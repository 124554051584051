import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { notify } from 'react-notify-toast';
//import $ from 'jquery';
import CategoryMegaMenu from './CategoryMegaMenu'
import { connect } from 'react-redux'


class Header extends React.Component {

  constructor() {
    super();
    this.state = {
      //redirectToReferrer: false,
      redirectToSearch: false,
      query: '',
      categories: [],
    };
  }

  componentDidMount() {


  }


  logout = () => {
    localStorage.removeItem('ud_fname');
    localStorage.removeItem('ud_lname');
    localStorage.removeItem('ud_mobile');
    localStorage.removeItem('ud_email');
    localStorage.removeItem('ud_tid');
    localStorage.removeItem('ud_id');
    localStorage.removeItem('ud_token');

    notify.show('You have been Signed off...', 'success');
    this.props.redirect(true);

    // this.setState({redirectToReferrer: true});


  }


  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  search = (e) => {
    e.preventDefault();
    this.setState({ redirectToSearch: true });
  }

  render() {

    // if (this.state.redirectToReferrer) {
    //   return (<Redirect to={'/'}/>)
    //   }

    if (this.state.redirectToSearch) {
      this.setState({ redirectToSearch: false });
      return (<Redirect to={'/search/' + this.state.query} />)
    }


    return (

      <header id="main-header" className="header">

        <div id='loading' className="loading" style={{ 'display': 'none',height: '100vH',opacity: 0.8,background: 'black',position: 'absolute',width: 100+'%',top: 0,left: 0,zIndex: 1000,fontSize: 40,color: 'white',textAlign: 'center',paddingTop: 10+'%' }}>
          {this.props.upload?'Uploading...'+(this.props.progress? ' '+Math.round(this.props.progress*100)/100+'% ('+Math.round(this.props.progressDone/10000)/100+'/'+Math.round(this.props.progressTotal/10000)/100+' MB)':''):'Loading...'}
          </div>

        <nav className="navbar navbar-default desktop_menu">
          <button className="navbar-toggle" type="button" data-toggle="collapse" data-target=".js-navbar-collapse">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>


          <div className="navbar-collapse js-navbar-collapse collapse in" aria-expanded="true" >
            <ul>
              <li className="side_menu_controller">
                <img src="/images/download.png" alt="" />
              </li>
              <li className="main_top_menu_all_icon">

                <div className="navbar-header">
                  <Link to="/" className="navbar-brand">
                    <img className="logo" src="/images/blacklogo.png" alt="Veriipro Learning" />
                  </Link>
                </div>
                <CategoryMegaMenu onChange={this.onChange} search={this.search} query={this.state.query} trainerPage={this.props.trainerPage}/>
              </li>
              <ul className="profile_section">


                {

                  (localStorage.ud_token) ?

                    <li className="profile_pic aflogin">
                      <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <img style={{ width: '100%', maxWidth:'35px', }} src={(localStorage.ud_image) ? localStorage.ud_image : "/images/onlylogin.png"} alt="User " />
                      </a>

                      <ul className="dropdown-menu mega-dropdown-menu Profile_drop">
                        <li>
                          <div className="profile_section_pic">
                            <div className="profile_image">
                              <img src={(localStorage.ud_image) ? localStorage.ud_image : "/images/onlylogin.png"} alt="" />
                            </div>
                            <div className="profile_name">
                              <p className="name">{localStorage.ud_fname}</p>
                              <p>{localStorage.ud_email}</p>
                            </div>
                          </div>
                        </li>
                        <Link to="/mycourse/completed" ><li>My Learning</li></Link>
                        <Link to="/mycourse/learning" ><li>In Progress</li></Link>
                        <li className="divider"></li>

                        <Link to="/update-password" ><li>Account</li></Link>
                        <Link to="/myorders" ><li>Order history</li></Link>

                        {/* <Link to="/paymentmethod" ><li>Payment Method</li></Link> */}
                        <Link to="#" ><li onClick={this.logout}>Logout</li></Link>
                        <li className="prodown_li">
                          <div className="prodown">
                            <ul>
                            { (this.props.trainerPage===true)? 
                              <Link to='/instructorportal/profile'><li>Update Profile</li></Link>
                              :
                              <Link to='/instructorportal'><li>VeriiPro For Trainer</li></Link>
                            }

                              <a href="https://www.veriipro.com" target="_blank"><li> Go to VeriiPro</li></a>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </li>
                    :
                    <li className="profile_pic">
                      <Link to="/login"> SIGN IN </Link>
                      <Link to="/signup"> SIGN UP </Link>
                    </li>

                }


                <li class="stu___dne__techer">
                { (this.props.trainerPage===true)? <Link to="/"> Student</Link> :  <Link to="/instructorportal"> Trainer</Link>}
                </li>
              </ul>
            </ul>
          </div>



        </nav>


        <div className="mobil_responsive_menu">
          <div className="main_mobile_menu">
            <div className="top_mobile_menu">
              <ul>
                <li className="mobile_category_menu_icon">
                  <div className="cat_menu_full_detail">=</div>
                </li>
                <li className="mobile_logo_menu_icon">
                  <img className="mobile_logo" src="/images/blacklogo.png" alt="Veriipro" />
                </li>
                { (this.props.trainerPage===true)? null:
                <li className="searchbar lrg">
                  <div className="search-container">
                    <form action="#" onSubmit={this.search}>
                      <input type="text" placeholder="Search.." value={this.state.query} required name='query' onChange={this.onChange} />
                      <button className="srcbtn" type="submit"><i style={{ padding: '7px 25px' }} className="fa fa-search"></i></button>
                    </form>
                  </div>
                </li> }


                {(localStorage.ud_token) ?


                  <li className="profile_pic aflogin">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                      <img style={{width: '100%', maxWidth:'35px',}} src="/images/onlylogin.png" alt="User" />
                    </a>

                    <ul className="dropdown-menu mega-dropdown-menu Profile_drop">
                      <li>
                        <div className="profile_section_pic">
                          <div className="profile_image">
                            <img src="/images/onlylogin.png" alt="" />
                          </div>
                          <div className="profile_name">
                            <p className="name">
                              {localStorage.ud_fname}
                            </p>
                            <p>
                              {localStorage.ud_email}
                            </p>
                          </div>
                        </div>
                      </li>
                      <Link to="/mycourse/completed" ><li>My Learning</li></Link>
                      <Link to="/mycourse/learning" ><li>In Progress</li></Link>
                      <li className="divider"></li>
                      <Link to="/update-password" ><li>Account</li></Link>
                      <Link to="/myorders" ><li>Order history</li></Link>
                      {/* <Link to="/paymentmethod" ><li>Payment Method</li></Link> */}
                      <li onClick={this.logout}>Logout</li>
                      <li className="prodown_li">
                        <div className="prodown">
                          <ul>
                          { (this.props.trainerPage===true)? 
                              <Link to='/instructorportal/profile'><li>Update Profile</li></Link>
                              :
                              <Link to='/instructorportal'><li>VeriiPro For Trainer</li></Link>
                            }
                            <a href="https://www.veriipro.com" target="_blank"><li> Go to VeriiPro Jobs</li></a>
                          </ul>
                        </div>
                      </li>
                    </ul>

                  </li>

                  :
                  <li className="mobile_sign_in_up_menu_icon">
                    <Link to="/login">Sign In</Link>
                    <Link to="/signup">Sign Up</Link>
                  </li>
                }
                <li class="stu___dne__techer">
                  { (this.props.trainerPage===true)? <Link to="/"> Student</Link> :  <Link to="/instructorportal"> Trainer</Link>}
                </li>
              </ul>
            </div>
            <div className="menu_search_icon">
              { (this.props.trainerPage===true)? null:
              <li className="searchbar sml">
                <div className="search-container">
                  <form action="#" onSubmit={this.search}>
                    <input type="text" placeholder="Search.." value={this.state.query} required name='query' onChange={this.onChange} />
                    <button className="srcbtn" type="submit"><i style={{ padding: '7px 25px' }} className="fa fa-search"></i></button>
                  </form>
                </div>
              </li>  }

            </div>
          </div>
        </div>

      </header>

    );
  }
}


const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
