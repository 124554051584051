import React, { Component } from 'react';
import {Route } from "react-router-dom";


import Login from './../pages/login/Login';
import Home from './../pages/home/Index';

export class PrivateRoute extends Component {

    render() {
      if (localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        // return (<Redirect to={{ pathname: '/login', state: { from: props.location } }}  />);
        return (<Route exact path={this.props.path} component={Login} />);
    }
  }
  
 export  class LoginRoute extends Component {
  
    render() {
      if (!localStorage.getItem('ud_token'))
        return (<Route exact path={this.props.path} component={this.props.component} />);
      else
        return (<Route exact path={this.props.path} component={Home} />);
    }
}