import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'


import { get, post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';


class CreateNewCourse extends React.Component {

    constructor(){
      super();
      this.state = {
        redirectToReferrer:false,
          languages: [],
          technologies: [],
          categories: [],
          subcategories: [],
          topics: [],
          formdata:{
            series_title:'',
            series_category:'',
            series_sub_category:'',
            series_topic:'',
            series_sub_title:'',
            series_description:'',
            series_language:'',
            series_level:'',
  
          }
      };
  
      this.onChange = this.onChange.bind(this);
      this.onChangeDropdown = this.onChangeDropdown.bind(this);
      this.addSeries = this.addSeries.bind(this);
  
    } 

	componentDidMount(){

	
	}

	componentDidUpdate(){
	//	$('select').niceSelect('update');
	}


	addSeries(e) {
		e.preventDefault();

		if(this.state.formdata.series_title && this.state.formdata.series_sub_title && this.state.formdata.series_description){
			post('createseries',this.state.formdata).then((result) => {
			 let responseJson = result;
			 if(responseJson.sid && responseJson.error==false){
				notify.show(responseJson.msg,'success');
				this.setState({redirectToReferrer: true});
				this.setState({
					redirectToReferrer:false,
						languages: [],
						categories: [],
						subcategories: [],
						topics: [],
						formdata:{
							series_title:'',
							series_category:'',
							series_sub_category:'',
							series_topic:'',
							series_sub_title:'',
							series_description:'',
							series_language:'',
							series_level:'',
		
						}
				});


			 }else  if(responseJson.error==true ){
				notify.show(responseJson.msg,'error');

			 }

			});
		}else{
      notify.show('Please Fill all the field','warning');

    }

	 }

  onChange(e){
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name] = e.target.value;                        //updating value
		this.setState({formdata});
  }


  onChangeDropdown(e) {
	let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
	formdata[e.target.name] =  this.getSelectedOptions(e)//.target.value;                        //updating value
	this.setState({ formdata });
  }


    render() {

      if (this.state.redirectToReferrer) {
        return (<Redirect to={'/instructorportal/mycourses'}/>)
        }
    
     
        return (
            <>
            <Header trainerPage={true} /> 
<Breadcrumb path={[{link:'/',label:'Home'},{link:'/instructorportal',label:'Trainer'},{link:'',label:'Create New Course'},]} />
<div className="main-body">


<form className="form-horizontal" action=" " method="post"  id="contact_form"   onSubmit={this.addSeries} >
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
  <p className="dnc">Design New Course</p>
  </div>

  <div className="topic_exit">
    <Link to="/instructorportal">Exit</Link>
  </div>
</div>

<div className="design_new_course_form">
  
  <div className="container">
<fieldset>


<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course Tittle</label>  
  <div className="col-md-12 inputGroupContainer">
  <div className="input-group">
  <input  name="series_title" minLength='4' maxLength='60' placeholder="Course Tittle*"  onChange={this.onChange}  required  className="form-control"  type="text"/>
    </div>
    <div className="form-control-counter" data-purpose="form-control-counter">{60-this.state.formdata.series_title.length}</div>
  </div>
</div>

{/* <!-- Text input--> */}

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course Sub-Tittle</label>  
    <div className="col-md-12 inputGroupContainer">
    <div className="input-group">
  <input name="series_sub_title" minLength='4' maxLength='120' placeholder="Course Sub-Tittle*"  onChange={this.onChange} required className="form-control"  type="text"/>
    </div>
    <div className="form-control-counter" data-purpose="form-control-counter">{120-this.state.formdata.series_sub_title.length}</div>
  </div>
</div>

{/* <!-- Text input--> */}
<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course Description</label>  
    <div className="col-md-12 inputGroupContainer">
    <div className="input-group">
          <textarea className="form-control" minLength='4'  name='series_description' onChange={this.onChange} required placeholder="Course Description*"></textarea>
  </div>
  </div>
</div>


{/* <!-- Learning Area -->
   
<!-- <div className="form-group dnc_flwd">
  <label className="col-md-4 control-label dnc_lnhg">Learning Area</label>   
    <div className="col-md-8 selectContainer">
    <div className="input-group">
    <select name="state" className="form-control selectpicker" >
      <option value=" " >Learning Area</option>
      <option>Alabama</option>
      <option>Alaska</option>
      <option >Arizona</option>
      <option >Arkansas</option>
    </select>
  </div>
</div>
</div>
 -->

<!-- Subject -->
   
<!-- <div className="form-group dnc_flwd"> 
    <label className="col-md-4 control-label dnc_lnhg">Subject</label>  
    <div className="col-md-8 selectContainer">
    <div className="input-group">
    <select name="state" className="form-control selectpicker" >
      <option value=" " >Subject</option>
      <option>Alabama</option>
      <option>Alaska</option>
      <option >Arizona</option>
      <option >Arkansas</option>
    </select>
  </div>
</div>
</div> -->


<!-- Topic -->
   
<!-- <div className="form-group dnc_flwd"> 
    <label className="col-md-4 control-label dnc_lnhg">Topic</label>  
    <div className="col-md-8 selectContainer">
    <div className="input-group">
    <select name="state" className="form-control selectpicker" >
      <option value=" " >Topic</option>
      <option>Alabama</option>
      <option>Alaska</option>
      <option >Arizona</option>
      <option >Arkansas</option>
    </select>
  </div>
</div>
</div> -->


<!-- Technology/ Skills/Soft-->
<!-- <div className="form-group dnc_flwd">
    <label className="col-md-4 control-label dnc_lnhg">Technology</label>  
    <div className="col-md-8 inputGroupContainer">
    <div className="input-group">
   <input  name="Technology/skills/soft" placeholder="Technology/skills/soft" className="form-control"  type="text"/>
  </div>
  <div className="form-control-counter" data-purpose="form-control-counter">60</div>  
  </div>
</div> -->

<!-- Language -->
   
<!-- <div className="form-group dnc_flwd"> 
    <label className="col-md-4 control-label dnc_lnhg">Language</label>  
    <div className="col-md-8 selectContainer">
    <div className="input-group">
    <select name="state" className="form-control selectpicker" >
      <option value=" " >Language</option>
      <option>Alabama</option>
      <option>Alaska</option>
      <option >Arizona</option>
      <option >Arkansas</option>
    </select>
  </div>
</div>
</div> -->


<!-- Level -->
   
<!-- <div className="form-group dnc_flwd"> 
    <label className="col-md-4 control-label dnc_lnhg">Level</label>  
    <div className="col-md-8 selectContainer">
    <div className="input-group">
    <select name="state" className="form-control selectpicker" >
      <option value=" " >Level</option>
      <option>Alabama</option>
      <option>Alaska</option>
      <option >Arizona</option>
      <option >Arkansas</option>
    </select>
  </div>
</div>
</div> --> */}


</fieldset>

</div>
    </div>
    {/* <!-- /.container --> */}

</div>

</div>
<div className="cnd_next_page">
  <div className="cnd_nxt_pg">
    <div className="cnd_cnue">
    <button type="submit">Create Course</button> </div>
  </div>
</div>
</form>
{/* <!-- END MAIN --> */}
</div>


    <Footer />

    </>
        );
    }
}

export default CreateNewCourse;