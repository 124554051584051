import React from 'react';
import { Link } from 'react-router-dom'

class Breadcrumb extends React.Component {

    render() {
        return (

            <ul className="breadcrumb">
                {
                    this.props.path.map((item,key)=>{
                        return item.link ? <li><Link to={item.link}>{item.label}</Link></li> : <li>{item.label}</li>
                    })
                }
            </ul>

        );
    }
}

export default Breadcrumb;
