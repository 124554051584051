import React from "react";
import { Link, Redirect } from "react-router-dom";
import {  post,put } from './../../services/Apicall'
import { notify } from 'react-notify-toast';
class EditModule extends React.Component {


    constructor() {
        super();

        this.state = {
            series_id: '',
            module_id: '',
            lecture_id: '',
            module_name: '',
            module_objective: '',
            module_position: '',
            data: { modules: [] }
        };
        this.onChange = this.onChange.bind(this)
        //this.closeeditlecture = this.closeeditlecture.bind(this)
        this.closeeditmodule = this.closeeditmodule.bind(this)
        this.savemodule = this.savemodule.bind(this)

    } //end constructor





    componentWillMount() {
        var series_id = this.props.sid;//match.params.seriesId;
        this.setState({ series_id: series_id });
        //this.setState({ lecture_id: this.props.lid });
        this.setState({ key2: this.props.key2 });
        this.setState({ module_id: this.props.mid });
        this.setState({ module_name: this.props.mname });
        this.setState({ module_objective: this.props.mdesc });


    }



    closeeditmodule(e, position) {
        this.componentWillMount();
        var x = document.getElementsByClassName("editmodule");
        var y = document.getElementsByClassName("modulepanel");
        var z = document.getElementsByClassName("editmodule_btn");
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = 'none';
            y[i].style.display = 'block';
            z[i].style.display = 'block';
        }
        //document.getElementById('lecturepanel_'+position).style.display = 'block';

    }

    savemodule(e, moduleid) {
        e.preventDefault();
        var title = this.state.module_name;
        var description = this.state.module_objective;
        const formdata = {
            series_id: this.props.sid,
            module_name: title,
            module_objective: description,
            module_id: moduleid
        };

        put('updatemodule', formdata).then((result) => {
            let responseJson = result;
            if (responseJson.error == false) {

                this.props.reload();
                this.closeeditmodule();
                
                
                notify.show('Module Updated Successfully!', 'success');


            } else {
                if(responseJson.msg)
                notify.show(responseJson.msg, 'error');
                else
                notify.show('Something Went Wrong!', 'error');
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        //this.componentWillMount();
        
        this.setState({ module_name: nextProps.mname });
        this.setState({ module_objective: nextProps.mdesc });

      }



    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }



    render() {
        console.log('updating props to local');
        var key2 = this.state.key2;
        var mid = this.state.module_id;
        var sid = this.state.series_id;
        //var lid = this.state.lecture_id;
        return (

            // <div className="row editmodule" id={'editmodule_' + key2} style={{ 'border': '1px black solid', 'display': 'none' }} >

            //     <button type='button' className='btn btn-sm btn-default pull-right closeeditmodule' title='Cancel' onClick={this.closeeditmodule} > x </button>
            //     <h4 className="col-md-12">Update Module</h4><br />
            //     <form action="#" className='col-md-12' onSubmit={(e) => this.savemodule(e, mid)}>
            //         <div className="mt-10 col-md-">
            //             <input type="text" maxLength='80' minLength='5' name="module_name" value={this.state.module_name} placeholder="Module Name" onChange={this.onChange} required className="single-input-accent" />
            //         </div>
            //         <div className="mt-10">
            //             <input type="text" maxLength='200' minLength='10' name="module_objective" value={this.state.module_objective}  placeholder="Learning Objective" onChange={this.onChange} required className="single-input-secondary" />
            //         </div>

            //         <button type='submit' className="genric-btn primary circle arrow mt-10 pull-right">Update Module</button>
            //         <button type='button' className="genric-btn default circle arrow mt-10 pull-right closeeditmodule" onClick={this.closeeditmodule} >Cancel</button>

            //     </form>
            // </div>


	<div className="dnc_middle_section editmodule"  id={'editmodule_' + key2} style={{ 'display': 'none' }}>
    <div className="dnc_title_section_left">
    <span> Update Section : </span>
    </div>
    <div className="dnc_section_right " >   
    <form action="#"  onSubmit={(e) => this.savemodule(e, mid)}>
    <button type='button' className='btn btn-sm btn-default pull-right closeeditmodule' title='Cancel' onClick={this.closeeditmodule}  > x </button>
        <div className="new_section_title">
            <div className="col-md-12 inputGroupContainer">
            <div className="input-group">
          <input placeholder="Section Title" className="form-control" type="text"maxLength='80' minLength='5' name="module_name" value={this.state.module_name} onChange={this.onChange} required />
            </div>
            <div className="form-control-counter" data-purpose="form-control-counter">{80-this.state.module_name.length}</div>
          </div>
  
        <span>What will students be able to do at the end of this section?</span>
  
      <div className="col-md-12 inputGroupContainer">
      <div className="input-group">
      <input placeholder="Section Objective" className="form-control" type="text"  maxLength='200' minLength='10' name="module_objective" value={this.state.module_objective} onChange={this.onChange} required  />
      </div>
      <div className="form-control-counter" data-purpose="form-control-counter">{200-this.state.module_objective.length}</div>
  
      <div className="new_section_title_btn">
        <button type="button" className="btn btn-lg btn-primary closenewmodule"  onClick={this.closeeditmodule}  >Cancel</button>
        <button type="submit" className="btn btn-lg btn-primary">Update Section</button>
      </div> 
  
      </div>    
  
        </div>
        </form>
    </div>
  
      
    </div>
  

        )
    }
}
export default EditModule;