import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'


import { get, post } from './../../services/Apicall';
import * as Helper   from './../../services/Helper';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';


class ManagePreview extends React.Component {

    constructor() {
        super();
        this.state = {
          data:{ objective: [ ],
          requirment: [ ],
          target: [ ],
          modules: [ ],
          reviewself: {},
          review: [],
          recomemded: [],
          series_skills:[],
          reviewavg: 0,
          reviewcount: 0,
          enrolled: false
        },
          rating:{},
          review:{},


        }
    }

    

  componentDidMount() {
    this.setState({ 'series_id': this.props.match.params.courseId })
    get('seriesdetail', { seriesid: this.props.match.params.courseId }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })
        var rformdata = {
          rating: responseJson.data.reviewself.rating,
          review: responseJson.data.reviewself.review,
          seriesid: this.props.match.params.courseId
        }
        this.setState({ rformdata: rformdata })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });



  }


  vpopClose=()=>{
    $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
      $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
      $("#video-popup-iframe").attr('src', '');
    });
  }
  vpopOpen=()=>{
   $(".vpop, .vvpop").on('click', function(e) {
    $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    var srchref='',autoplay='',id=$(this).data('id');
    if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";

    if($(this).data('autoplay') == true) autoplay = '?autoplay=1';

    $("#video-popup-iframe").attr('src', srchref+id+autoplay);

    $("#video-popup-iframe").on('load', function() {
      $("#video-popup-container").show();
    });
  });

  }
  shareSocial = (type) => {
    let shareurl = '/course/'+this.props.match.params.courseId;
    let url = '';

    if (type == 'facebook')
      url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.hostname + shareurl;
    else if (type == 'linkedin')
      url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.hostname + shareurl;
    else if (type == 'twitter')
      url = 'http://twitter.com/share?text=' + window.location.hostname + shareurl + '&url=' + window.location.hostname + shareurl + '&hashtags=Veriipro,JobsInUS,VerifiedJobs';
    window.open(url, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=350');
  }

    render() {
        return (
            <>
            <Header trainerPage={true} /> 
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />

    <div className="main-body">
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
    <div className="btac ttl_tg_all">&#8735;</div>
  <p className="aabtx ttl_tg_all"><Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>
  <p className="crs_name ttl_tg_all">{this.state.data.series_title}</p>
  {/* <p className="crs_sts ttl_tg_all">inactive</p> */}
  </div>

  <div className="topic_exit">
    <Link to="/instructorportal/tips">Tips <i className="fa fa-info-circle"></i></Link>
  </div>
</div>

<TrainerSidebar courseId={this.props.match.params.courseId } />

<div className="dnc_right_bar">

<div id="Course_Preview" className="tabcontent">

<div className="main cou_det_pg">

<div className="Com_detail_top_panel">
<h2>{this.state.data.series_title}</h2>
                    <p>{this.state.data.series_sub_title}</p>
  {this.state.data.featured?<span className="course-badge--course-badge--1AN7r"><span data-purpose="badge" className="on-course-card badge badge-accented yellow"><span className="badge-text">Bestseller</span></span></span>:null}
  <div className="dec_star dec_all">
  <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"   initialRating={this.state.data.reviewavg}/>
      </div>
      <span className="cd_wishlist"><i style={{fontSize:15}} className="fa"></i> Wishlist</span>
      <span style={{color: '#fff'}} className="dec_rate_me">{this.state.data.reviewavg}</span>
      <span className="dec_total_rateing dec_all">({this.state.data.reviewcount} rating)</span>
      <p>{(this.state.data.series_enrolled)}  Students Enrolled</p>
      <p><Link to={"/instructor/"+this.state.data.series_trainer_id}>Created By {this.state.data.series_trainer}</Link></p>
      <p>Last Updated {this.state.data.updated_on?(this.state.data.updated_on): (this.state.data.created_on)}</p>
</div>
<div className="main_detail_section">
  <div className="container">
    <div className="col-md-7 col-lg-7">
      <div className="course_intro_section">
        <h2>Course Details</h2>
        <p>{this.state.data.series_description}</p>
      </div>

      <div className="course_content_panels">
        <div className="panel_title_time_etc">
          <span className="cor_con">COURSE CONTENT</span>
          <span className="tim_all">{Helper.sec2HrMin(this.state.data.series_lecture_duration)}</span>
          <span className="fr_lec">{this.state.data.series_lecture_count}  Lectures</span>
          <span className="exp_all expand_all"><div id="collapsebutton" className="nodisp expandcollapse no-print" style={{display: 'none'}}><i className="glyphicon glyphicon-minus"></i> Collapse All</div>
    <div id="expandbutton" className="disp expandcollapse no-print"><i className="glyphicon glyphicon-plus"></i> Expand All</div></span>
          
        </div>

        <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

{
this.state.data.modules.map((item,key)=>{
  return  <div className="panel panel-default"  key={key}>
    <div className="panel-heading" role="tab" id={"heading"+key}>
      <h4 className="panel-title">
        <a role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapse"+key} aria-expanded="true" aria-controls="collapseOne">
        {item.module_name}
<i className="more-less glyphicon glyphicon-plus"></i>
          <span>{Helper.sec2HrMin(item.lecture_duration)}</span>
        </a>
      </h4>
    </div>
    <div id={"collapse"+key} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading"+key}>
      <div className="panel-body">
      {
      item.module_content.map((module,keyM)=>{
        return(  
          (module.conten_type)? 
              (module.sample)? <div style={{ color: '#08bbf5' }} className="well well-sm"   key={keyM}>
                                  {(module.content_type=='V')? <i style={{ color: '#08bbf5' }} className="material-icons"></i> : <i style={{ color: '#08bbf5' }}  className="fa" aria-hidden="true"></i> }
                                  {module.title}  
                                  <span className="tim_ing pa_es">{Helper.sec2HrMin(module.duration)}</span> 
                                  <span className="tim_ing pa_es vvpop"  onClick={(e)=>this.vpopOpen(e)} data-type="vimeo" data-id={module.samplevideo} data-video-ref={module.samplevideo}  data-article-ref={module.article}  >Preview</span>
                              </div>
              
                 : 
                <div className="well well-sm"  key={keyM}>
                  {(module.content_type=='V')? <i className="material-icons"></i> : <i className="fa" aria-hidden="true"></i> }
                  {module.title}
                  <span className="tim_ing">{Helper.sec2HrMin(module.duration)}</span>
                </div>
              :
              null

        )
      })
    }
        
      </div>
    </div>
  </div>

})
}

</div>
{/* <!-- panel-group --> */}

</div>

<div className="req_ment">
<h2>Objective</h2>
<ul>
{this.state.data.objective.map((item,key)=>{
  return <li key={key}>{item.value}</li>
})}
</ul>

<h2>Target Individuals</h2>
<ul>
{this.state.data.target.map((item,key)=>{
  return <li key={key}>{item.value}</li>
})}
</ul>
<h2>Requirements</h2>

<ul>
{this.state.data.requirment.map((item,key)=>{
  return <li key={key}>{item.value}</li>
})}
</ul>
</div>



    </div>
    <div className="col-md-5 col-lg-5 padd_for_use">
      <div className="all_right_bar_panel_detail">
<div className="cou_vid_tumb_nail">
<div className="topic_video_lesson">
  
      <img src={this.state.data.series_image?this.state.data.image_url+this.state.data.series_image:"/images/cloud.jpg"}  alt="Preview" style={{width:'100%'}}/>

<div className="vpop" data-type="vimeo" data-id="" data-autoplay="true">
      <i className="fa video_po_pup"></i>
    </div>

    {/* <!-- copy this stuff and down --> */}
<div id="video-popup-overlay"   onClick={this.vpopClose}></div>

<div id="video-popup-container">
  <div id="video-popup-close"   onClick={this.vpopClose} className="fade">✖</div>
  <div id="video-popup-iframe-container">
    <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</div>

</div>
{/* <!--   <div className="img_course_hover">
  <img src="https://www.udemy.com//staticx/udemy/js/webpack/icon-play.ac3f32ecb72a0c3f674fa5a3f3062a56.svg" alt="" className="hover_img_course">
  <p>Preview this course</p>
</div> --> */}
</div>

<div className="cou_pri_det_full">
  <div className="cup_and_price">
    {/* <span className="cup_on">Apply Coupon</span> */}
    <span className="pri_ing">{(this.state.data.series_type=='PAID')? '$ '+this.state.data.series_price : 'Free '}</span>
  </div>
  <div className="crs_vide_pric_es">
       <a href="#"><button data-purpose="buy-this-course-button" type="button" className="course-cta btn btn-lg btn-quaternary btn-block">Buy now</button></a>
  </div>
  <div className="mon_y_ba_k">
    {/* <span className="money-back">
30-Day Money-Back Guarantee
</span> */}
  </div>
  <div className="incentives">
<div className="clp-component-render"><div>
<span id="incentives" className="in-page-offset-anchor"></span>
<div className="incentives__header">
This course includes
</div>
</div>
<ul className="incentives__list">
<li className="incentives__item">
<i className="incentives__icon incentives__icon--bold udi udi-video-design"></i>
<span className="incentives__text" data-purpose="video-content-length">
{this.state.data.series_duration } {this.state.data.series_duration_type} on-demand video
</span>
</li>
<li className="incentives__item">
<i className="incentives__icon udi udi-file-o"></i>
<span className="incentives__text">
{this.state.data.series_article_count }  articles
</span>
</li>
{/* <li className="incentives__item">
<i className="incentives__icon udi udi-file-text-o"></i>
<span className="incentives__text">
19 downloadable resources
</span>
</li>
<li className="incentives__item">
<i className="incentives__icon udi udi-curly-braces-bold"></i>
<span className="incentives__text" data-purpose="incentive-coding-exercises">
4 coding exercises
</span>
</li> */}
<li className="incentives__item">
<i className="incentives__icon incentives__icon--bold udi udi-what-lifetime"></i>
<span className="incentives__text" data-purpose="incentive-lifetime-access">
Full lifetime access
</span>
</li>
<li className="incentives__item">
<i className="incentives__icon incentives__icon--bold udi udi-mobile-apps"></i>
<span className="incentives__text">
Access on mobile app and website
</span>
</li>
</ul></div>
<div className="clp-component-render"><ul className="incentives__list">
<li className="incentives__item">
<i className="incentives__icon incentives__icon--bold udi udi-academics"></i>
<span className="incentives__text" data-purpose="incentive-certificate">
Certificate of Completion
</span>
</li>
</ul></div>
</div>
<div className="marketplace-social-share-button--share-button--79rGp"><div className="marketplace-social-share-button--share-button__top-border--bacJS"></div><button type="button" className="btn btn-link"><span className="udi udi-share"></span><span className="marketplace-social-share-button--share-button__text--1jqY7">Share</span></button></div>

<div className="marketplace-social-share-button--share-button--79rGp">
                              <div className="marketplace-social-share-button--share-button__top-border--bacJS"></div>
                              <div className="shre__option">
                                <div className="my__shre__op">
                                <span className="fa fa-share"></span>
                              <span className="marketplace-social-share-button--share-button__text--1jqY7">Share</span>
                              
                                </div>
                              <div className="social__icon___onhover">
                                <i className="fa fa-facebook" onClick={()=>this.shareSocial('facebook')} ></i>
                                <i className="fa fa-linkedin" onClick={()=>this.shareSocial('linkedin')} ></i>
                                <i className="fa fa-twitter" onClick={()=>this.shareSocial('twitter')} ></i>
                              </div>
                              </div>
                            </div>


                            <section className="overview-panel__authors">
                              <h3 className="overview-panel__authors-headline">Instructor</h3>

                              <ul className="overview-panel__authors-list">
                                <li>
                                  <div className="overview-panel__entity-lockup">
                                    <figure>
                                    <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        <img className="lazy-loaded" alt={this.state.data.series_trainer} src={this.state.data.series_trainer_image?this.state.data.trainer_image_url+this.state.data.series_trainer_image:'/images/user-dummy.png'}/>
                                      </Link>
                                    </figure>
                                    <div>
                                      <h4 className="overview-panel__entity-lockup-title">
                                      <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        {this.state.data.series_trainer} </Link>
                                      </h4>
                                      <h5 className="overview-panel__entity-lockup-headline">{this.state.data.series_trainer_professional_title}</h5>

                                      <label className="overview-panel__entity-lockup-label" for="author0-readmore">
                                      <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        <span className="overview-panel__entity-lockup-label-more">Learn More</span></Link>
                                      </label>
                                      {this.state.data.series_trainer_linkedin?
                                      <>
                                      <span className="vertical-divider"></span>
                                      <a target="_blank" href={ Helper.url(this.state.data.series_trainer_linkedin)} className="entity-action" >
                                        View on LinkedIn  </a></>:null}

                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </section>


                            <div className="overview-panel__skills">
                              <h3 className="panel__headline">Skills covered in this course</h3>
                              <ul className="skills__list">
                                {this.state.data.series_skills.map((item,key)=>{
                                return <li className="skills__skill">
                                    <Link to={ item.slug?'/technology/'+item.slug:'/technology/'+item.id} data-tracking-control-name="course_skill" data-tracking-will-navigate="" className="pill" saprocessedanchor="true">
                                     {item.technology_name}</Link>
                                    </li>
                                   })
                                  }
                                
                                
                              </ul>
                            </div>

</div>

</div>


    </div>
  </div>
</div>


</div>

</div>

</div>


</div>

</div>
{/* <!-- END MAIN --> */}
</div>


  
    <Footer  NoNavigation={true} />
    </>
        );
    }
}

export default ManagePreview;