import React from "react";
import { Link, Redirect } from "react-router-dom";
import {  post,put } from './../../services/Apicall'
import { notify } from 'react-notify-toast';
class EditLecture extends React.Component {


    constructor() {
        super();

        this.state = {
            series_id: '',
            module_id: '',
            lecture_id: '',
            lecture_title: '',
            lecture_description: '',
        };
        this.onChange = this.onChange.bind(this)
        this.closeeditlecture = this.closeeditlecture.bind(this)
       this.savelecture = this.savelecture.bind(this)


    } //end constructor





    componentDidMount() {
        var series_id = this.props.sid;//match.params.seriesId;
        this.setState({ series_id: series_id });
        this.setState({ lecture_id: this.props.lid });
        this.setState({ module_id: this.props.mid });
        this.setState({ lecture_title: this.props.lname });
        this.setState({ lecture_sample: this.props.lsample });
        this.setState({ lecture_description: this.props.ldesc });

        this.setState({ key1: this.props.key1 });
        this.setState({ key2: this.props.key2 });


    }



    closeeditlecture(e, position) {
        this.componentDidMount();
       
        var x = document.getElementsByClassName("editlecture");
        var y = document.getElementsByClassName("lecturepanel");
        var z = document.getElementsByClassName("editlecture_btn");
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = 'none';
            y[i].style.display = 'block';
            z[i].style.display = 'block';
        }
        //document.getElementById('lecturepanel_'+position).style.display = 'block';

    }

    savelecture(e, lectureid, moduleid,sid) {
        e.preventDefault();
        var title = this.state.lecture_title;
        var description = this.state.lecture_description;
        var sample = this.state.lecture_sample;
        const formdata = {
            series_id: sid,
            title: title,
            description: description,
            module_id: moduleid,
            lecture_id: lectureid,
	        sample:sample
        };

        put('updatelecture', formdata).then((result) => {
            let responseJson = result;
            if (responseJson.error == false) {
                this.props.reload();
                this.closeeditlecture();
                this.componentDidMount();
                notify.show('Lecture Updated Successfully!', 'success');


            } else {
                if(responseJson.msg)
                notify.show(responseJson.msg, 'error');
                else
                notify.show('Something Went Wrong!', 'error');
            }
        });
    }




    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    onSampleToggle = (e)=>{
        var sample =  (/*this.state.lecture_sample*/ e.target.checked)? 1 : 0;
        this.setState({ lecture_sample: sample });
    }
    componentWillReceiveProps(nextProps) {
        //this.componentWillMount();
        
        this.setState({ lecture_description: nextProps.mname });
        this.setState({ lecture_description: nextProps.mdesc });

      }




    render() {
        console.log('updating props to local');
        var key1 = this.state.key1;
        var key2 = this.state.key2;
        var mid=this.state.module_id;
        var sid=this.state.series_id;
        var lid=this.state.lecture_id;
        return (

            // <div className="row editlecture" id={'editlecture_' + (key2) + key1} style={{ 'border': '1px black solid', 'display': 'none' }} >

            //     <button type='button' className='btn btn-sm btn-default pull-right closeeditlecture' title='Cancel' onClick={this.closeeditlecture}  > x </button>
            //     <h4 className="col-md-12">Update Lecture</h4><br />
            //     <form action="#" className='col-md-12' onSubmit={(e) => this.savelecture(e, lid, mid,sid)}>
			// <div className="mt-10 col-md-">
			//  <span>Mark as Sample </span> <input type="checkbox"  name="lecture_sample"  checked={ (this.state.lecture_sample)? true: false }   onChange={this.onSampleToggle}  />
			// </div>
            //         <div className="mt-10 col-md-">
            //             <input type="text" maxLength='50' minLength='4' name="lecture_title" value={this.state.lecture_title} placeholder="Lecture Title" onChange={this.onChange} required className="single-input-accent" />
            //         </div>
            //         <div className="mt-10 col-md-">
            //             <textarea maxLength='255' minLength='4' name="lecture_description" value={this.state.lecture_description} placeholder="Lecture Description" onChange={this.onChange} required className="single-input-accent" ></textarea>
            //         </div>



            //         <button type='submit' className="genric-btn primary circle arrow mt-10 pull-right">Update Lecture</button>
            //         <button type='button' className="genric-btn default circle arrow mt-10 pull-right closeeditlecture" onClick={this.closeeditlecture} >Cancel</button>

            //     </form>
            // </div>



<div className="dnc_middle_section editlecture" id={'editlecture_' + (key2) + key1}   style={{ 'display': 'none' }}>

<div className="dnc_title_section_left">
  <span> Update Lecture : </span>
</div>

<div className="dnc_section_right " >
  <form action="#"   onSubmit={(e) => this.savelecture(e, lid, mid,sid)}  >
    <button type='button' className='btn btn-sm btn-default pull-right closeeditlecture' onClick={this.closeeditlecture} title='Cancel'   > x </button>
    <div className="new_section_title">
      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <span>Mark as Sample </span> <input type="checkbox"  name="lecture_sample"  checked={ (this.state.lecture_sample)? true: false } onChange={this.onSampleToggle}  />
        </div>
      </div>

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <input type="text" maxLength='50' minLength='4' name="lecture_title" value={this.state.lecture_title} placeholder="Lecture Title" onChange={this.onChange} required className="form-control" />
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">60</div>
      </div>

      

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <textarea maxLength='255' minLength='4' name="lecture_description" value={this.state.lecture_description} placeholder="Lecture Description" onChange={this.onChange} required className="form-control" ></textarea>
         
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">60</div>

        <div className="new_section_title_btn">

          <button type="button" className="btn btn-lg btn-primary closeeditlecture" onClick={this.closeeditlecture} >Cancel</button>
          <button type="submit" className="btn btn-lg btn-primary">Update Lecture</button>

        </div>

      </div>

    </div>
  </form>
</div>

</div>


        )
    }
}
export default EditLecture;
