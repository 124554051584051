import React from 'react';
//import $ from 'jquery'
import { Link } from 'react-router-dom'

import Header from './../../common/Header'
import Footer from './../../common/Footer'
import Breadcrumb from './../../common/Breadcrumb'

import { get, BaseUrl } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

const $ = window.$;

class Confirmation extends React.Component {

  constructor() {
    super();
    this.state = {
      data: []
    };

  } //end constructor

 
	componentDidMount(){

		get('fertchconfirmation',{oid:this.props.match.params.orderId}).then((result) => {
			let responseJson = result;
			if(responseJson.data && responseJson.error===false){
				this.setState({data: responseJson.data })
			
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		 });
  }
  
  render() {

    return (

      <>

        <Header />



        <div className="check_out_page_start">
          <Breadcrumb path={[{ link: '/', label: 'Home' }, { link: '', label: 'Order Confirmation' }]} />
          <div className="check_out_page">

            <div className="check_out_banner">

              <p>Thank you for the order, Your Order # {this.state.data[0]?.ORDER_ID} has been Processed successfully!</p>

            </div>


            <div className="main_section_of_check_out">

              <div className="check_out_detail_section">

                <h2>Order# {this.state.data[0]?.ORDER_ID}</h2>

                <table className="table table-dark">
                  <tr>
                    <th scope="row">Description</th>

                    <th scope="row">Price</th>

                    <th scope="row">Quantity</th>

                    <th scope="row">Total</th>
                  </tr>
                  {this.state.data.map((item,index)=>{ 
                  return <tr>

                    <td scope="row"><Link to={'/course/'+item.ITEM_ID} target='_blank'>{item.REMARK}</Link></td>

                    <td scope="row">$ {item.PRICE}</td>

                    <td scope="row">1</td>

                    <td scope="row">${item.PRICE}</td>
                  </tr>
                  })
                }
                  <tr style={{borderTop:'1px gray solid'}}>

                    <td colSpan="2" style={{textAlign:'right'}}>&nbsp;</td>
                    <td colSpan="1" style={{textAlign:'center'}}>Total</td>

                    <td scope="row">$
                    {/* {this.state.data.TOTAL} */}
                   { this.state.data.reduce(function (accumulator, item) {
                      if(item.TOTAL)
                      return accumulator + item.TOTAL;
                      else
                      return accumulator + 0;
                    }, 0)}
                    </td>
                  </tr>

</table>
{/* 
              <div className="check_ot_btn">
                <a href={BaseUrl()+'/processorder?q='+this.state.data.ORDER_ID}> Pay Now</a>
              </div> */}

            </div>

          </div>



        </div>

      </div>



      <Footer />
      </>




    );
  }
}

export default Confirmation;
