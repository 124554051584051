import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';

import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';

//const $ = window.$;

class Privacy extends React.Component {

  constructor(){
    super();
  }

  componentDidMount(){

//     $('.search-toggle').addclassName('closed');

// $('.search-toggle .search-icon').click(function(e) {
//   if ($('.search-toggle').hasclassName('closed')) {
//     $('.search-toggle').removeclassName('closed').addclassName('opened');
//     $('.search-toggle, .search-container').addclassName('opened');
//     $('#search-terms').focus();
//   } else {
//     $('.search-toggle').removeclassName('opened').addclassName('closed');
//     $('.search-toggle, .search-container').removeclassName('opened');
//   }
// });

    // $(".vpop").on('click', function(e) {
    //     e.preventDefault();
    //     $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();
        
    //     var srchref='',autoplay='',id=$(this).data('id');
    //     if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    //     else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";
        
    //     if($(this).data('autoplay') == true) autoplay = '?autoplay=1';
        
    //     $("#video-popup-iframe").attr('src', srchref+id+autoplay);
        
    //     $("#video-popup-iframe").on('load', function() {
    //       $("#video-popup-container").show();
    //     });
    //   });
      
    //   $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
    //     $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
    //     $("#video-popup-iframe").attr('src', '');
    //   });
      
    //   /* 
    //   minified
      
    //   $(".vpop").on("click",function(o){o.preventDefault(),$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();var p="",e="",i=$(this).data("id");if("vimeo"==$(this).data("type"))var p="//player.vimeo.com/video/";else if("youtube"==$(this).data("type"))var p="https://www.youtube.com/embed/";1==$(this).data("autoplay")&&(e="?autoplay=1"),$("#video-popup-iframe").attr("src",p+i+e),$("#video-popup-iframe").on("load",function(){$("#video-popup-overlay, #video-popup-container").show()})}),$("#video-popup-close, #video-popup-overlay").on("click",function(o){$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").hide(),$("#video-popup-iframe").attr("src","")});
    //   */   

//     filterSelection("all")
// function filterSelection(c) {
//   var x, i;
//   x = document.getElementsByclassNameName("column");
//   if (c == "all") c = "";
//   for (i = 0; i < x.length; i++) {
//     w3RemoveclassName(x[i], "show");
//     if (x[i].classNameName.indexOf(c) > -1) w3AddclassName(x[i], "show");
//   }
// }

// function w3AddclassName(element, name) {
//   var i, arr1, arr2;
//   arr1 = element.classNameName.split(" ");
//   arr2 = name.split(" ");
//   for (i = 0; i < arr2.length; i++) {
//     if (arr1.indexOf(arr2[i]) == -1) {element.classNameName += " " + arr2[i];}
//   }
// }

// function w3RemoveclassName(element, name) {
//   var i, arr1, arr2;
//   arr1 = element.classNameName.split(" ");
//   arr2 = name.split(" ");
//   for (i = 0; i < arr2.length; i++) {
//     while (arr1.indexOf(arr2[i]) > -1) {
//       arr1.splice(arr1.indexOf(arr2[i]), 1);     
//     }
//   }
//   element.classNameName = arr1.join(" ");
// }


// // Add active className to the current button (highlight it)
// var btnContainer = document.getElementById("myBtnContainer");
// var btns = btnContainer.getElementsByclassNameName("btn");
// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener("click", function(){
//     var current = document.getElementsByclassNameName("active");
//     current[0].classNameName = current[0].classNameName.replace(" active", " ");
//     this.classNameName += " active";
//   });
// }
     
  }

render() {
  return (
    <div> 

<Header />

<div className="main-body">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="leftside1" className="lsidee left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span></a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span></a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span></a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="rightside" className="rside">

  <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Privacy Policy'} ]} />

<div className="our_story">
  
<div className="content_our_story">

<div className="con_os">

  
<div className="mission__statement">
  <h1 className="termsofuse">Privacy Policy</h1>
</div>


</div>

</div>

</div>




<div className="abt_us_page_here">
  
  <div className="abtus_pg">
    
<p>Protecting your private information is our priority. This Statement of Privacy applies to www.Veriipro.com and Veriipro Technologies, LLC and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Veriipro Technologies, LLC include www.Veriipro.com and Veriipro. The Veriipro website is a Job Portal site. By using the Veriipro website, you consent to the data practices described in this statement.</p>

<h3>Collection of your Personal Information</h3>



<p>In order to better provide you with products and services offered on our Site, Veriipro may collect personally identifiable information provided by you, such as your: </p>

<ul>
  
<li>First and Last Name</li>
<li>Mailing Address</li>
<li>E-mail Address</li>
<li>Phone Number</li>
<li>Employer</li>
<li>Relevant Professional Experience details</li>
<li>Education details</li>
<li>Professional Certification detail</li>
<li>Social media links</li>
<li>Salary information</li>
<li>Professional references</li>
<li>Professional skills</li>

</ul>
<p>Veriipro may also collect anonymous demographic information, which is not unique to you, such as your:</p> 

    
<ul>
  
<li>Age</li>
</ul>



  <h3>Materials Provided to www.Veriipro.com or Posted on Any Veriipro Web Page</h3> 
  
<p>Veriipro does not claim ownership of the materials you provide to www.Veriipro.com (including feedback and suggestions) or post, upload, input or submit to any Veriipro Site or our associated services (collectively "Submissions"). However, by posting, uploading, inputting, providing or submitting your Submission you are granting Veriipro, our affiliated companies and necessary sublicenses permission to use your Submission in connection with the operation of their Internet businesses including, without limitation, the rights to: copy, distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in connection with your Submission.</p>

<p>No compensation will be paid with respect to the use of your Submission, as provided herein. Veriipro is under no obligation to post or use any Submission you may provide and may remove any Submission at any time in Veriipro's sole discretion.</p>

<p>By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section including, without limitation, all the rights necessary for you to provide, post, upload, input or submit the Submissions.</p>


  <h3>International Users</h3> 
  
<p>The Service is controlled, operated and administered by Veriipro from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Veriipro Content accessed through www.Veriipro.com in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>


  <h3>Indemnification</h3> 
  
<p>You agree to indemnify, defend and hold harmless Veriipro, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Veriipro reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Veriipro in asserting any available defenses.</p>

  <h3>Arbitration </h3> 
  
<p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>

  <h3>className Action Waiver</h3> 
  
<p>Any arbitration under these Terms and Conditions will take place on an individual basis; className arbitrations and className/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR className MEMBER IN ANY PUTATIVE className, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Veriipro agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or className proceeding.</p>


  <h3>Liability Disclaimer</h3> 
  
<p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. VERIIPRO TECHNOLOGIES, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>

<p>VERIIPRO TECHNOLOGIES, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. VERIIPRO TECHNOLOGIES, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>

<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL VERIIPRO TECHNOLOGIES, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF VERIIPRO TECHNOLOGIES, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>


  <h3>Termination/Access Restriction</h3> 
  
<p>Veriipro reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of California and you hereby consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>

<p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Veriipro as a result of this agreement or use of the Site. Veriipro's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Veriipro's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Veriipro with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>

<p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Veriipro with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Veriipro with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

  <h3>Changes to Terms</h3> 
  
<p>Veriipro reserves the right, in its sole discretion, to change the Terms under which www.Veriipro.com is offered. The most current version of the Terms will supersede all previous versions. Veriipro encourages you to periodically review the Terms to stay informed of our updates.</p>

<h3>Contact Us</h3>
<p>Veriipro welcomes your questions or comments regarding the Terms:</p>
<ul>
<li>Veriipro Technologies, LLC</li>
<li>30941 Agoura Road, Suite 128</li>
<li>Westlake Village, California 91361</li>
<br/>
<li>Email Address:</li>
<li>learning@veriipro.com</li>
<br/>
<li>Telephone number:</li>
<li>+1-805-277-4900</li>
<br/>
<li>Effective as of October 01, 2019</li>
</ul>
<br/>
  </div>

</div>


<Footer />

</div>


</div>
</div>




<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
 <script src="js/jquery.js"></script>                
<script src="js/bootstrap.min.js"></script>  
<script>

</script>

<script type="text/javascript">
</script>

<script type="text/javascript">
</script>


</div>



  );
}

}
export default Privacy;
