import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';

import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';

//const $ = window.$;

class Tips extends React.Component {

  constructor(){
    super();
  }

  componentDidMount(){
     
  }

render() {
  return (
    <div> 

<Header />

<div className="main-body">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="leftside1" className="lsidee left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span></a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span></a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span></a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="rightside" className="rside">

  
  <Breadcrumb path={[{ link: '/', label: 'Home' }, { link: '/instructorportal', label: 'Instructor'}, { link: '', label: 'Tips' }]} />

<div className="our_story">
  
<div className="content_our_story">

<div className="con_os">

  
<div className="mission__statement">
  <h1 className="termsofuse">Tips and Guidelines</h1>
</div>


</div>

</div>

</div>




<div className="abt_us_page_here">
  
  <div className="abtus_pg">
  <div class="gulid_line_here">

<div class="left_gulid">

<h4>Tips and guidelines for preparing a course structure</h4>

<p>Plan and create a clear learning path for students including the following:</p>

<ul>

<li>Creating an Introduction summary which consists of a self introduction and communicating clear learning objectives of course. Prepare the content for the introduction summary and a short 2 min to 10 min impressive introduction video.</li>

<li>The skills you’ll teach and how it can help students in their work and career. It is recommended to follow a sequential and systematic approach while preparing course lessons/topics. Make a topic length of 15 min to 60 min which covers one concept to keep students interested and help them study in short bursts. Introduce each topic by describing the topic goal and why it’s important. Give Topics and Sub-Topics titles that reflect their content and have a logical flow.</li>

<li><strong>Appropriate videos </strong>- It is good practice to prepare course videos along with visual content for giving students a good learning experience. Alternate between filming yourself, your screen, and slides or other visuals. Showing yourself can help students feel connected.</li>

<li><strong>Practical activities and Assignments </strong>- At the end of each topic include practical activities and provide assignments for the evaluation of students. Practice activities and assessments create hands-on learning. Help students apply your lessons to their real-world with projects, assignments, coding exercises, or worksheets.</li>

<li><strong>Q & A </strong>- Encourage students to make use of the Question and Answer forum for clarifying their doubts. Actively participate in answering questions asked by students.</li>

</ul>

</div>

<div class="right_gulid">

<h2>Requirements:</h2>

<ul>

<li>The course is composed of appropriate and valuable educational content. </li>
<li>The course has at least five lessons/topics.</li>
<li>All lessons/topics add up to at least 30+ minutes of total video.</li>
<li>Publishing a new course is subject to approval. </li>

</ul>
</div></div></div>
</div>


<Footer />

</div>


</div>
</div>




<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
 <script src="js/jquery.js"></script>                
<script src="js/bootstrap.min.js"></script>  
<script>

</script>

<script type="text/javascript">
</script>

<script type="text/javascript">
</script>


</div>



  );
}

}
export default Tips;
