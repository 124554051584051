import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'


import { get, post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';


class TrainerIndex extends React.Component {

    constructor() {
        super();
        this.state = {
            profileCompleted: 'loading'
        }
    }

    componentDidMount() {
      if(localStorage.ud_token)
       get('profilecompletenesscheck', { }).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
          this.setState({ profileCompleted: true })
        } else {
          this.setState({ profileCompleted: false })
          //notify.show('Something Went Wrong!', 'error');
        }
      });
    }

    render() {
      if(localStorage.ud_token && this.state.profileCompleted===true)
      return  <Redirect to={'/instructorportal/mycourses'} />

      if(localStorage.ud_token && this.state.profileCompleted===false)
      return  <Redirect to={'/instructorportal/profile'} />
            
        return (
            <>
            <Header  trainerPage={true} /> 

<div className="main-body">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span><span className="side_txt">  Home </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span><span className="side_txt"> Trending </span> </a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span><span className="side_txt"> Subscription </span> </a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="leftside1" className="lsidee left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span></a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span></a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span></a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="rightside" className="rside">

	<div className="home_top_banner">
  <div className="back_ground_imge_overlay">
    <p className="box_conten_banner_text">
   Do online teaching and make your global presence
  </p>

<p className="st_my_r_mnt"> 
{ (localStorage.ud_token)?
<Link to={'/instructorportal/profile'} id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" > Become a Trainer</Link> :
<Link to={'/login'} id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" > Become a Trainer</Link>
}
</p>

<p className="aavm"><Link to={'/login'} >Join Us Buliding Carrer of Tomorrow</Link></p>

  </div>
</div>

<div className="body_main">
 
<div className="main">
  
<div className="trainer_home">
  
  <div className="icon_with_text">
    <div className="tm_icon">
      <img src="/images/teach.png" alt="" />
    </div>
    <div className="tm_text">Teach, Inspire, and Earn</div>
  </div>

  <div className="icon_with_text">
    <div className="tm_icon">
      <img src="/images/teach.png" alt="" />
    </div>
    <div className="tm_text">Share your knowledge and experience with aspiring minds</div>
  </div>

  <div className="icon_with_text">
    <div className="tm_icon">
      <img src="/images/teach.png" alt="" />
    </div>
    <div className="tm_text">Join community of best in class feculty and industry professional</div>
  </div>

  <div className="icon_with_text">
    <div className="tm_icon">
      <img src="/images/teach.png" alt="" />
    </div>
    <div className="tm_text">We bring remrkable difference in changing lives, through our online learning market place</div>
  </div>


</div>
<div className="home_page_center_btn">
<p className="st_my_r_mnt">
{ (localStorage.ud_token)?
<Link to={'/instructorportal/profile'} id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" > Become a Trainer</Link>:
<Link to={'/login'} id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" > Become a Trainer</Link>
}
</p>
</div>
</div>

	</div>

    <Footer />

    </div>
</div>
</div>
    </>
        );
    }
}

export default TrainerIndex;