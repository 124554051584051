import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'


import { get, post, put,PostFiles } from './../../services/Apicall';
import { notify } from 'react-notify-toast';


import * as Helper from './../../services/Helper';

class TrainerProfile extends React.Component {

  constructor() {
    super();
    this.state = {
      loading: false,
      languages: [],
      business_subject:[],
      technology_subject:[],
      creative_subject:[],
      marketing_subject:[],

      data: {

        business_can_teach:[],
        creative_can_teach:[],
        marketing_can_teach:[],
        technology_can_teach:[],
        other_can_teach:'',
        video_intro:'',
        website:'',
        facebook:'',
        linkedin:'',
        twitter:'',
        github:'',
        stackoverflow:'',
        language:'',
        biography:'',
        image:'',
        professional_title:'',
        other_language:'',

        qualification_teaching_exp:'',
        cources_like_to_teach:'',
        language_proficiency:'',





      }
    }
  }

  componentDidMount() {
    this.setState({ loading: true });
    get('fetchprofile', {}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ data: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
    get('getmaster', { type: 'language' }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ languages: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
    get('getcategory', { pid: 1 }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ business_subject: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong wile loading busines ssubject!', 'error');
      }
    });
    get('getcategory', { pid: 2 }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ technology_subject: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong wile loading technology  subject!', 'error');
      }
    });
    get('getcategory', { pid: 3 }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ creative_subject: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong wile loading creatives subject!', 'error');
      }
    });
    get('getcategory', { pid: 4 }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ marketing_subject: responseJson.data, loading: false })
      } else {
        notify.show('Something Went Wrong wile loading marketing subject!', 'error');
      }
    });

    
  }

  onChange=(e) =>{
    let data = Object.assign({}, this.state.data);    //creating copy of object
    data[e.target.name] = e.target.value;                        //updating value
    this.setState({ data });
  }

  onFileChange=(e) =>{
    let data = Object.assign({}, this.state.data);
    data[e.target.name] = e.target.files[0]; 
    this.setState({ data });
  }

  processRadioButton = (e,type, id) => {
    var data = {...this.state.data}
    
    var cbarray = document.getElementsByName(type);
    if(e.target.checked==true){
      data[type] = id;
      for(var i = 0; i < cbarray.length; i++){
        if(cbarray[i]!=e.target)
            cbarray[i].checked = false;
            
      }
    }else{
      data[type] = '';
    }

    this.setState({data})
  }

  processCheckbox = (type, id) => {
    var data = {...this.state.data}
    console.log(data);
    console.log(type)
    console.log(data[type])
     var target = data[type];
     
     if (target.includes(id)) {
         var index = target.indexOf(id);
         if (index !== -1)
             target.splice(index, 1);
         //this.setState({ ['cb_' + type + id]: true })
     } else {
         target.push(id)
        //this.setState({ ['cb_' + type + id]: false })
     }
     data[type] = target;
     this.setState({data})
 }

 updateprofile = (e)=>{
   e.preventDefault();

   PostFiles('updateprofile',this.state.data,'POST').then((result) => {
    let responseJson = result;
    if (responseJson.error == false) {
      this.setState({ loading: false })
      notify.show('Updated Succesfully!', 'success');
      const {data}=this.state;
      localStorage.setItem('ud_fname',data.first_name);
      localStorage.setItem('ud_lname',data.last_name);
      localStorage.setItem('ud_mobile',data.phone);
      localStorage.setItem('ud_email',data.email);
      if(data.image)
      localStorage.setItem('ud_image',data.image_url+data.image);
    } else {
      notify.show('Something Went Wrong wile updating profile!', 'error');
    }
  });

 }


  render() {
const {data, marketing_subject,technology_subject,creative_subject,business_subject,languages}=this.state;
    return (
      <>
        <Header trainerPage={true} />

        <div className="main-body instructor_profile_form">
          <div className="container-fluid">
            <div id="leftside" className="lside left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt="" /> </span><span className="side_txt">  Home </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt="" /> </span><span className="side_txt"> Trending </span> </a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt="" /> </span><span className="side_txt"> Subscription </span> </a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt="" /> </span><span className="side_txt"> Course </span> </a> </li>
                  {/* <!-- 			<li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="leftside1" className="lsidee left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt="" /> </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt="" /> </span></a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt="" /> </span></a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt="" /> </span></a> </li>
                  {/* <!-- 			<li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="rightside" className="rside">

              <div className="body_main">

                <div className="main">


                  <div className="trainer_profile_edit_here">

                    <div className="profile_edit">

                      <h2> My Profile </h2>

                    </div>

                    <div className="profile_information">

                      <div className="profile_information_edit">

                      <form onSubmit={this.updateprofile} > 
                        <div className="pl_com_pro_info">

                          <p><span>Please Complete your profile information</span> </p>

                          <div className="edit_profile_here">

                            <div className="edit_row">


                                <div className="group half_it_here">
                                  <input placeholder="First Name" type="text" maxLength='25' name="first_name" value={data.first_name}   onChange={this.onChange}  required  />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>First Name</label>
                                </div>

                                <div className="group half_it_here hih">
                                  <input placeholder="Last Name" type="text" maxLength='25' name="last_name"   value={data.last_name}   onChange={this.onChange}  required />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>Last Name</label>
                                </div>

                                <div className="group half_it_here">
                                  <input placeholder="Email" type="email" name="email" value={data.email}   onChange={this.onChange} />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>Email</label>
                                </div>

                                <div className="group half_it_here hih mob">
                                  <span className="country_code_here">
                                    <input className="no-arrow" type="number" name="" value="" placeholder="+1" readonly/></span>
                                  <input placeholder="Mobile No." className="no-arrow"type="number" maxLength="10" min='1000000000' name="phone" value={data.phone}   onChange={this.onChange}  style={{ paddingLeft: 52 }} />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>Mobile No.</label>
                                </div>


                                <div className="group half_it_here full_width">
                                  <input placeholder="Professional Title" type="text" name="professional_title" value={data.professional_title}   onChange={this.onChange} />
                                  <span className="highlight"></span>
                                  <span className="bar"></span>
                                  <label>Professional Title</label>
                                </div>


                                <div className="group half_it_here other_topic_section">
                                  {/* <input placeholder="Bio...." type="text" name="biography" value={data.biography}   onChange={this.onChange} /> */}
                      
                                  <label>Bio</label>
                                  <textarea row="3"  name="biography" value={data.biography}   onChange={this.onChange} placeholder=""></textarea>

                                </div>



                                {/* <span> <sup>*</sup> incase he change email it will undergo email verification </span> */}
                              {/* </form> */}
                              <div className="subject_topics">

                                <h4>What Business Subjects/Topics You can teach</h4>
                                <div className="new">
                                  {/* <form> */}
                                  {business_subject.map((item,index)=>{
                                   return <div className="form-group">
                                      <input type="checkbox" id={"business_can_teach"+item.value} name="custom-radio-btn"   onChange={()=>this.processCheckbox('business_can_teach',item.value)}  defaultChecked={data.business_can_teach.includes(item.value+'')} />
                                      <label className="custom-radio" for={"business_can_teach"+item.value}></label>
                                      <span className="label-text">{item.label}</span>
                                    </div>
                                  })
                                  }
                                   
                                  {/* </form> */}
                                </div>

                                <h4>What Technology Subjects/Topics You can teach</h4>
                                <div className="new">
                                {technology_subject.map((item,index)=>{
                                   return <div className="form-group">
                                      <input type="checkbox" id={"technology_can_teach"+item.value} name="custom-radio-btn"   onChange={()=>this.processCheckbox('technology_can_teach',item.value)}  defaultChecked={data.technology_can_teach.includes(item.value+'')}/>
                                      <label className="custom-radio" for={"technology_can_teach"+item.value}></label>
                                      <span className="label-text">{item.label}</span>
                                    </div>
                                  })
                                  }


                                </div>

                                <h4>What Creative Subjects/Topics You can teach</h4>
                                <div className="new">
                                {creative_subject.map((item,index)=>{
                                   return <div className="form-group">
                                      <input type="checkbox" id={"creative_can_teach"+item.value} name="custom-radio-btn"   onChange={()=>this.processCheckbox('creative_can_teach',item.value)} defaultChecked={data.creative_can_teach.includes(item.value+'')} />
                                      <label className="custom-radio" for={"creative_can_teach"+item.value}></label>
                                      <span className="label-text">{item.label}</span>
                                    </div>
                                  })
                                  }
                                
                                </div>

                                <h4>What Marketing Subjects/Topics You can teach</h4>
                                <div className="new">
                              
                                {marketing_subject.map((item,index)=>{ 
                                   return <div className="form-group">
                                      <input type="checkbox" id={"marketing_can_teach"+item.value} name="custom-radio-btn"   onChange={()=>this.processCheckbox('marketing_can_teach',item.value)}  defaultChecked={data.creative_can_teach.includes(item.value+'')} />
                                      <label className="custom-radio" for={"marketing_can_teach"+item.value}></label>
                                      <span className="label-text">{item.label}</span>
                                    </div>
                                  })
                                  }

                                </div>


                              </div>

                              <div className="other_topic">

                                <div className="other_topic_section">

                                  <h4>Other topics that you are qualified to teach ?</h4>

                                  <textarea maxLength={100} name="other_can_teach" value={data.other_can_teach}   onChange={this.onChange} placeholder="100 Character limit ...."></textarea>

                                </div>

                                <div className="other_topic_section">

                                  <h4>Tell Us about your qualification and teaching experience</h4>

                                  <textarea row="3" maxLength={200} name="qualification_teaching_exp"  value={data.qualification_teaching_exp}   onChange={this.onChange} placeholder="200 Character limit ...."></textarea>

                                </div>


                                <div className="other_topic_section">

                                  <h4>Tell us About the course you'd like to teach</h4>

                                  <textarea  maxLength={200} name="cources_like_to_teach" value={data.cources_like_to_teach}   onChange={this.onChange}  placeholder="200 Character limit ...."></textarea>

                                </div>

                              </div>

                            </div>

                          </div>

                          <div className="presonal_infomation_section">

                            <div className="per_info_sec">

                              <div className="upload_image_section">

                                <h3>Uplpoad your Professional Pofile pic</h3>
                                <div className="demo_pic">
                                <img src={data.image? data.image_url+data.image:"/images/user-dummy.png"} />
                           
                                </div>
                                {/* <label>Browse... </label> */}
                                <div className="upload-btn-wrapper">
                                  <button type="button" className="btn">Update Profile Pic</button>
                                  <input type="file" name="image_file" onChange={(e)=>this.onFileChange(e)} accept="image/*" />
                                </div>
                                     <span className='profile_nodate'>{data.image_file?.name}</span>
                              </div>

                              <div className="professional_links">
                                <h3>Social Profile Link</h3>
                                <div className="pro_links_all edit_row">
                                  {/* <form> */}

                                    <div className="group">
                                      {/* <span className="pro_link_prefix">https://www.linkedin.com/</span> */}
                                      <input placeholder="https://www.linkedin.com/_____" type="text" name="linkedin" value={data.linkedin} maxLength={125} onChange={this.onChange}  required />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Linkind Profile</label>
                                    </div>

                                    <div className="group">
                                      {/* <span className="pro_link_prefix">https://stackoverflow.com/</span> */}
                                      <input placeholder="https://stackoverflow.com/_____" type="text" name="stackoverflow" value={data.stackoverflow} maxLength={125} onChange={this.onChange}  required />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Stack overflow</label>
                                    </div>

                                    <div className="group">
                                      {/* <span className="pro_link_prefix">https://github.com/</span> */}
                                      <input placeholder="https://github.com/_____" type="text" name="github" value={data.github}  onChange={this.onChange}  maxLength={125} required />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Github</label>
                                    </div>

                                    <div className="group">
                                      {/* <span className="pro_link_prefix">https://www.facebook.com/</span> */}
                                      <input placeholder="https://www.facebook.com/____" type="text" name="facebook" value={data.facebook} maxLength={125}  onChange={this.onChange}  required />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Facebook</label>
                                    </div>

                                    <div className="group">
                                      {/* <span className="pro_link_prefix">https://www.</span> */}
                                      <input placeholder="https://www.____" type="text" name="website" value={data.website} maxLength={125}  onChange={this.onChange}  required />
                                      <span className="highlight"></span>
                                      <span className="bar"></span>
                                      <label>Website</label>
                                    </div>


                                  {/* </form> */}

                                </div>

                              </div>

                              <div className="your_language">

                                <div className="language_radio">
                                  <h3> Your English Language Proficiency </h3>
                                  <div className="custom-radio-wrap">
                                    
                                  {/* {languages.map((item,index)=>{ 
                                   return <div className="form-group">
                                        <input id={"language"+item.value} type="radio" name="custom-radio-btn"   onChange={(e)=>this.processRadioButton(e,'language',item.value)}  />
                                        <label className="custom-radio" for={"language"+item.value}></label>
                                        <span className="label-text">{item.label}</span>
                                      </div>

                                  })
                                } */}

<div className="form-group">
              <input id="beg" type="radio" name="custom-radio-btn"  onChange={(e)=>this.processRadioButton(e,'language_proficiency','Beginner')} defaultChecked={data.language_proficiency==='Beginner'} />
              <label className="custom-radio" for="beg"></label>
              <span className="label-text">Beginner</span>
            </div>
            <div className="form-group">
              <input id="med" type="radio" name="custom-radio-btn"   onChange={(e)=>this.processRadioButton(e,'language_proficiency','Intermediate')} defaultChecked={data.language_proficiency==='Beginner'} />
              <label className="custom-radio" for="med"></label>
              <span className="label-text">Intermediate</span>
            </div>
            <div className="form-group">
              <input id="exp" type="radio" name="custom-radio-btn"   onChange={(e)=>this.processRadioButton(e,'language_proficiency','Expert')}  defaultChecked={data.language_proficiency==='Beginner'} />
              <label className="custom-radio" for="exp"></label>
              <span className="label-text">Expert</span>
            </div>

                                
                                  </div>

                                </div>

                              </div>


                              {/* <div className="addres_here">
                                <div className="all_ddetail_here">

                                  <h3>Please Share your Address. </h3>
                                  <div className="add_here_only">
                                    <span>Address</span>
                                    <input type="text" name="" value="" placeholder="write your address here" />
                                  </div>

                                  <div className="coun_here_only">

                                    <span>Country</span>
                                    <select>
                                      <option value=""> India</option>
                                      <option value=""> U.K</option>
                                      <option value=""> U.S.A</option>
                                      <option value=""> Japan</option>
                                      <option value=""> England</option>
                                    </select>

                                  </div>

                                  <div className="state_here_only">

                                    <span>State</span>
                                    <select>
                                      <option value=""> Delhi</option>
                                      <option value=""> Newyork</option>
                                      <option value=""> U.P</option>
                                      <option value=""> L.A</option>
                                      <option value=""> chinatown</option>
                                    </select>

                                  </div>


                                </div>
                              </div> */}

<div className="other language">
                                <h3>Please Share any primary language. </h3>
                                <div className="lan_drop_down">
                                  <select name='language' value={data.language}  onChange={this.onChange} >
                                    <option value=''>--Select--</option>
                                  {languages.map((item,index)=>{ 
                                   return <option  value={item.value} >{item.label}</option>

                                  })
                                } 
                                  </select>

                                </div>
                              </div>

                              <div className="other language">
                                <h3>Please Share any other language. </h3>
                                <div className="lan_drop_down">
                                  <span>You are fluent in</span>
                                  <select name='other_language' value={data.other_language}  onChange={this.onChange} >
                                    <option value=''>--Select--</option>
                                  {languages.map((item,index)=>{ 
                                   return <option  value={item.value} >{item.label}</option>

                                  })
                                } 
                                  </select>

                                </div>
                              </div>

                              <div className="upload_image_section">

                                <h3>Uplpoad your Professional Demonstrate Video</h3>
                                <div className="demo_pic">
                                  <img src="/images/novideo.png" />
                                </div>
                                <label>Browse... </label>
                                <div className="upload-btn-wrapper">
                                  <button className="btn">Upload</button>
                                  <input type="file"  onChange={(e)=>this.onVideoChange(e)}  name="video" accept="video/mp4,video/x-m4v,video/*" />
                                </div>
                              </div>

                              <div className="final_submit_all_form">
                                <button type="submit">Save</button>
                              </div>

                            </div>

                          </div>

                        </div>
                        </form>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <Footer />

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TrainerProfile;