import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import CourseCardVerticle from './../../common/CourseCardVertical';
import { get, post } from './../../services/Apicall';
import * as Helper from './../../services/Helper';
import { notify } from 'react-notify-toast';

//const $ = window.$;

class Trainer extends React.Component {

  constructor(){
    super();
    this.state={
      data:{course:[],},
      image_url:''
    }
  }

  componentDidMount(){

//     filterSelection("all")
// function filterSelection(c) {
//   var x, i;
//   x = document.getElementsByClassName("column");
//   if (c == "all") c = "";
//   for (i = 0; i < x.length; i++) {
//     w3RemoveClass(x[i], "show");
//     if (x[i].className.indexOf(c) > -1) w3AddClass(x[i], "show");
//   }
// }

// function w3AddClass(element, name) {
//   var i, arr1, arr2;
//   arr1 = element.className.split(" ");
//   arr2 = name.split(" ");
//   for (i = 0; i < arr2.length; i++) {
//     if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
//   }
// }

// function w3RemoveClass(element, name) {
//   var i, arr1, arr2;
//   arr1 = element.className.split(" ");
//   arr2 = name.split(" ");
//   for (i = 0; i < arr2.length; i++) {
//     while (arr1.indexOf(arr2[i]) > -1) {
//       arr1.splice(arr1.indexOf(arr2[i]), 1);     
//     }
//   }
//   element.className = arr1.join(" ");
// }


// // Add active class to the current button (highlight it)
// var btnContainer = document.getElementById("myBtnContainer");
// var btns = btnContainer.getElementsByClassName("btn");
// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener("click", function(){
//     var current = document.getElementsByClassName("active");
//     current[0].className = current[0].className.replace(" active", " ");
//     this.className += " active";
//   });
// }


// $('.search-toggle').addClass('closed');

// $('.search-toggle .search-icon').click(function(e) {
//   if ($('.search-toggle').hasClass('closed')) {
//     $('.search-toggle').removeClass('closed').addClass('opened');
//     $('.search-toggle, .search-container').addClass('opened');
//     $('#search-terms').focus();
//   } else {
//     $('.search-toggle').removeClass('opened').addClass('closed');
//     $('.search-toggle, .search-container').removeClass('opened');
//   }
// });

// $(document).ready(function(){
//   $('[data-toggle="tooltip"]').tooltip();   
// });

get('trainerdetail', {tid :this.props.match.params.trainerId}).then((result) => {
  let responseJson = result;
  if (responseJson.data && responseJson.error === false) {
    this.setState({ data: responseJson.data,image_url : responseJson.image_url})
  } else {
    notify.show('Something Went Wrong!', 'error');
  }
});
   
  }

render() {
  const {data,image_url}=this.state;
  return (
    <div> 

<Header />

  <ul className="breadcrumb">
    <li><Link to="/">Home</Link></li>
    <li>Trainer</li>
  </ul> 
<div className="main-body">
<div className="container-fluid">

<div className="trainer_page_start">
  
<div className="trainer_banner_detail">
  <div className="trainer_detail">
    <div className="trainer_name">
      <p>{data.first_name+' '+(data.last_name? data.last_name: '')}</p>
      <div className="trainer_intro">
        <p>{data.professional_title}</p>
      </div>

      <div className="rating">
        <span className="st_ar">
        <i  className="fa">&#xf005;</i>
        <i  className="fa">&#xf005;</i>
        <i  className="fa">&#xf005;</i>
        <i  className="fa">&#xf005;</i>
        <i  className="fa">&#xf123;</i>
              <div className="revie_w_left">
        <div className="rev_iew_all_stars">
          
          {/* <div className="rat_ing_review_block">
            <span className="rating_of_mine_here">
              4.2
            </span>
            <span>(450 Rating)</span>
            <div className="interstr">
            <i className="fa"></i>
            <i className="fa"></i>
            <i className="fa"></i>
            <i className="fa"></i>
            <i className="fa"></i>
        </div>
          </div> */}
          {/* <div className="al_l_star_sh_ow">
            <div className="my_rating_he_re">
              <span className="ra_te_out_of">5 star</span>
              <span className="rate_prog_ress">
                <div className="progress">
                  <div className="progress-bar excep" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:'90%'}}>
                    <span className="sr-only">90% Complete</span>
                  </div>
                </div>
              </span>
            </div>
            <div className="my_rating_he_re">
              <span className="ra_te_out_of">4 star</span>
              <span className="rate_prog_ress">
                <div className="progress">
                  <div className="progress-bar ab_av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:'75%'}}>
                    <span className="sr-only">75% Complete</span>
                  </div>
                </div>
              </span>
            </div>
            <div className="my_rating_he_re">
              <span className="ra_te_out_of">3 star</span>
              <span className="rate_prog_ress">
                <div className="progress">
                  <div className="progress-bar av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:'60%'}}>
                    <span className="sr-only">60% Complete</span>
                  </div>
                </div>
              </span>
            </div>
             <div className="my_rating_he_re">
              <span className="ra_te_out_of">2 star</span>
              <span className="rate_prog_ress">
                <div className="progress">
                  <div className="progress-bar be_av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:'10%'}}>
                    <span className="sr-only">10% Complete</span>
                  </div>
                </div>
              </span>
            </div>
            <div className="my_rating_he_re">
              <span className="ra_te_out_of">1 star</span>
              <span className="rate_prog_ress">
                <div className="progress">
                  <div className="progress-bar unsat" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{width:'30%'}}>
                    <span className="sr-only">30% Complete</span>
                  </div>
                </div>
              </span>
            </div>
            <div className="c_al_review">
              <a href="">See All 456 coustomer Review</a>
            </div>
          </div> */}

        </div>
      </div>
      </span>

      </div>


    </div>

    <div className="trainer_social_icon">
<div className="icons_trainer">
        
{data.website?<a href={Helper.url(data.website)} target="_blank" className="traicon"><i className="fa fa-globe"></i></a>:null}
{data.linkedin_profile_url?<a href={Helper.url(data.linkedin_profile_url)} target="_blank" className="traicon"><i className="fa fa-linkedin"></i></a>:null}
{data.twitter?<a href={Helper.url(data.twitter)} target="_blank" className="traicon"><i className="fa fa-twitter"></i></a>:null}
{data.facebook?<a href={Helper.url(data.facebook)} target="_blank" className="traicon"><i className="fa fa-facebook-f"></i></a>:null}
{data.stackoverflow?<a href={Helper.url(data.stackoverflow)} target="_blank" className="traicon"><i className="fa fa-stack-overflow"></i></a>:null}
{data.github?<a href={Helper.url(data.github)} target="_blank" className="traicon"><i className="fa fa-github"></i></a>:null}
      </div>
    </div>

    <div className="trainer_student_enroll">
      <div className="trainer_pop">

        <div className="t_course_view">
          <p>Students reviews</p>
            <p className="trainer_main_data_record">{this.state.data.student_review_count}</p>
          </div>
        <div className="t_course_enroll">
          <p>Total Courses</p>
          <p className="trainer_main_data_record">{this.state.data.course_count}</p>
        </div>
        <div className="t_student_enroll">
          <p>
            <span>Enrolled Students </span>
            <sup className="review_point"><a href="#" data-toggle="tooltip" title="This is the number of unique students across all courses including historical courses for this instructor.">!</a></sup>
          </p>
          <p className="trainer_main_data_record">{this.state.data.enrolled_student_count}</p>
        </div>
      </div>
    </div>

  </div>
</div>

<div className="trainer_page_explain">
  <div className="trainer_page">
<div className="trainer_profile">
<img className="trainer_pic" src={data.image?image_url+data.image:'/images/user-dummy.png'} alt=""/>
  </div>

  <div className="trainer_summery_detail">

<div className="trainer_summery">
  
<p>{data.biography}</p>
</div>

  </div>
</div>
</div>



<div className="trainer_course_page_no">
  <div className="trainer_all_course">
    
<h2>Courses taught by {data.first_name+' '+(data.last_name? data.last_name: '')}</h2>

<ul>

{
  data.course.map((item, key) => {
      return <li className={"column "} key={key}>
        <CourseCardVerticle item={item} />
        </li>
    })
}
  
 

</ul>

  </div>
</div>
</div>

{/* <!-- END MAIN --> */}
</div>

	</div>

{/* <!-- FOOTER --> */}


{/* <!-- END FOOTER --> */}

{/* <!-- MAIN-FOOTER --> */}

<Footer />

{/* <!-- END MAIN-FOOTER --> */}

<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
 <script src="js/jquery.js"></script>                
<script src="js/bootstrap.min.js"></script> 

{/* <!-- <script src="js/lightslider.js"></script>  --> */}
{/* <!-- <script>
 $(document).ready(function() {
	$("#content-slider").lightSlider({
        loop:true,
        keyPress:true
    });npm
});
</script> --> */}
<script> </script>

<script type="text/javascript"></script>

<script> </script>

</div>



  );
}

}
export default Trainer;
