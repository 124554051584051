import React from 'react';
import {Link} from 'react-router-dom'
class TrainerSidebar extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
     var  cid=this.props.courseId
     const  { approveStatus,publishStatus,moduleExist}=this.props

        return (
<div className="dnc_left_bar tab">
<Link to={'/instructorportal/managecourse/'+cid} > <button className={(window.location.pathname=='/instructorportal/managecourse/'+cid)? " active" :""}>
<div className="custom-radios">
  <div>
    <input type="radio" id="color-1" name="color" value="color-1" checked />
    <label for="color-1">
      <span>
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
      </span>
    </label>
  </div>

  About course </div></button></Link>
 <Link to={'/instructorportal/manageobjective/'+cid} > <button  className={(window.location.pathname=='/instructorportal/manageobjective/'+cid)? " active" :""} >
<div className="custom-radios">
  <div>
    <input type="radio" id="color-2" name="color" value="color-2" checked />
    <label for="color-2">
      <span>
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
      </span>
    </label>
  </div>
  
  Target Individuals
</div></button></Link>
<Link to={'/instructorportal/managecurriculum/'+cid} >  <button  className={(window.location.pathname=='/instructorportal/managecurriculum/'+cid)? " active" :""}>
<div className="custom-radios">
  <div>
    <input type="radio" id="color-3" name="color" value="color-3" checked />
    <label for="color-3">
      <span>
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
      </span>
    </label>
  </div>
  
  Course content
</div></button></Link>
 <Link to={'/instructorportal/managepricing/'+cid} > <button  className={(window.location.pathname=='/instructorportal/managepricing/'+cid)? " active" :""} >
<div className="custom-radios">
  <div>
    <input type="radio" id="color-4" name="color" value="color-4" checked />
    <label for="color-4">
      <span>
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
      </span>
    </label>
  </div>
  
  Publish Course
</div></button></Link>
 <Link to={'/instructorportal/managepreview/'+cid} > <button  className={(window.location.pathname=='/instructorportal/managepreview/'+cid)? " active" :""}>
<div className="custom-radios">
  <div>
    <input type="radio" id="color-5" name="color" value="color-5" checked />
    <label for="color-5">
      <span>
        <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg" alt="Checked Icon" />
      </span>
    </label>
  </div>
  
  Course Preview 
</div></button></Link>
{/*onClick={this.publishseries} */}

{
  (moduleExist > 0) ? (approveStatus == '0' && publishStatus=='0') ?
  <Link to={'/instructorportal/submit/'+cid}  ><button className=" sub_btn_side"><div className="custom-radios"> Mark Publish & Submit for Review</div></button></Link>

    :
      (publishStatus == '1') ?
      <button className=" sub_btn_side" onClick={(e)=>this.props.unpublishseries(e)}><div className="custom-radios">UnPublish</div></button>
      :
      <button className=" sub_btn_side" onClick={(e)=>this.props.publishseries(e)}><div className="custom-radios">Publish the Series</div></button>
    : ''
}

{/* 
<Link to={'/instructorportal/submit/'+cid} ><button className="tablinks sub_btn_side" >
<div className="custom-radios">
  
  Submit for review
</div>
</button></Link> */}

</div>
        );
    }
}

export default TrainerSidebar;