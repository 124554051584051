import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';


//import ReCAPTCHA from "react-google-recaptcha";
import { get, put, post, deleteData } from './../../services/Apicall';
import * as Helper from './../../services/Helper';
import * as Constant from './../../services/Constant';
//import * as Configuration from './../../services/Configuration';
import { notify } from 'react-notify-toast';
//import { loader } from './../../services/Loader/Loader';

//const $ = window.$;

class ContactUs extends React.Component {

  constructor(){
    super(); 
    this.state = {
      processingApi:false,
      name: '',
      fname: '',
      lname: '',
      email: '',
      phone: "",
      msg: "",
      mobile: 1,
  };        
  }

  componentDidMount(){

  
  }


  contact = (e) => {
    e.preventDefault();
    // console.log(this.state)
    if (this.validateForm()) {
      this.setState({ processingApi: true });
      //loader.show();
      document.getElementById('loading').style.display = 'block';
      post('contactus', this.state).then((result) => {
        //loader.hide();
        document.getElementById('loading').style.display = 'none';
        let responseJson = result;
        //console.log(responseJson)
        if (responseJson.error === false) {

          notify.show('Thanks for contacting us. Our support team will get back to as soon as possible', "success");
          this.setState({
            name: '',
            fname: '',
            lname: '',
            email: '',
            phone: '',
            // city: '',
            // state: '',
            // subject: '',
            msg: '',
            // zip: '',
            redirect: false,

          });

        } else if (responseJson.error === true) {
          notify.show(responseJson.msg, 'error');

        } else {
          notify.show('Something went wrong!', "warning");
        }
        this.setState({ processingApi: false });
      });
    }
  }



  validateForm = () => {

    var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var regexPhone = /^[2-9]\d{2}[2-9]\d{6}$/;
    var regexName = /^(?=.{3,30}$)[a-zA-Z ]*$/;

    if (!regexName.test(this.state.name)) {
      notify.show("Enter a Valid Name", 'error'); return false;
    }/* else if (!this.state.state) {
      notify.show("Select a State", 'error'); return false;
    } else if (!this.state.city) {
      notify.show("Select a City", 'error'); return false;
    } */
    else if (!regexEmail.test(String(this.state.email).toLowerCase())) {
      notify.show("Enter a Valid Email", 'error'); return false;
    } else if (!regexPhone.test(String(this.state.phone).toLowerCase())) {
      notify.show("Enter a Valid 10 Digit US Phone No.", 'error'); return false;
    }/* else if (!this.state.subject) {
      notify.show("Select Subject", 'error');
      return false;
    }*/ else if (!this.state.msg) {
      notify.show("Enter Message", 'error');
      return false;
    } else {
      return true;
    }
  }


  onChange = (e) => {
    // alert(this.validateInput(value,name))
    if (this.validateInput(e.target.value, e.target.name))
      this.setState({ [e.target.name]: e.target.value })
    if (e.target.name == 'fname' || e.target.name == 'lname')
      this.setState({ name: this.state.fname + ' ' + this.state.lname })
  }


  validateInput = (value, name) => {
    if (name == 'fname' || name == 'lname') {
      var regexp = /[^a-zA-Z\s]/g; //onlu alpha and space
      return (regexp.test(value)) ? false : true; // if aplha and space only  return false  /^[a-z\d\-_\s]+$/i
    } else if (name == 'phone') {
      var regexp = /[^0-9]/g;
      return !regexp.test(value) // if numeric only  return false 
    } else {
      return true;
    }

  }

render() {

  if (this.state.redirect) {
    this.setState({ redirect: false });
    return (<Redirect to={'/'} />)
  }

  return (
    <div> 

<Header />


        <div className="main-body">
          <div className="container-fluid">
            <div id="rightside" className="rside">
                <ul class=" breadcrumb">
                  <li><Link to="/">Home</Link> </li>
                  <li>Contact us</li>
                </ul>

              <div class="container contact__us_main_sec">

                <h1 class="brand"><span>Contact Us</span></h1>

                <div class="wrapper">

                  <div class="company-info contact_us_info">
                    <h3>Contact Detail</h3>

                    <ul>

                      <li><i class="fa fa-mobile"></i>{Constant._SITE_CONTACT_PHONE}</li>
                      <li><i class="fa fa-envelope"></i>{Constant._SITE_CONTACT_EMAIL} </li>
                      <li class="contact_add_res"> <i class="fa fa-map-marker"></i> {Constant._SITE_CONTACT_ADDRESS_FULL}</li>
                    </ul>
                  </div>

                  <div class="contact contact_us_form">

                    <h2> Get in touch with us </h2>
                    <p>Feel free to use the contact form below to contact us Directly</p>


                    <form id="contact-form" onSubmit={this.contact}>
                      <div className="Whol__mne_fil">
                        <p>
                          <label>First Name <sub>*</sub></label>
                          <input type="text" name='fname' maxLength={20} value={this.state.fname} onChange={(e) => this.onChange(e)}   id="" />
                        </p>

                        <p>
                          <label>Last Name</label>
                          <input type="text" name='lname' maxLength={20} value={this.state.lname} onChange={(e) => this.onChange(e)}   id="" />
                        </p>
                      </div>
                      <div className="Whol__mne_fil">
                        <p>
                          <label>Email <sub>*</sub></label>
                          <input type="email" name="email" maxLength={75} value={this.state.email} onChange={(e) => this.onChange(e)}   id="email" />
                        </p>
                        <p>
                          <label>Phone <sub>*</sub></label>
                          <input type="text" name="phone" maxLength={10} value={this.state.phone} onChange={(e) => this.onChange(e)}   id="" />
                        </p>
                      </div>
                      <p class="full">
                        <label>Message <sub>*</sub></label>
                        <textarea name='msg' maxLength={500} value={this.state.msg} onChange={(e) => this.onChange(e)}   rows="5" id="message" ></textarea>
                      </p>
                      <div className="col-lg-12 recaptcha_style" >
                        {/* <ReCAPTCHA ref={e => (this.captcha = e)} sitekey={Constant._SITE_RECAPTCHA_SITE_KEY} onChange={(value) => { this.setState({ 'g-recaptcha-response': value }) }} /> */}

                      </div>
                      <p class="full">
                        <button class="contact_submit" type="submit">Send your message</button>
                      </p>

                    </form>
                  </div>

                </div>
              </div>


            </div>
          </div>


          <Footer />
        </div>

    {/* <!-- END MAIN-FOOTER --> */}
</div>



  );
}

}
export default ContactUs;        