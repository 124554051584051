import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'

//import VimeoUpload from 'vimeo-upload';
//import VimeoUpload from './../../services/vimeo-upload';
import ImageUploader from 'react-images-upload';
import { get, put,PostFiles, VimeoUpload as VU, VimeoCreate } from './../../services/Apicall';

import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';
import VimeoUpload from 'vimeo-upload';


class ManagePricing extends React.Component {

    constructor() {
		super();

		this.state = {
			progress:false,
      progressDone:0,
      progressTotal:0,
	  upload:false,
			loading: false,
			redirect: false,
			series_id: '',//this.props.match.params.courseId,
			formdata: {
				redirect: false,
				series_id: '',
				image_file: '',
				vimeo_video_file: '',
				series_type: 'FREE',
				series_price: '0.0',

			},
			seriesdata: {
				series_title: '',
				series_category: '',
				series_sub_category: '',
				series_primary_teaching: '',//topic
				series_sub_title: '',
				series_description: '',
				series_language: '',
				series_level: '',
				image_file: '',
				video_file: '',

			}
		};

		this.update = this.update.bind(this);
		this.onFileChange = this.onFileChange.bind(this);

		this.onDrop = this.onDrop.bind(this);
		this.onChange = this.onChange.bind(this);
		//this.updateSeries = this.updateSeries.bind(this);



	} //end constructor

	update(e) {
		e.preventDefault();
		//this.setState({loading:true});

		let imgfile = this.state.formdata.image_file;
		let seriesid=this.props.match.params.courseId;

		if (this.state.formdata.series_type && this.state.formdata.series_price!=='' && 
			(this.state.seriesdata.series_type!=this.state.formdata.series_type  || this.state.seriesdata.series_price!=this.state.formdata.series_price
				|| this.state.seriesdata.series_duration_type!=this.state.formdata.series_duration_type ||this.state.seriesdata.series_duration!=this.state.formdata.series_duration
				 )
		) {

		document.getElementById('loading').style.display='block';
			put('updateseriesprice', this.state.formdata,).then((result) => {
				let responseJson = result;
				if ( responseJson.error == false) {
					notify.show(responseJson.msg, 'success');
					//this.setState({redirect: true});
				} else if (responseJson.error == true) {
					notify.show(responseJson.msg, 'error');

				}
				document.getElementById('loading').style.display='none';

			});
		} 

		if (this.state.formdata.image_file) {

		document.getElementById('loading').style.display='block';
			PostFiles('updateseriesimage',{ series_id: seriesid, image_file: imgfile }, 'POST').then((result) => {
				let responseJson = result;
				if (responseJson.error == false) {
					if (this.state.formdata.vimeo_video_file){
						notify.show('Image Uploaded Successfully.. Uploading Video... Please Wait..', 'success');
					}else{
					this.componentDidMount()
					document.getElementById('loading').style.display='none';
					notify.show(responseJson.msg, 'success');
					this.setState({redirect: true});
					}
				} else if (responseJson.error == true) {
					document.getElementById('loading').style.display='none';
					notify.show(responseJson.msg, 'error');

				}

			});
		}
		if (this.state.formdata.vimeo_video_file) {
			let file = this.state.formdata.vimeo_video_file;
			let series_id=this.props.match.params.courseId;

			this.createvideo();
		}
		

	}


	componentDidMount() {

		this.setState({ 'series_id': this.props.match.params.courseId })


		get('fetchseries', { series_id: this.props.match.params.courseId }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ seriesdata: responseJson.data })
				this.setState({ formdata: responseJson.data })


			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});



	}

	onChange(e) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name] = e.target.value;                        //updating value
		this.setState({ formdata });
	}


	onRadioChange(v) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata['series_type'] = v;                        //updating value
		if(v==='FREE')
			formdata['series_price'] = 0;   

		this.setState({ formdata });
	}



	onFileChange(e) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name] = e.target.files[0];                        //updating value
		this.setState({ formdata });
	}

	onDrop(picture) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		//				pictures: this.state.pictures.concat(picture),
		formdata['image_file'] = picture[0];// e.target.files[0];                        //updating value
		this.setState({ formdata });
	}

	createvideo = async (e)=> {
		
	
	
		if (this.state.formdata.vimeo_video_file) {
			let file = this.state.formdata.vimeo_video_file;
			let series_id=this.props.match.params.courseId;
		
	
		  document.getElementById('loading').style.display = 'block';
	
		  var uploader = new VimeoUpload({
			file: file,
			token: 'ef0e5c1b2d5ceb62aed80130ed489a1e',
	
			name: 'Course Intro Video',
			description: '',
			//private: true,
			//private: document.getElementById('make_private').checked,
			//upgrade_to_1080: document.getElementById('upgrade_to_1080').checked,
			onError: function (data) {
			  notify.show('Error while Uploading Video ', 'error')
			  this.setState({progress:false ,progressDone: 0, progressTotal:0,vimeo_video_file:null });
			  //+ JSON.parse(data).error
			},
			onProgress: (data)=> {
			  //updateProgress(data.loaded / data.total)
			  document.getElementById('loading').style.display = 'block';
			  this.setState({progress:(data.loaded/data.total)*100 ,progressDone: data.loaded, progressTotal:data.total })
			},
			onComplete:  (videoId, index) =>{
			  
			  if (videoId) {
	
				put('updateseriesvideo', { series_id: series_id, video_id: videoId}).then((result) => {
				  let responseJson = result;
				  if (responseJson.error == false) {
					document.getElementById('loading').style.display = 'none';
					//this.closevideo();
					this.componentDidMount();
					
					notify.show('Video Uploaded Successfuly', 'success');
				  } else {
	
					document.getElementById('loading').style.display = 'none';
					notify.show('Something Went Wrong!', 'error');
				  }
				  this.setState({progress:false ,progressDone: 0, progressTotal:0,vimeo_video_file:null });
				});
	
			  }
	
			}
	
	
		  });
	
		  uploader.upload();
	
		}
	  }
	


    render() {
        return (
            <>
            <Header trainerPage={true} upload={this.state.formdata.vimeo_video_file?true:false} progress={this.state.progress} progressDone={this.state.progressDone} progressTotal={this.state.progressTotal}  /> 
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />

    <div className="main-body">
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
    <div className="btac ttl_tg_all">&#8735;</div>
  <p className="aabtx ttl_tg_all"><Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>
  <p className="crs_name ttl_tg_all">{this.state.formdata.series_title}</p>
  {/* <p className="crs_sts ttl_tg_all">inactive</p> */}
  </div>

  <div className="topic_exit">
    <Link to="/instructorportal/tips">Tips <i className="fa fa-info-circle"></i></Link>
  </div>
</div>

<TrainerSidebar courseId={this.props.match.params.courseId } />

<div className="dnc_right_bar">

<form onSubmit={this.update} >
<div id="Publish_Course" className="tabcontent">

<div className="design_new_course_form">

<div className="container">

<div className="dnc_crs_dur">
  
  <div className="dnc_crs_dur_title">
    <p>Course duration</p>
    <div className="dnc_crs_dur_content">
      <div className="dnc_crs_dur_content_input">
        <input type="number" name="series_duration" value={this.state.formdata.series_duration} placeholder="Duration"   onChange={this.onChange} required  />
      </div>
      <div className="dnc_crs_dur_content_drop">
        <select className="form-control" name="series_duration_type" value={this.state.formdata.series_duration_type}  onChange={this.onChange}  required>
          <option value="MIN">MINUTES</option>
          <option value="HOUR">HOUR</option>
          <option value="WEEK">WEEK</option>
          <option value="MONTH">MONTH</option>
        </select>
      </div>
    </div>
  </div>

</div>

<div className="dnc_crs_dur">
  
  <div className="dnc_crs_dur_title">
    <p className="ptype">Pricing type</p>
    <div className="dnc_crs_dur_content">
  <div className="dnc_radio">
    <input id="radio-1" type="radio"  name="series_type" value='FREE'  onChange={()=>this.onRadioChange('FREE')} checked={this.state.formdata.series_type === 'FREE'} required  />
    <label for="radio-1" className="radio-label">Free</label>
  </div>

  <div className="dnc_radio">
    <input id="radio-2" type="radio"   name="series_type" value='PAID' onChange={()=>this.onRadioChange('PAID')}  checked={this.state.formdata.series_type === 'PAID'} required />
    <label  for="radio-2" className="radio-label">Paid</label>
  </div>

    </div>
  </div>

</div>

<div className="dnc_crs_dur">
  
  <div className="dnc_crs_dur_title">
    <p>Course price</p>
    <div className="dnc_crs_dur_content">
      <div className="dnc_crs_dur_content_input">
        <input type="number" max='0' max="999.99"  name="series_price" readOnly={this.state.formdata.series_type === 'FREE'} value={this.state.formdata.series_price}  placeholder="Price" title="Price"  onChange={this.onChange} required />
      </div>
      <div className="dnc_crs_dur_content_drop">
        <select className="form-control" name="series_price_currency" onChange={this.onChange} value={this.state.formdata.series_price_currency} >
          <option value="">--Select Currency--</option>
          <option value="USD">USD</option>
        </select>
      </div>
    </div>
  </div>

</div>

<div className="dnc_crs_img">
  <div className="dnc_crs_img_title">
    <p>Course image</p>
  </div>
  <div className="left_course_img">
    <img className="img-fluid img-thumbnail" src={(this.state.seriesdata.series_image)? this.state.seriesdata.image_url+this.state.seriesdata.series_image : '/images/cloud.jpg'} alt="" />
  </div>
  <div className="left_course_content">
    <div className="left_course_content_main">
    {/* <p>Make your course stand out with a great image from our design team based on your preferences and style.<a href=""> Get your free image</a>.</p> */}

    {/* <p>If you create your image, it must meet our <a href="">course image quality</a> standards to be accepted.</p> */}

    <p>Important guidelines:<strong> 750x422 pixels; .jpg, .jpeg,. gif, or .png.</strong> no text on the image.</p>

    <div className="left_course_content_upload">
      
      <div className="file-uploader--file-selector--SGCns" data-purpose="">
        {/* <label for="FileUploaderS3-0--8">
          <span className="input-group">
            <input readonly="" type="text" className="form-control" value="No file selected" />
            <span className="input-group-btn">
              <button type="button" className="btn btn-default">Upload file</button>
            </span>
          </span>
        </label> */}
        {/* <input accept=".gif,.jpg,.jpeg,.png" type="file" id="FileUploaderS3-0--8" className="" /> */}
        <ImageUploader name="image_file" withIcon={true} buttonText='Choose Preview Image' onChange={this.onDrop} imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
												maxFileSize={1024000} label='Max file size: 1Mb, accepted: Only Image Files' singleImage={true} withPreview={true} />
      </div>

    </div>

  </div>
</div>

</div>

<div className="dnc_crs_video">
  <div className="dnc_crs_img_title">
    <p>Indroduction video</p>
  </div>
  <div className="left_course_img">
 { (this.state.seriesdata.series_promo_video) ?    
			<iframe src={'https://player.vimeo.com/video/'+this.state.seriesdata.series_promo_video}  className="row" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							:
    <img src="/images/novideo.png" alt="course_"/>
  }
  </div>
  <div className="left_course_content">
    <div className="left_course_content_main">
    <p>Students who watch a well-made promo video are <strong>5X more likely to enroll </strong> in your course. We've seen that statistic go up to 10X for exceptionally awesome videos. <a href=""> Learn how to make yours awesome!</a></p>

    <div className="left_course_content_upload">
      
      <div className="file-uploader--file-selector--SGCns manageprice_inputfile" data-purpose="">
        <label for="FileUploaderS3-0--8" className="manage_inputfile">
          {/* <span className="input-group"> */}
            <input readonly="" type="text" className="form-control" value={this.state.formdata.vimeo_video_file?this.state.formdata.vimeo_video_file.name:"No file selected"} />
            {/* <span className="input-group-btn"> */}
              <button type="button" className="btn btn-default manage_inputbtn">Select file</button>
        <input type="file" name="vimeo_video_file" accept="video/mp4,video/x-m4v,video/*" onChange={this.onFileChange}  style={{display:'block'}} />

            {/* </span> */}
          {/* </span> */}
		{/* <button type="button" className="btn btn-default manage_inputbtn">Upload file</button>
        <input type="file" name="vimeo_video_file" accept="video/mp4,video/x-m4v,video/*" onChange={this.onFileChange}  style={{display:'block'}} />
         */}

        </label>
		
      </div>

    </div>

  </div>
</div>

</div>
<div className="dnc_crs_atert bs-example"> 
    <div className="alert alert-warning alert-dismissible show">
        <strong><span>&#9888;</span>  Before publising!</strong> trainer profile should be complete.
        <button type="button" className="close" data-dismiss="alert">&times;</button>
    </div>
</div>
</div>
{/* <!-- /.container --> */}
<div className="continue_btn">
<button type="submit" className="btn btn-lg btn-primary">Save and continue</button>
</div>

</div>
</div>
</form>

</div>


</div>

</div>
{/* <!-- END MAIN --> */}
</div>


  
    <Footer  NoNavigation={true} />
    </>
        );
    }
}

export default ManagePricing;