import React from 'react';
import { Link } from 'react-router-dom'
import Rating from 'react-rating';
import { queryHelpers } from '@testing-library/react';
import * as Helper from './../services/Helper';

class CourseCardTrainer extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        var item = this.props.item;
        return (



      <div className="dec_course_info">
      <Link className="column people show" to={"/instructorportal/managecourse/"+item.series_id}  >
      <div className="container">
        <div className="col-md-3 col-lg-3 all_course_item">
          <img src= {(item.series_image)? item.image_url+item.series_image : "/images/cloud.jpg" } alt="Chicago" /> 
          {/* <span className="imge_edit course_edit_pen"><i className="fa">&#xf040;</i></span>         */}
        </div>
        <div className="col-md-9 col-lg-9 trainer_course_detail_page">
          <div className="dec_back_info">
          <div className="col-md-8 col-lg-8">
          <div className="al_crs_title">
            <h3>{item.series_title}</h3>
          </div>
    <div className="fc_rating trin_cour">
     {item.approved && item.published?<span className="t_status">Active</span>:
         !item.approved && !item.published ?<span className="t_status draft">Draft</span>:
            !item.approved && item.published ?<span className="t_status inact">Under Review</span>:
            <span className="t_status inact">In-Active</span>
         }
     
     {/* <span className="t_status inact">Inactive</span> */}
  
    <span className="">{item.series_lecture_count} lectures</span>
    <span className="">{Helper.sec2HrMin(item.series_lecture_duration)} Duration</span>
    <span className="">{item.series_article_count} articles</span>
    
    <span className="">{(item.series_level=='1')? 'Beginner Level' : (item.series_level=='2')? 'Intermediate Level' : (item.series_level=='3')? 'Expert Level' : 'All Level' }</span>  
    <span className="">last updated : <strong>{item.updated_on}</strong> </span>  
      <span className="">Students enroll : <strong>{item.series_enrolled}</strong> </span>
    </div>
    <div className="dec_detail_info">{item.series_sub_title}</div>
  
          </div>
          <div className="col-md-4 col-lg-4">
        <span className="dec_rate dec_all course_edit_pen"><i className="fa">&#xf040;</i></span>
        <span className="dec_rate dec_all">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>   
        {/* <s className="dec_all"> <span className="dec_real dec_all">₹ 1034</span></s> */}
          <div className="dec_star dec_all">
          {/* <span>
          <i  className="fa">&#xf005;</i>
          <i  className="fa">&#xf005;</i>
          <i  className="fa">&#xf005;</i>
          <i  className="fa">&#xf005;</i>
          <i  className="fa">&#xf123;</i>
        </span> */}
         <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating}/>
        <span className="dec_rate_me">{item.series_rating}</span>
        </div>
          <span className="dec_total_rateing dec_all">({item.series_ratedby} rating)</span>
          </div>
        </div>
      </div>
      </div>
    </Link>
    </div>

        );
    }
}

export default CourseCardTrainer;
