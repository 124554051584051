import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { post, DeviceId, DeviceType } from './../../services/Apicall';
import { notify } from 'react-notify-toast';


class Forgot extends React.Component {

	constructor(){
		super();
	   
		this.state = {
			verify: '',
			email: '',
		 password: '',
		 user_id:'',
		 redirectToReferrer: false
		};
	
		this.login = this.login.bind(this);
		this.verifyaccount = this.verifyaccount.bind(this);
		this.setnewpass = this.setnewpass.bind(this);
		this.resendotp = this.resendotp.bind(this);
		this.onChange = this.onChange.bind(this);
	
	  }

    componentDidMount() {
        // $('.mybtn').on('click', function(event) {
        //     event.preventDefault();
        //     var url = $(this).data('target');
        //     window.location.replace(url);
        // });
    }

    login(event) {
      event.preventDefault();
      if(this.state.email){
        post('forgetpassword',this.state).then((result) => {
         let responseJson = result;
         if(responseJson.error===false){         
          notify.show('OTP Sent to the registered Email Address!','success');
          this.setState({verify:1});
         }else{
          notify.show('Not a valid Email, Please Verify and Try again!','warning');
          
         }
         
        });
      }else{
        notify.show('Email Address is required!','warning');
      }
      
     }

     verifyaccount(event) {
      event.preventDefault()
      if(this.state.otp && this.state.email){
        post('forgetotpverify',this.state).then((result) => {
         let responseJson = result;
         if(responseJson.error===false){         
          notify.show('OTP Verified Successfully... Please Create a New Password!','success');
          this.setState({verify:''});
          this.setState({user_id:responseJson.user_id});
         }else{
          notify.show('Invalid OTP Try again with Valid OTP!','warning');
          
         }
         
        });
      }
      
     } 

     setnewpass(event) {
      event.preventDefault()
      if(this.state.newpassword && this.state.newpassword===this.state.confirmpassword){
        post('setnewpassword',this.state).then((result) => {
         let responseJson = result;
         if(responseJson.error===false){         
          notify.show('Password Reset Successfull... Please Login','success');
          this.setState({redirectToReferrer:true});
         }else{
          notify.show('Something Went Wrong Try Again!','warning');
          
         }
         
        });
      }else{
        notify.show('Please Enter Same Password in Confirm Password field','warning');
      }
      
     }

     resendotp(event) {
      event.preventDefault()
      if(this.state.email){
        post('resendforgetotp',this.state).then((result) => {
         let responseJson = result;
         if(responseJson.error===false){         
          notify.show('OTP Sent to the registered Email Address','success');
          this.setState({redirect:true});
         }else{
          notify.show('Something Went Wrong Try Again!','warning');
          
         }
         
        });
      }
      
     }

       onChange(e){
        this.setState({[e.target.name]:e.target.value});
       }

    render() {
      if (this.state.redirectToReferrer) {
        return (<Redirect to={'/'}/>)
        }
       
        if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/'}/>)
        }
        return (
            <div className="signuppage">
                <div className="logo">
  
  <Link to={'/'}> <img className="logo_img" src="/images/whitelogo.png" alt="Veriipro Learning" /></Link>
  <h1>WE ENSURE BETTER EDUCATION FOR A BETTER WORLD</h1>
  
  
  <form id="regForm" onSubmit={this.login} style={ (this.state.verify==='' && this.state.user_id==='')?  {display:'block'} :  {display:'none'} } >
    <h1>Reset Password</h1>
    {/* <!-- One "tab" for each step in the form: --> */}
  
      <p><input placeholder="Email ID..." oninput="this.className = ''" name="email" onChange={this.onChange} required className="single-input" /></p>
    
    <div style={{overflow:'auto'}}>
      <div className="down_bottom">
         <button type='button'  id="nextBtn "  className="ntvp"><Link to="/login" className="already_oelv" >I remember my Password </Link></button> 
         <button type="submit" id="nextBtn">Reset</button>
      </div>
    
    </div>
    {/* <!-- Circles which indicates the steps of the form: --> */}
  </form>

  <form id="regForm" onSubmit={this.verifyaccount} style={ (this.state.verify!='')?  {display:'block'} :  {display:'none'} }>
    <h1>Enter OTP</h1>
    {/* <!-- One "tab" for each step in the form: --> */}
  
      <p><input placeholder="Enter OTP..." oninput="this.className = ''" name="otp" onChange={this.onChange} required className="single-input-secondary" type="password" /></p>
    
    <div style={{overflow:'auto'}}>
      <div className="down_bottom">
      <button type="button" id="" className="ntvp" onClick={this.resendotp}> <a href="#" className="already_oelv" >Resend OTP</a></button>
      <button type="submit" id="nextBtn" >Verify</button>
      
      </div>
    
    </div>
    {/* <!-- Circles which indicates the steps of the form: --> */}
  </form>

  <form id="regForm" onSubmit={this.setnewpass} style={ (this.state.user_id!='')?  {display:'block'} :  {display:'none'} }>
    <h1>Set New password</h1>
    {/* <!-- One "tab" for each step in the form: --> */}
  
      <p><input placeholder="New Password..." oninput="this.className = ''" name="newpassword" onChange={this.onChange} required className="single-input-secondary" type="password" /></p>
      <p><input placeholder="Confirm New Password..." oninput="this.className = ''" name="confirmpassword" onChange={this.onChange} required className="single-input-secondary" type="password" /></p>
    
    <div style={{overflow:'auto'}}>
      <div className="down_bottom">
      <button type="submit" id="nextBtn" >Set Password</button>
      
      </div>
    
    </div>
    {/* <!-- Circles which indicates the steps of the form: --> */}
  </form>
  </div>
            </div>
        );
    }
}

export default Forgot;
