import React from 'react';
import { Link } from 'react-router-dom'
import * as Constant from './../services/Constant'
class Footer extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>


                {/* <!-- FOOTER --> */}

                <div className="footer" style={(this.props.NoNavigation === true) ? { display: 'none' } : null}>

                    <div className="container-fluid">

                        <ul className="foter">

                            <p>Job Seekers</p>
                            <li><a href={Constant._SITE_JOB_URL} target='_blank'>Job Seeker Login</a></li>
                            <li><a href={Constant._SITE_JOB_URL} target='_blank'>Free Job Alert</a></li>
                            <li><a href={Constant._SITE_JOB_URL+'jobs'} target='_blank'>Job Search</a></li>
                            <li><a href={Constant._SITE_JOB_URL+'jobs'} target='_blank'>Find Job</a></li>
                            <li><a href={Constant._SITE_JOB_URL} target='_blank'>Help</a></li>

                        </ul>
                        <ul className="foter">

                            <p>Employers</p>

                            <li><a href={Constant._SITE_JOB_EMPLOYER_URL} target='_blank'>Employer Login</a></li>
                            <li><a href={Constant._SITE_JOB_EMPLOYER_URL} target='_blank'>Job Posting</a></li>
                            <li><a href={'#'} target='_blank'>Advertise with Us</a></li>
                            <li><a href={Constant._SITE_JOB_EMPLOYER_URL} target='_blank'>Access Resume Database</a></li>

                        </ul>
                        <ul className="foter">

                            <p>E-Learning</p>

                            <li><Link to={'/browse-course'}>Trending Course</Link></li>
                            <li><Link to={'/mycourse/saved'}>Saved</Link></li>
                            <li><Link to={'/mycourse/learning'}>Learning</Link></li>
                            <li><Link to={'/mycourse/learning'}>My Courses</Link></li>
                            <li><Link to={'/instructorportal'}>Become a Trainer</Link></li>
                            <li><Link to={'/'}>Become a Student</Link></li>

                        </ul>
                        <ul className="foter">

                            <p>Veriipro</p>

                            <li><Link to={'/about'}>About Us</Link></li>
                            <li><Link to={'/contact'}>Contact Us</Link></li>
                            <li><Link to={'/contact'}>Send Feedback</Link></li>
                            <li><a href={'/sitemap.xml'}>Sitemap</a></li>

                        </ul>
                        <ul className="foter social">

                          <p>Connect</p>

                            <span><a href={Constant._SITE_SOCIAL_LINKEDIN} target='_blank' ><i style={{ fontSize: 35 }} className="fa">&#xf08c;</i></a></span>
                            <span><a href={Constant._SITE_SOCIAL_TWITTER} target='_blank'><i style={{ fontSize: 35 }} className="fa">&#xf081;</i></a></span>
                            <span><a href={Constant._SITE_SOCIAL_FACEBOOK} target='_blank'><i style={{ fontSize: 35 }} className="fa">&#xf230;</i></a></span>


                        </ul>




                    </div>

                </div>

                {/* <!-- END FOOTER --> */}

                {/* <!-- MAIN-FOOTER --> */}

                <div className="footterr">

                    <div className="container-fluid">

                        <div className=" col-lg-3 col-md-3 cpy_rgh">
                            <span className="ftrtxt">{Constant._SITE_COMPANY_NAME} &copy; {new Date().getFullYear()}</span>
                        </div>
                        <div className=" col-lg-3 col-md-3 cpy_rgh ftr">
                            <Link to={'/privacy'}><span> Privacy Policy </span></Link>
                            <Link to={'/terms-of-use'}><span> Terms Of Use  </span></Link>
                        </div>
                        <div className=" col-lg-3 col-md-3 ftr mob_mail">
                            <div className="icon_based" style={{ paddingTop: 3 }}>
                                <i style={{ fontSize: 24 }} className="fa fa-phone" aria-hidden="true">
                                </i>
                                <div className="text_base"><a href={'tel:'+Constant._SITE_CONTACT_PHONE}> {Constant._SITE_CONTACT_PHONE}</a></div></div>

                            <div className="icon_based"><i style={{ fontSize: 24 }} className="fa"></i> <div style={{ paddingTop: 5 }} className="text_base"><a href={'mailto:'+Constant._SITE_CONTACT_EMAIL}>{Constant._SITE_CONTACT_EMAIL}</a></div></div>

                        </div>
                        <div className=" col-lg-3 col-md-3 fologo">
                            <Link to='/'>
                            <span>
                                <img src="/images/whitelogo.png" alt="Veriipro Learning Logo" />
                            </span></Link>
                        </div>

                    </div>

                </div>

                {/* <!-- END MAIN-FOOTER --> */}

            </div>
        );
    }
}

export default Footer;