import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'


import { get, post } from './../../services/Apicall';
import * as Helper from './../../services/Helper';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
//import Vimeo from 'react-vimeo';
import Player from '@vimeo/player';

//const $ = window.$;

 class CourseExplore extends React.Component {

  constructor(){
    super();

    this.state = {
			unauthorized:false,
			editreview:false,
			reviewmode: 'init',
			activequestion:{
				answer:[]
			},
			rformdata:{
				rating:0,
				review:'',
				que_title:'',
				que_describe:'',
			},
			  data:{
				  series_id:2,
				  series_title:"Title of Series",
				  series_sub_title:"Sub Title of Series",
				  series_description:"Description about series",
				  series_language:"English",
				  series_level:"All",
				  series_category:"Category",
				  series_sub_category:"Subcategory",
				  series_primary_teaching:"Topic",
				  series_promo_video:null,
				  series_trainer:"Trainer Name",
				  series_image:null,
				  approved:1,
				  created_on:"Date",
				  updated_on:"Date",
				  image_url:"http:\/\/training.local\/publicdata\/series\/image\/",
				  objective:[
					  {
						  value:"",
						  position:1
						}
					],
					requirment:[
						{
							value:"",
							position:1
						}
					],
					target:[
						{
							value:"",
							position:1
						}
					],
					modules:[
						{
							module_name:"",
							module_position:1,
							module_content:[
								{
									conten_type:"lecture",
									position:1,
										id:1,
										title:"",
										description:"",
										content_type:"V",
										video:"21208357",
										article:"",
										downloadable:0,
										created_on:1554873664,
										updated_on:1554873664,
										resources:[
											{
												id:1,
												widget_type:'lecture',
												widget_id:1,
												resource_type:"file",
												url:"",
												url_title:"",
												file:"file.txt",
												file_size:"101",
												file_name:"file.txt",
												created_on:111,
												updated_on:111
											}
										]
									
								}
							]
						}
					],
					reviewself: {},
					review: [],
					recomemded: [],
					reviewavg: 0,
					reviewcount: 0,
					enrolled:false,
					series_lecture_count:0,
				},		
			  mdata:{
				completed_lecture:[],
				  lecture_id:'',
				  lecture_title:"",
				  lecture_type:"",
				  lecture_description:"",
				  lecture_video:"",
				  lecture_article:"",
				  lecture_downloadable:0,
				  lecture_resourceuri:'#',
				  lecture_resources:[
					{
						id:1,
						widget_type:'lecture',
						widget_id:1,
						resource_type:"file",
						url:"",
						url_title:"",
						file:"file.txt",
						file_size:"101",
						file_name:"file.txt",
						created_on:111,
						updated_on:111
					}
				],
				  modules:[
						{
							module_name:"",
							module_position:1,
							module_content:[
								{
									conten_type:"lecture",
									position:1,
									
										id:1,
										title:"",
										description:"",
										content_type:"V",
										video:"",
										article:"",
										downloadable:0,
										created_on:1554873664,
										updated_on:1554873664,
										resources:[
											{
												id:1,
												widget_type:'lecture',
												widget_id:1,
												resource_type:"file",
												url:"",
												url_title:"",
												file:"file.txt",
												file_size:"101",
												file_name:"file.txt",
												created_on:111,
												updated_on:111
											}
										]
									
								}
							]
						}
					],
					questions:[],
					enrolled:false,
				}		
		};

    this.switch = this.switch.bind(this);
		this.editReview = this.editReview.bind(this);
		this.onChange = this.onChange.bind(this);
		this.changeRating = this.changeRating.bind(this);
		this.saveReview = this.saveReview.bind(this);
		this.cancelEdit = this.cancelEdit.bind(this);
		this.openinit = this.openinit.bind(this);
		this.openaskquestion = this.openaskquestion.bind(this);
		this.openquestion = this.openquestion.bind(this);
		this.saveQuestion = this.saveQuestion.bind(this);
		this.saveAnswer = this.saveAnswer.bind(this);
  }

  componentDidMount(){


$('.quest_ka_ans').click(function() {
  $(".her_di_no").toggle("dis_none");
  });
  
  $('.ask_question button').click(function() {
  $(".questions_answers_panel_here").toggleClass("dis_none");
  });
  $('.sub_can_btn').click(function() {
  $(".questions_answers_panel_here").toggleClass("dis_none");
  });

this.getSeriesData();


}

getSeriesData=()=>{
		get('seriesdetail',{seriesid:this.props.match.params.courseId}).then((result) => {
			let responseJson = result;
			if(responseJson.data && responseJson.error==false){
				this.setState({data: responseJson.data })
				var rformdata={
					rating: responseJson.data.reviewself.rating,
					review: responseJson.data.reviewself.review,
					seriesid: this.props.match.params.courseId
				}
				this.setState({rformdata: rformdata })
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		});
		get('exploreseries',{seriesid:this.props.match.params.courseId,moduleid:this.props.match.params.moduleId}).then((result) => {
			let responseJson = result;
			if( responseJson.error==false){
				this.setState({mdata: responseJson.data });
				this.initVimeoPlayer(responseJson.data.lecture_video);
			}else {
				this.setState({unauthorized:true})
			 notify.show('You are not authorized to view the requesteed Content!','warning');
			}
			

		 });

		 

	}

	initVimeoPlayer=(videoId)=>{

		if(this.player){
			this.player.unload();
		}else if(!this.player){
			var iframe = document.getElementById('mainvideoplayer');
			// console.log(iframe)
			try{
			this.player = new Player(iframe,{id:videoId});
			}catch(ex){

			}
		}
		if(this.player){
		this.player.loadVideo(videoId)


	 
		 this.player.on('play', (data) =>{
			 console.log('played the video!');
			 console.log(data);
			 //marklecturecomplete
			 this.markComplete();
			 this.player.off('play');
		 });
	 
		 this.player.getVideoTitle().then(function(title) {
			 console.log('title:', title);
		 });
		 this.player.getVideoId().then(function(title) {
			 console.log('title:', title);
		 });
		 this.player.on('ended', function(data) {
			console.log('on ended ')
			console.log(data);
			this.player.off('ended');
			// `data` is an object containing properties specific to that event
		  });
		}
	}

	switch(modp,lid){
		let selected_module_content = this.state.mdata.modules[modp];
		let data = selected_module_content.module_content.filter(x => x.id === lid).map(x => x);

		let  mdata = Object.assign({}, this.state.mdata);    //creating copy of object
		mdata['lecture_id']=data[0].id;                      //updating value
		mdata['lecture_video']=data[0].video;                      //updating value
		mdata['lecture_title']=data[0].title;                      //updating value
		mdata['lecture_description']=data[0].description;
		mdata['lecture_content_type']=data[0].content_type;
		mdata['lecture_article']=data[0].article;
		mdata['lecture_downloadable']=data[0].downloadable;
		mdata['lecture_resources']=data[0].resources;
		this.setState({mdata},()=>{
			this.initVimeoPlayer(mdata.lecture_video)
		});

	}


	markComplete=(e)=>{
		
		post('marklecturecomplete',{seriesid:this.state.data.series_id ,lectureid: this.state.mdata.lecture_id,  moduleid:null }).then((result) => {
			let responseJson = result;
			if( responseJson.error===false){
				let  mdata = Object.assign({}, this.state.mdata);
				mdata['completed_lecture']=responseJson.data?.completed_lecture;
				this.setState({mdata});
				//this.getSeriesData()
				//notify.show('Saved Successfully!','success');
				//this.setState({editreview: false })
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		 });
	
		e.preventDefault();

	}

	saveReview(e){
		
		post('updateReview',this.state.rformdata).then((result) => {
			let responseJson = result;
			if( responseJson.error===false){
				this.getSeriesData()
				notify.show('Saved Successfully!','success');
				this.setState({editreview: false })
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		 });
	
		e.preventDefault();

	}
	cancelEdit(e){
		this.setState({editreview: false });
		var rformdata={
			rating: this.state.data.reviewself.rating,
			review: this.state.data.reviewself.review,
			seriesid: this.props.match.params.courseId
		}
		this.setState({rformdata: rformdata })
		e.preventDefault();
	}

	editReview(e){
		this.setState({editreview:true});
		e.preventDefault();
	}

	onChange(e){
		let  rformdata = Object.assign({}, this.state.rformdata);    //creating copy of object
		rformdata[e.target.name]= e.target.value;                        //updating value
		this.setState({rformdata});
  }

  changeRating(v){
		let  rformdata = Object.assign({}, this.state.rformdata);    //creating copy of object
		rformdata['rating']= v;                        //updating value
		this.setState({rformdata});
  }

  openaskquestion(e){
	  this.setState({reviewmode:'ask'});
	e.preventDefault();
  }

  openquestion(e,id){
	this.setState({reviewmode:'question'});
	var activedata  = this.state.mdata.questions.filter(x => x.id === id).map(x => x);
	this.setState({activequestion: activedata[0]});
	e.preventDefault();
  }
  openinit(e){
	this.setState({reviewmode:'init'});
	e.preventDefault();
  }


	saveQuestion(e){
		
		e.preventDefault();
		post('postquestion',this.state.rformdata).then((result) => {
			let responseJson = result;
			if( responseJson.error===false){
				this.getSeriesData()
				notify.show('Question Posted Successfully!','success');
				this.setState({reviewmode: 'init' })
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		 });
	
	}

	

	saveAnswer(e){
		
		e.preventDefault();
		var pd={seriesid : this.state.rformdata.seriesid,answer : this.state.rformdata.post_answer,qid:this.state.activequestion.id}
		post('postanswer',pd,).then((result) => {
			let responseJson = result;
			if( responseJson.error===false){
				this.getSeriesData()
				notify.show('Answer Posted Successfully!','success');

				let  rformdata = Object.assign({}, this.state.rformdata);  
				rformdata['post_answer']= '';   
				this.setState({rformdata});
				this.setState({reviewmode: 'init',activeQuestion2:'',activequestion:{answer:[]} })
				//this.openquestion(e,pd.qid)
			}else {
			 notify.show('Something Went Wrong!','error');
			}
		 });
	
	}


	// Rakesh
	openquestion2=(key,id)=>{
		var currentActive=this.state.activeQuestion2;
		if(currentActive==key)
		this.setState({activeQuestion2:''});
		else
		this.setState({activeQuestion2:key});


	var activedata  = this.state.mdata.questions.filter(x => x.id === id).map(x => x);
	this.setState({activequestion: activedata[0]});
	

		//e.preventDefault();
	  }
	
	



render() {

  if (this.state.unauthorized === true) {
		return <Redirect to='/' />
	  }

const {data,mdata}= this.state;
  return (
    <div> 

<Header />

<div className="main-body courseexplore">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
  <div className="side-bar">
    <ul className="left-side-bar">
      <li className="first"><a href="home.html"> <span className="side_icon"><img src="/images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
      <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
      <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
      <li className="fourth"><a href="course_login.html"> <span className="side_icon"><img src="/images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
      <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/histroy.png" alt=""/> </span><span className="side_txt"> History </span> </a> </li>      
      <li className="divider"></li>      
      <li className="fifth"><a href=""><span className="side_txt"> Best Of VeriiPro </span> </a> </li>
      <li className="sixth"><a href=""> <span className="side_icon"><img src="/images/trainer.png" alt=""/> </span><span className="side_txt"> Trainer </span> </a> </li>
      <li className="seventh"><a href=""> <span className="side_icon"><img src="/images/pc.png" alt=""/> </span><span className="side_txt"> Popular Courses </span> </a> </li>
      <li className="eigth"><a href=""> <span className="side_icon"><img src="/images/pt.png" alt=""/> </span><span className="side_txt"> Popular Topics </span> </a> </li>
      <li className="divider"></li>       
      <li className="ninth"><a href=""> <span className="side_icon"><img src="/images/ms.png" alt=""/> </span><span className="side_txt"> My Series </span> </a> </li>
      <li className="tenth"><a href=""> <span className="side_icon"><img src="/images/us.png" alt=""/> </span><span className="side_txt"> Upload Series </span> </a> </li>
      <li className="divider"></li>       
      <li className="elventh"><a href=""><span className="side_txt"> More From VeriiPro </span> </a> </li>
      <li className="elventh"><a href=""> <span className="side_icon"><img src="/images/job.png" alt=""/> </span><span className="side_txt"> Job </span> </a> </li>  
      <li className="divider"></li> 
      <li className="elventh"><a href=""><span className="side_txt"> My Account </span> </a> </li>
      <li className="elventh"><a href=""><span className="side_txt"> Help </span> </a> </li>  
      <li className="elventh"><a href=""><span className="side_txt"> Send Feedback </span> </a> </li>             
                                                     
{/* <!--      <li className="fivth"></li> --> */}
    </ul>
  </div>

</div>
<div id="leftside1" className="lsidee left_bar">
  <div className="side-bar">
    <ul className="left-side-bar">
      <li className="first active_side_bar"><a href="home.html"> <span className="side_icon"><img src="/images/home.png" alt=""/> </span> </a> </li>
      <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt=""/> </span></a> </li>
      <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt=""/> </span></a> </li>
      <li className="fourth"><a href="course_login.html"> <span className="side_icon"><img src="/images/course.png" alt=""/> </span></a> </li>
{/* <!--      <li className="fivth"></li> --> */}
    </ul>
  </div>

</div>
<div id="rightside" className="rside">
  <Breadcrumb path={[{link:'/',label:'Home'},{link:'/mycourse/learning',label:'My Course'},{link:'',label:this.state.data.series_title}, ]} />
<div className="body_main">

<div className="main explore_inner">
      <div className="bannerimg">
        <div className="bann">
          <div className="courseimg">
            <img src={this.state.data.series_image?this.state.data.image_url+this.state.data.series_image:"/images/cloud.jpg"} alt={this.state.data.series_title}/>
			
          </div>
          <div className="coursedetail">
            <p>Course</p>
            <h2>{this.state.data.series_title}</h2>
            <h3>{this.state.data.series_sub_title}</h3>
          </div>
        </div>
        <div className="banbotom">
            <div className="progress">
    <div className="progress-bar" role="progressbar" aria-valuenow={this.state.data.series_lecture_count?this.state.mdata.completed_lecture.length/this.state.data.series_lecture_count*100:0} aria-valuemin="0" aria-valuemax="100" style={{width:this.state.data.series_lecture_count?(this.state.mdata.completed_lecture.length/this.state.data.series_lecture_count)*100+'%':0+'%',color:'#000'}}>
      <span className="sr-only">{this.state.data.series_lecture_count?this.state.mdata.completed_lecture.length/this.state.data.series_lecture_count*100:0}% Complete</span>
    </div>
  </div>
        </div>
      </div>

<div className="container">
  <div className="col-md-9">
    <div className="detailss">
        {/* <!--Accordion wrapper--> */}

<div className="next_previous_lesson">
  <div style={{visibility:'hidden'}} className="pv_next_pages">
    <i style={{fontSize:'15px'}} className='fa'>&#xf3e5;</i>
    <span>Previous Topic</span>
  </div>
    <div className="crt_next_pages">
    {this.state.mdata.lecture_title}
  </div>
  { (this.state.mdata.next)?
    <div className="nx_next_pages">
    <a href="course-explore-video-2.html"> <span>Next Topic</span> 
    <i style={{fontSize:'15px'}} className="fa">&#xf064;</i></a>
  </div> :<></>
  }
</div>

<div className="topic_video_lesson explor_two">

{
  (this.state.mdata.lecture_video) ?  
  //<Vimeo videoId={ this.state.mdata.lecture_video } style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%'}} autoplay playerOptions={{autoplay: 1 }} /> 
	//	<ReactPlayer url={'https://player.vimeo.com/video/'+this.state.mdata.lecture_video} playing  width="560" height="349"  className="row" style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%'}} /> 
			 <iframe id='mainvideoplayer' src={'https://player.vimeo.com/video/'+this.state.mdata.lecture_video}  width="560" height="349"  className="row" style={{width: '100%'}} frameborder="0" allow="autoplay; fullscreen" allowFullScreen ></iframe>
    : (
		this.state.mdata.lecture_type=='T' ?
		<p>{this.state.mdata.lecture_article}</p>
		:<img  src={this.state.data.series_image?this.state.data.image_url+this.state.data.series_image:"/images/cloud.jpg"} alt="No Video" style={{width:'100%'}} />)
	}
  
{/* 
<div className="vpop" data-type="vimeo" data-id="17853140" data-autoplay="true">
      <i className="fa video_po_pup"></i>
    </div> */}

    {/* <!-- copy this stuff and down --> */}
{/* <div id="video-popup-overlay"></div> */}

{/* <div id="video-popup-container">
  <div id="video-popup-close" className="fade">&#10006;</div>
  <div id="video-popup-iframe-container">
    <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</div> */}


</div>
<div className="container"><h3> Course overview </h3></div>
<div id="exTab3" className="container"> 
<ul  className="nav nav-pills">
      <li className="active">
        <a  href="#1b" data-toggle="tab">Overview</a>
      </li>
      <li><a href="#2b" data-toggle="tab">Requirment</a>
      </li>
      {/* <li><a href="#3b" data-toggle="tab">NoteBook</a>
      </li> */}
      <li><a href="#4b" data-toggle="tab">Question and Answer</a>
      </li>
	  <li><a href="#5b" data-toggle="tab">Resources</a>
      </li>
	  <li><a href="#6b" data-toggle="tab">Review and Rating</a>
      </li>

    </ul>

      <div className="tab-content clearfix">
        
		<div className="tab-pane active" id="1b">
        <h4>About the Course</h4>
          <p>
			  {/* {this.state.data.series_description} */}
		  
		  {this.state.data.series_description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                              })}</p>

          <h4>Learning Objectives:</h4>
          <ul>
          {this.state.data.objective.map((item,key)=>{
										   return <li key={key}>{item.value}</li>
									   })}
          </ul>

          <h4>Target Individuals:</h4>
          <ul>
          {this.state.data.target.map((item,key)=>{
										   return <li key={key}>{item.value}</li>
									   })}
          </ul>
          
        </div>


        <div className="tab-pane" id="2b">
          <ul>
          {this.state.data.requirment.map((item,key)=>{
										   return <li key={key}>{item.value}</li>
									   })}

          </ul>
        </div>

        <div className="tab-pane" id="3b">

<div className="only_show_when_there_note">
  <span className="no_of_note">2</span><span className="note_name">Notes</span>
</div>
<div className="course_note_detail">
<p>Put Your External CSS in Head Section</p>

<textarea placeholder="Type your note here and press “Enter” to submit…" id="ember1807" className="course-notes__create-note t-14 ember-view"></textarea>

<div className="your_notes">
  <p> Your Notes</p>
</div>
<div className="your_note_box">
<div className="note_detail">
  <span className="topic_name_icon"><i style={{fontSize: '18px',padding: '5px',color: '#f44336',margin:'0px 10px',float: 'right'}} className="fa" aria-hidden="true"></i></span><span className="topic_name"> Course Overview </span>
</div>
<div className="course_note_detail test" id="test">
<p>Whats Going On in This video. I don't Understant</p>  <span className="video_time">00:10</span>
<a className="remImage" href="#" id="delete">
    <i className="material-icons">&#xe92b;</i>
  </a>   
</div>
<div className="course_note_detail  test" id="test">
<p>Basic Html and Css Learining</p>  <span className="video_time">00:40</span>
<a className="remImage" href="#" id="delete">
  <i className="material-icons">&#xe92b;</i>
  </a>
</div>
</div>
<div className="your_note_box">
<div className="note_detail">
  <span className="topic_name_icon"><i style={{fontSize: '18px',padding: '5px',color: '#f44336',margin:'0px 10px',float: 'right'}} className="fa" aria-hidden="true"></i></span><span className="topic_name"> Introduction </span>
</div>
<div className="course_note_detail test" id="test">
<p>Now i Understand Whats going on this video</p>  <span className="video_time">00:50</span>
<a className="remImage" href="#" id="delete">
  <i className="material-icons">&#xe92b;</i>
  </a>   
</div>
</div>
        </div>

</div>
          
		  <div className="tab-pane" id="4b">
    


          <div className="ask_que_wit_com_and_rev"  style={(this.state.reviewmode==='init')? {display : 'block'} :  {display : 'none'}} >
									   <span className="al__que_s">All Questions <i>({this.state.mdata.questions.length})</i> </span>
									<button className='generic-btn btn-success pull-right as__kqu_sion'  onClick={this.openaskquestion}>Ask Question</button>
										
						                <div className="comments-area">
										{ this.state.mdata.questions.map((item,key)=>{    
						                   return  <div className="comment-list" key={key}>
											{/* <a href='#' style={{textDecoration:'none'}} onClick={(e) => this.openquestion(e,item.id)}> */}
											{/* <a className="main__que__to_ans" href='#' style={{textDecoration:'none'}} > */}
						                        <div className="shoo__w_al_ans" onClick={()=>this.openquestion2(key,item.id)}>
						                            <div className="user justify-content-between d-flex">
						                                <div className="desc">
						                                    <h5>{item.title}</h5>
						                                   
						                                    <p className="comment" style={(this.state.activeQuestion2===key)? {display : 'block'} :  {display : 'none'}}  >
						                                       {item.content}
						                                    </p>
															  </div>
															  <p className="date mt-10"> Posted By : <span className="ser__nme"> {item.user_name} </span> |
															  <span className="ser__nme"> {Helper.formatDate(item.created_on)} </span> 
															  <span className="ans__wes_hide"> Answers <i> ({item.answercount}) </i> </span> 
															  {/* <span className="ans__wes_hide"> Views <i> ({item.answercount}) </i> </span>  */}
															  </p>
						                              
						                            </div>
						                            <div className="reply-btn">
														 <i className='fa fa-angle-down'></i>
						                            </div>

						                        </div>
												<div className="sho__all__ans_sec" style={(this.state.activeQuestion2===key)? {display : 'block'} :  {display : 'none'}}  >
												<div className="sh__sig__ans_one"> 
												<h5>Answers</h5>
												{this.state.activequestion.answer.map((item,key)=>{
												return <div className="user justify-content-between d-flex">
													<div className="desc">													
														<p className="comment">
														{item.answer}
														</p>
														</div>
														<p className="date mt-10"> Posted By : <span className="ser__nme"> {item.user_name} </span> |<span className="ser__nme">{Helper.formatDate(item.created_on)} </span>  </p>
												
												</div> 

												})
												}
												
												<div className="">
										<br/>
						                    <form onSubmit={this.saveAnswer}>
						                        <div className="form-group">
						                            <textarea className="form-control mb-10"  onChange={this.onChange}  value={this.state.rformdata.post_answer}   rows="5" name="post_answer" placeholder="Post Answer to the Quesion" required></textarea>
						                        </div>
						                        <button type="submit" className="mt-40 text-uppercase genric-btn primary text-center">Post Answer</button> 
						                    </form>
						                </div> 
												
												</div>
											</div>
												{/* </a> */}
											</div>  
										}) }

																					
						                </div>  
										</div> {/* Initial screen  */}
									
									<div  style={(this.state.reviewmode=='question')? {display : 'block'} :  {display : 'none'}}>
									<button className='generic-btn btn-default bac__toall__btn '  onClick={this.openinit}>Back to All Questions</button>
										
						                <div className="comments-area">
						                    <div className="comment-list">
											<a href='#' style={{textDecoration:'none'}} >
						                        <div className="single-comment justify-content-between d-flex">
						                            <div className="user justify-content-between d-flex">
						                                <div className="thumb">
						                                    <img src="/images/user-dummy.png" alt="" />
															<span>UserName </span>
						                                </div>
						                                <div className="desc">
						                                    <h5>{this.state.activequestion.title} </h5>
						                                   
						                                    <p className="comment">
															{this.state.activequestion.content}........
						                                    </p>
						                                </div>
															<p className="date mt-10"> December 4, 2017 at 3:12 pm </p>
						                            </div>
						                            <div className="reply-btn">
													<br/>
													{this.state.activequestion.answercount} <i className='fa fa-comments'></i>
						                            </div>
						                        </div>
												</a>
						                    </div>  

											{this.state.activequestion.answer.map((item,key)=>{
						                   return  <div className="comment-list left-padding">
						                        <div className="single-comment justify-content-between d-flex">
						                            <div className="user justify-content-between d-flex">
						                                <div className="thumb">
						                                    <img src="/images/user-dummy.png" alt="" />
															<span>UserName </span>
						                                </div>
						                                <div className="desc">
														<p className="comment">{item.answer}  </p>						                                    
						                                </div>														
														<p className="date mt-10"> December 4, 2017 at 3:12 pm </p>
						                            </div>
						                            {/* <div className="reply-btn">
						                                   <a href="" className="btn-reply text-uppercase">reply</a> 
						                            </div> */}
						                        </div>
											</div>   
											})}                                                
						                </div>
						                <div className="">
										<br/>
						                    <form onSubmit={this.saveAnswer}>
						                        <div className="form-group">
						                            <textarea className="form-control mb-10"  onChange={this.onChange}  value={this.state.rformdata.post_answer}   rows="5" name="post_answer" placeholder="Post Answer to the Quesion" required></textarea>
						                        </div>
						                        <button type="submit" className="mt-40 text-uppercase genric-btn primary text-center">Post Answer</button> 
						                    </form>
						                </div>  
										</div> {/* Question Detail screen  */}

										<div style={(this.state.reviewmode=='ask')? {display : 'block'} :  {display : 'none'}}>
						                <div className="comment-form">
						                    <h4>Ask Queston related to Series</h4>
						                    <form onSubmit={this.saveQuestion}>
						                        <div className="form-group">
						                            <input type="text" onChange={this.onChange} value={this.state.rformdata.que_title} className="form-control" name="que_title" placeholder="Question" required  />
						                        </div>
						                        <div className="form-group">
						                            <textarea className="form-control mb-10" onChange={this.onChange}  value={this.state.rformdata.que_descrption}  rows="5" name="que_description" placeholder="Description"  required></textarea>
						                        </div>
						                        <button type="submit" className="mt-40 text-uppercase genric-btn primary anceltext-center">Post Question</button> 
						                        <button type="submit" className="mt-40 text-uppercase genric-btn default text-center" onClick={this.openinit}>Cancel</button> 
						                    </form>
						                </div>  
										</div> {/* Question Form screen  */}

       
       
       
        </div>

        <div className="tab-pane" id="5b">
          <ul className="re__sor__ce">
		{mdata.lecture_resources.map((item,key)=>{
            return item.resource_type=='file'?<li className="tab_pane_download">
			<span><i className="fa fa-file"></i></span>
			<span><a href={Helper.url(mdata.lecture_resourceuri +item.file)} target='_blank'>{item.file_name}</a></span>
			</li>:<li className="tab_pane_download">
			<span><i className="fa fa-download"></i></span>
			<span><a href={Helper.url(item.file)}  target='_blank'>{item.url_title}</a></span>
			</li>
})}
          {mdata.lecture_resources.length==0? <li className="tab_pane_download">
		  No Resources available for the selected module.
		  </li>:null}

          </ul>
        </div>

        <div className="tab-pane" id="6b">

			
{/* review rating section */}
<div className="rev__rting">
                
				<div className="rev__rting">
				<div className="rrting__sec main___rting_show">
				<div className="col-sm-12">
				<div className="rev_iew_all_stars">
				<div  className="rat_ing_review_block">
				<h2 className="wit__yur__revie">
				Course Review Rating	
				</h2>
				<span  className="rating_of_mine_here"> {this.state.data.reviewavg} </span>
				<span> <i className="fa fa-user"></i> {this.state.data.reviewcount} students </span>
				<div  className="interstr">
				<div  className="my-rating" data-rating={this.state.data.reviewavg}>
				<Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={this.state.data.reviewavg}/>
				</div></div> </div>
				<div  className="al_l_star_sh_ow">
{/* 
				<div  className="my_rating_he_re">
				<span  className="ra_te_out_of">5</span>
				<span  className="rate_prog_ress">
				<div  className="progress">
				<div  className="progress-bar excep" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:90}}>
				<span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
				<div  className="my_rating_he_re">
				<span  className="ra_te_out_of">4</span>
				<span  className="rate_prog_ress">
				<div  className="progress">
				<div  className="progress-bar ab_av" role="progressbar" aria-valuenow="66.666666666667" aria-valuemin="0" aria-valuemax="100" style={{width:66}}>
				<span  className="sr-only">66.666666666667% Complete</span> </div> </div> </span> </div>
				<div  className="my_rating_he_re">
				<span  className="ra_te_out_of">3</span>
				<span  className="rate_prog_ress">
				<div  className="progress">
				<div  className="progress-bar av" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:50}}>
				<span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
				<div  className="my_rating_he_re">
				<span  className="ra_te_out_of">2</span>
				<span  className="rate_prog_ress">
				<div  className="progress">
				<div  className="progress-bar be_av" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:30}}>
				<span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
				<div  className="my_rating_he_re">
				<span  className="ra_te_out_of">1</span>
				<span  className="rate_prog_ress">
				<div  className="progress">
				<div  className="progress-bar unsat" role="progressbar" aria-valuenow="33.333333333333" aria-valuemin="0" aria-valuemax="100" style={{width:20}}>
				<span  className="sr-only">33.333333333333% Complete</span> </div> </div> </span> </div>
				 */}
				 </div> </div></div></div>
				<div className="rrting__sec main__comt_sec">
				<div className="col-sm-12">
				<div className="review-block">
				
				{ (Object.getOwnPropertyNames(this.state.data.reviewself).length!==1 && this.state.editreview===false)?	
				<div className="rrting__sec">
					<a href="#" className='pull-right' onClick={this.editReview}>Edit</a> 
                <div className="col-sm-2"><img src={this.state.data.reviewself.image?this.state.data.reviewself.image_url+this.state.data.reviewself.image:'/images/user-dummy.png'} className="img-rounded"/>
                <div className="review-block-name"><Link to="#">{this.state.data.reviewself.name}</Link></div></div>
                <div className="col-sm-10">
                <div className="review-block-rate">
                <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={this.state.data.reviewself.rating}/>
                <div className="review-block-date">{Helper.formatDate(this.state.data.reviewself.date)} </div></div>
                <div className="review-block-description">{this.state.data.reviewself.review}</div></div>
                </div>
				:<div className="rrting__sec">
				
				<h2 className="wit__yur__revie">
				Write Your Review
				</h2>
				<form method='' onSubmit={this.saveReview}>
				<div  className="my-rating wr__yr__rvw" data-rating="3.5">
				<Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" onChange={this.changeRating} fractions={2} initialRating={this.state.rformdata.rating}/>
				{/* <div className="jq-star" style={{width:16,  height:16}}><i className="fa fa-star"></i></div>
				<div className="jq-star" style={{width:16,  height:16}}><i className="fa fa-star"></i></div>
				<div className="jq-star" style={{width:16,  height:16}}><i className="fa fa-star"></i></div>
				<div className="jq-star" style={{width:16,  height:16 , color:'#efefef'}}><i className="fa fa-star"></i></div>
				<div className="jq-star" style={{width:16,  height:16 , color:'#efefef'}}><i className="fa fa-star"></i></div>   */}
				</div>
					<textarea name="review" placeholder='Type Review' onChange={this.onChange}   rows="4" value={this.state.rformdata.review}></textarea>
					<button  type="submit" className="submit rviwrt__btn">Submit</button>
					<button  type="button"  onClick={this.cancelEdit} className="cancel rviwrt__btn">cancel</button>
					</form>
				</div>}
				
				{this.state.data.review.map((item, key) => { 
                return <div className="rrting__sec">
                <div className="col-sm-2"><img src={item.image?item.image_url+item.image:'/images/user-dummy.png'} alt={item.name} className="img-rounded"/>
                <div className="review-block-name"><Link to="#">{item.name}</Link></div></div>
                <div className="col-sm-10">
                <div className="review-block-rate">
				<Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star checked" readonly="true"  fractions={2} initialRating={item.rating}/>
                <div className="review-block-date">{Helper.formatDate(item.date)} </div></div>
                <div className="review-block-description">{item.review}</div></div>
                </div>
                }) }
                
    
				</div></div></div></div>        
					</div>
				
				
				{/* end of review rating section */}

        </div>

  </div>
{/* <!-- Accordion wrapper --> */}
      </div>
  </div>

  </div>
  <div className="col-md-3">
    <div id="right_right_side">
    <div id="right_side">
    <div className="prodetai">
    {/* <!--Accordion wrapper--> */}

    
    {
			this.state.mdata.modules.map((item,key)=>{
				 return    <div className="panel panel-default"  key={key}>
                <div className="panel-heading" role="tab" id="headingOne">
                    <h4 className="panel-title">
<a role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapse"+key} aria-expanded="true" aria-controls={"#collapse"+key} className="">
Module {key+1}: {item.module_name}
                    </a>
                </h4>
                </div>
    <div className="panel-body">

 {
		item.module_content.map((module,keyM)=>{
		return <div className="well" key={keyM}>
            <Link to={"/explorecourse/"+this.props.match.params.courseId+'/'+module.id} onClick={(e) => this.switch(key,module.id, e)}>
            <span className="off_extra psidpane compl_yes">{keyM+1}</span>
			<span className="off_extra">Lecture {keyM+1}: {module.title}</span>
			<span className="off_extra psidpane"><i className={module.content_type=='T'?"fa fa-file":"fa fa-video-camera"}></i></span>
          </Link>
        </div>
        })
	}
 
</div>
 
  </div>
  })
  }

    {/* <!--Accordion wrapper--> */}
      </div>
          <div className="prodetail">
    {/* <!--Accordion wrapper--> */}
<h2 style={{textAlign: 'center',fontSize: '25px'}}>Your Co-ordinator</h2>
    <div className="imgpic">
      
      <img src={this.state.data.series_trainer_image?this.state.data.trainer_image_url+this.state.data.series_trainer_image:'/images/user-dummy.png'} alt=""/>
    </div>
    <div className="imgdet">
      <p className=""><strong>Name </strong>:
	   <Link to={"/instructor/"+data.series_trainer_id} >
	   {data.series_trainer}</Link> </p>
      <p className=""><strong>Title </strong> : {this.state.data.series_trainer_professional_title}</p>
      {/* <p className=""><strong>Availablilty </strong> : 4pm to 8pm</p> */}
	  {this.state.data.series_trainer_linkedin?
	  <p className=""><strong>LinkedIn</strong> : <a href={Helper.url(this.state.data.series_trainer_linkedin)} target="_blank">View Profile</a></p>
	  :null
	  }
      {/* <p className="help"> Can't find Your Co-ordinator</p> */}
      <p className="help"> For any query/feedback/complaint</p>

      <p className="">write Us on <a href="mailto:learning@veriipro.com">learning@veriipro.com</a> </p>

    </div>

    {/* <!--Accordion wrapper--> */}
      </div>

</div>
</div>
</div></div>
</div>
</div>


{/* <!-- FOOTER --> */}

<Footer/>

{/* <!-- END MAIN-FOOTER --> */}

{/* <!-- FOOTER --> */}

</div>
</div>

<div className="questions_answers_panel_here" style={(this.state.reviewmode=='ask')? {display : 'block'} :  {display : 'none'}}>

<form onSubmit={this.saveQuestion}>
          <div className="write_queans">
            <h4 className="as_qu">Ask Queston related to Series</h4>
<input className="que_please_here" type="text" placeholder="Write Question here" name="que_title"  onChange={this.onChange} value={this.state.rformdata.que_title} />
<textarea className="descr_que_here" name="que_descrption" placeholder="Describe Your Question Here" onChange={this.onChange} value={this.state.rformdata.que_descrption}  ></textarea>

<div className="btn_section_auesans">
  <button className="sub_can_btn" type="submit">Submit</button>
  <button className="sub_can_btn col_change" type="button"  onClick={this.openinit}>Cancel</button>
</div>
          </div>
          </form>
        </div>



</div>
</div>

 
  );
}

}
export default CourseExplore;