import React from 'react';
import { Link ,withRouter} from 'react-router-dom'
import Rating from 'react-rating';
import { put } from '../services/Apicall';
import { notify } from 'react-notify-toast';

class CourseCardVertical extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }

    saveCourse  = (series_id,action) =>{
      if(action=='login')
        this.props.history.push('/login')
      else
      put('saveseries', {action:action,series_id:series_id} ).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
            this.props.saveCourse(series_id,action)
        } else {
          notify.show('Something Went Wrong!', 'error');
        }
      });
    }


    render() {
        var item = this.props.item;
        return (



          <div className="content">
            <div className="img_withoverlay">
            <img src={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.jpg"}   alt={item.series_title} style={{width:'100%'}} />
            {(item.series_promo_video)?
            <>
            <div className="vpop" onClick={(e)=>this.props.vpopOpen(e)} data-type="vimeo" data-id={item.series_promo_video} data-autoplay="true">
              <i className="fa video_po_pup"></i>
            </div>
            {/* <div id="video-popup-overlay"></div> */}
            </>
            :
            null
            }
          </div>
          <div className="course_caed_detail_box">
           <Link to={item.slug?"/course/" + item.slug:"/course/" + item.series_id}>
              
              <h4>{item.series_title}</h4>
              </Link>    
              <p className="tutor"><Link to={item.series_trainer_slug?"/instructor/" + item.series_trainer_slug:"/instructor/" + item.series_trainer_id}>{item.series_trainer} </Link>  </p>
              <div className="rating">
                {/* <span>
                <i  className="fa">&#xf005;</i>
                <i  className="fa">&#xf005;</i>
                <i  className="fa">&#xf005;</i>
                <i  className="fa">&#xf005;</i>
                <i  className="fa">&#xf123;</i>
              </span> */}
              <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating}/>
                <span className="rate">{item.series_rating}</span>
                <span className="total">({parseInt(item.series_ratedby)})</span>
                <span className="rate card_save_sec">
                { 
                  (localStorage.ud_token)?
                  (item.saved)?
                <span className="fa fa-heart fa-2x pull-right"  title='Remove this Course from wishlist' onClick={()=>this.saveCourse(item.series_id,'remove')}></span>
                :
                <span className="fa fa-heart-o fa-2x pull-right" title='Save this Course' onClick={()=>this.saveCourse(item.series_id,'add')}></span>
                :

                <span className="fa fa-heart-o fa-2x pull-right" title='Login to Save this Course' onClick={()=>this.saveCourse(item.series_id,'login')}></span>
                }
                  
                  </span>
              </div>
              <div className="priceing">
                {/* <s> <span className="real">{(item.series_type=='PAID')? <span className="dec_real dec_all">$ {item.series_price}</span> : null }</span></s> */}
                <span className="offer">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>
              </div>
              </div>
            </div>
        

        );
    }
}

export default withRouter (CourseCardVertical);
