import React from 'react';
//import $ from 'jquery'
import { Link } from 'react-router-dom'
import Header from './../../common/Header'
import Footer from './../../common/Footer'
import Breadcrumb from './../../common/Breadcrumb'
import Rating from 'react-rating';
import { get,post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

const $ = window.$;

class LearningPath extends React.Component {

  constructor() {
    super();
    this.state = {
      data: {
        page_detail:{},
      career_path_item:[],
      trainer:[],
    }
    };

  } //end constructor

  componentDidMount() {
    get('careerpath-detail', {careerpathid:this.props.match.params.careerId}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })
      } else {
        notify.show('Something Went Wrong while loading data!', 'error');
      }
    });

  }
  paynenroll(e) {

e.preventDefault();
    post('cporderrequest', { careerpath_id: this.props.match.params.careerId }).then((result) => {
      let responseJson = result;
      if (responseJson.oid && responseJson.error === false) {
        // let  data = Object.assign({}, this.state.data);    //creating copy of object
        // data['enrolled'] = true;                        //updating value
        
        window.location.href='https://www.veriipro.com/trainingws/public/processorder?q='+responseJson.oid
        //this.setState({ checkout: responseJson.oid });
        //this.setState({ redirect: true });
        // notify.show('Enrolled Successfully!!','success');

      } else {
        notify.show(responseJson.msg, 'error');
      }
    });

  }

  render() {
 const {career_path_item,page_detail,trainer} = this.state.data;
    return (

      <>
        <Header />
        
<div className="main-body">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="leftside1" className="lsidee left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span></a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span></a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span></a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="rightside" className="rside">

<Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Learning Path'}]} />
	<div className="home_top_banner CP_htb" 
  style={{backgroundImage: 'url('+(page_detail.banner_image?page_detail.image_url+page_detail.banner_image:'/images/learning_banner.png') +')'} }>
  <div className="back_ground_imge_overlay CP_bgo">
    <p className="box_conten_banner_text">
   {page_detail.page_title}
  </p>
  <p className="CP_course_overview">{page_detail.page_subtitle}</p>
{/* 
<p className="st_my_r_mnt CP_btns">
<a href="" id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" data-ga-action="click" data-ga-label="free-trial" data-qa="topcourses_individual_cta">Overview</a></p> */}
<p className="st_my_r_mnt CP_btns">
<a href="#" id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" data-ga-action="click" data-ga-label="free-trial" data-qa="topcourses_individual_cta" onClick={(e)=>this.paynenroll(e)}>Purchase All At Once's $ {page_detail.price}</a></p>

<div className="CP_video_size">
  <p>{page_detail.duration}</p>
</div>

  </div>
</div>

<div className="LPC">
  
<div className="LPC_layer">
  
  <div className="Learn thrdiv">
    <p className="LPC_Title">{page_detail.label_text1}</p>
    <p className="LPC_content">{page_detail.value_text1}</p>
  </div>
  <div className="Pratice thrdiv">
    <p className="LPC_Title">{page_detail.label_text2}</p>
    <p className="LPC_content">{page_detail.value_text2}</p>
  </div>
  <div className="Create thrdiv">
    <p className="LPC_Title">{page_detail.label_text3}</p>
    <p className="LPC_content">{page_detail.value_text3}</p>    
  </div>

</div>

</div>


<div className="ATV">
  
  <div className="ATV_list">
    <div className="ATV_space">
    <div className="ATV_list_item"> 
{
  career_path_item.map((item,key)=>{
return <div className="dec_course_info">
    <Link to={item.slug?"/course/"+item.slug:"/course/"+item.series_id}>
    <div className="container">
      <div className="col-md-3 col-lg-3 ATV_vid">
        <img className="ATV_img" src={item.series_image? page_detail.series_image_url+item.series_image:"/images/cloud.jpg"} alt={item.series_title} />         
      </div>
      <div className="col-md-9 col-lg-9 ATV_vid">
        <div className="dec_back_info">
        <div className="col-md-9 col-lg-9">
        <div className="al_crs_title">
          <h3>{item.series_title}</h3>
        </div>
  <div className="fc_rating">
{item.featured?   <span className="course-badge--course-badge--1AN7r"><span data-purpose="badge" className="on-course-card badge badge-accented yellow"><span className="badge-text">Bestseller</span></span></span> :null}
  <span className="">{item.series_lecture_count}  lectures</span>
  <span className="">{item.series_duration+' '+item.series_duration_type}</span>
  <span className="">{(item.series_level=='1')? 'Beginner Level' : (item.series_level=='2')? 'Intermediate Level' : (item.series_level=='3')? 'Expert Level' : 'All Level' }</span>  
  </div>
  <div className="dec_detail_info">{item.series_sub_title}</div>

        </div>
        <div className="col-md-3 col-lg-3">
      <span className="dec_rate dec_all">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>   
      {/* <s className="dec_all"> <span className="dec_real dec_all">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span></s> */}
        <div className="dec_star dec_all">
        <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating}/>
      <span className="dec_rate_me">{item.series_rating}</span>
      </div>
        <span className="dec_total_rateing dec_all">({parseInt(item.series_ratedby)}  rating)</span>
        </div>
      </div>
    </div>
    </div>
  </Link>
{key!=career_path_item.length-1?
  <div className="vertical_dotted_line">
    <div className="assa">
      <img src="/images/learningpathseries.png" className=""/>
      <span className="learning_count">{key+1}</span>
    </div>
  </div>
:null}
  </div>
  })
}

    </div>

<div className="ATV_teacher">
  
<div className="ATV_trainer">

<div className="ATV_trainer_name">
  
  <p>Experts</p>

</div>

<div className="container">
    <div className="row">

     
        <div className="col-md-12" id="learning_path_">
            <div id="carousel-pager" className="carousel slide " data-ride="carousel" data-interval="100000000">
                {/* <!-- Carousel items --> */}
                <div className="carousel-inner vertical">
                   {
                   trainer.map((item,key)=>{
                   return <div className={key?"item":"active item"}>
                        
                        <Link to={'/instructor/'+(item.slug?item.slug:item.user_id)}>
                        <figure>
                        <img src={item.image? page_detail.user_image_url+item.image:"/images/user-dummy.png"} className="img-responsive" data-target="#carousel-main" data-slide-to="0" />
                        <figcaption>{item.first_name+' '+item.last_name}</figcaption> 
                        </figure>                     </Link>
                    </div>
                  })  
                  }
                    
                    
                </div>
                <div></div>
                {/* <!-- Controls --> */}
                <a className="left carousel-control" href="#carousel-pager" role="button" data-slide="prev">
                    <span className="fa fa-angle-up" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="right carousel-control" href="#carousel-pager" role="button" data-slide="next">
                    <span className="fa fa-angle-down" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>
</div>

</div>

  </div>

  </div>

</div>

      

        <Footer />

</div>


</div>
</div>
      </>




    );
  }
}

export default LearningPath;
