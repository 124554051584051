import React, { Component } from 'react';
import { Link,Redirect} from "react-router-dom";
import { put, DeviceId, DeviceType } from './../../services/Apicall';
import {notify} from 'react-notify-toast';
import $ from 'jquery';
import Header from './../../common/Header';

import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';


//const $ = window.$;

class Chngpwd extends React.Component {

  constructor(){
    super();
		this.state = {
      formdata:{
        oldpassword:'',
        newpassword:'',
        confirmpassword:'',
      }
    };

    this.onChange = this.onChange.bind(this);
    this.changepassword = this.changepassword.bind(this);
  }

  componentDidMount(){

 
  }

  changepassword(e) {
    e.preventDefault();
    if(this.validate() && this.state.formdata.oldpassword && this.state.formdata.newpassword  ){
        put('changepassword',this.state.formdata).then((result) => {
         let responseJson = result;
         if( responseJson.error==false){
            notify.show('Password changed successfully! '+responseJson.msg,'success');
         }else  if(responseJson.error==true ){
            notify.show('Something went wrong! '+responseJson.msg,'error');

         }

        });
    }else{
        notify.show('Please Enter All Field','warning')
    }

 }

    onChange(e){
        let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
        formdata[e.target.name] = e.target.value;                        //updating value
        this.setState({formdata});
    }
  
    
    
    validate=()=>{
      // var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // var regexPhone = /^\d{10}$/;
      // var regexName = /^(?=.{3,30}$)[a-zA-Z ]*$/;
      var regexPass = /^(?=.{8,20}$)(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*/;
  
      // if(!regexName.test(this.state.name)){
      //      notify.show("Enter a Valid Name");return false;
      // }else if(!regexEmail.test(String(this.state.email).toLowerCase())){
      //      notify.show("Enter a Valid Email");return false;
      // }else if(!regexPhone.test(String(this.state.phone).toLowerCase())){
      //      notify.show("Enter a Valid 10 Digit Phone No.");return false;
      // }else 
      if(!regexPass.test(this.state.formdata.newpassword)){
           notify.show("Enter  8 Character Password Containing atleast 1 Alphabet and 1 Numeric","error");return false;
      }else if(this.state.formdata.newpassword!==this.state.formdata.confirmpassword){
           notify.show("Confirm Password Missmatch",'error');return false;
      }
      // else if(this.state.agreement==='N'){
      //      notify.show("Accept Terms & Conditions");return false;
      // }
      else{
          return true;
      }
  }

render() {
  if (this.state.redirectToReferrer) {
    return (<Redirect to={'/'}/>)
    }
   
    if(sessionStorage.getItem('userData')){
    return (<Redirect to={'/'}/>)
    }  
  return (
    <div> 

<Header />

<div className="main-body course">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
  <div className="side-bar">
    <ul className="left-side-bar">
      <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
      <li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
      <li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
      <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!--      <li className="fivth"></li> --> */}
    </ul>
  </div>

</div>
<div id="leftside1" className="lsidee left_bar">
  <div className="side-bar">
    <ul className="left-side-bar">
      <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span> </a> </li>
      <li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span></a> </li>
      <li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span></a> </li>
      <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span></a> </li>
{/* <!--      <li className="fivth"></li> --> */}
    </ul>
  </div>

</div>
<div id="rightside" className="rside">

<Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Change Password'} ]} />
<div className="body_main">

<div className="reset_password_section">
  
  <div className="cha_nge_pass_word">

  <h3>Change Password</h3>

    <form action="#" onSubmit={this.changepassword}>

      <div className="old_pas">
        <label>Old Password</label>
        <input type="password" minLength='8' maxLength='30'  name="oldpassword" placeholder="Current Password"  onChange={this.onChange}  required className="single-input-accent"/>
      </div>

      <div className="new_pas">
        <label>New Password</label>
        <input type="password" minLength='8' maxLength='30' name="newpassword" placeholder="New Password"  onChange={this.onChange}  required className="single-input-accent"/>
      </div>

      <div className="retype_pas">
        <label>Retype Password</label>
        <input type="password" minLength='8' maxLength='30'  name="confirmpassword" placeholder="Confirm New Password"  onChange={this.onChange} required className="single-input-secondary"/>
      </div>

    <button> Change Password </button> 

    </form>

  </div>

  <div className="pass_req">
    <div className="pass_must_have">
    <h4>Password Must Meet The Following Requirments: </h4>

    <ul>

      <li>At Least One Cpaital Letter</li>
      <li>At Least One Small Letter</li>
      <li>At Least One Number</li>
      <li>At Least Eight(8) Letter</li>

    </ul>
</div>
  </div>

</div>


</div>
{/* <!-- FOOTER --> */}

<Footer />

</div>
</div>

</div>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
 <script src="js/jquery.js"></script>                
<script src="js/bootstrap.min.js"></script>  
<script type="text/javascript">
</script>

</div>



  );
}

}
export default Chngpwd;
