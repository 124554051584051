import React from 'react';
import { Link,Redirect,withRouter } from 'react-router-dom'
import Rating from 'react-rating';
import { put } from '../services/Apicall';
import { notify } from 'react-notify-toast';

class CourseCardHorizontal extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }
    saveCourse  = (series_id,action) =>{
      if(action=='login')
        this.props.history.push('/login')
      else
      put('saveseries', {action:action,series_id:series_id} ).then((result) => {
        let responseJson = result;
        if (responseJson.error === false) {
            this.props.saveCourse(series_id,action)
        } else {
          notify.show('Something Went Wrong!', 'error');
        }
      });
    }

    render() {
        var item = this.props.item;
        return (

            <div className="dec_course_info can_crs">
            {/* <Link className="column show" href={"/course/" + item.series_id}> */}
            <div className="container">
              <div className="col-md-3 col-lg-3 all_course_item">
              <Link className="column show" to={item.slug?"/course/" + item.slug:"/course/" + item.series_id}> <img src={(item.series_image) ? item.image_url + item.series_image : "/images/cloud.jpg"}  alt={item.series_title} />         </Link>
              </div>
              <div className="col-md-9 col-lg-9">
                <div className="dec_back_info">
                <div className="col-md-8 col-lg-8">
                <div className="al_crs_title">
                <Link className="column show" to={item.slug?"/course/" + item.slug:"/course/" + item.series_id}> <h3>{item.series_title}</h3></Link>
                </div>
          <div className="fc_rating">
          {item.featured?<span className="course-badge--course-badge--1AN7r"><span data-purpose="badge" className="on-course-card badge badge-accented yellow"><span className="badge-text">Bestseller</span></span></span>:null}
          <span className="">{item.series_lecture_count} lectures</span>
          {(item.series_duration)?<span className="">{item.series_duration} {item.series_duration_type}</span>:null }
          <span className="">{(item.series_level=='1')? 'Beginner Level' : (item.series_level=='2')? 'Intermediate Level' : (item.series_level=='3')? 'Expert Level' : 'All Level' }</span>  
          </div>
          <div className="dec_detail_info">{item.series_sub_title}</div>
        
                </div>
                <div className="col-md-4 col-lg-4">
              <span className="dec_rate dec_all">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>   
              {/* <s className="dec_all">{(item.series_type=='PAID')? <span className="dec_real dec_all">$ {item.series_price}</span> : null } </s> */}
                <div className="dec_star dec_all">
                {/* <span>
                <i  className="fa"></i>
                <i  className="fa"></i>
                <i  className="fa"></i>
                <i  className="fa"></i>
                <i  className="fa"></i>
              </span> */}
               <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating}/>
              {/* <span className="dec_rate_me">{item.series_rating}</span> */}
              </div>
                <span className="dec_total_rateing dec_all">({item.series_ratedby} Rating)</span>
                { 
                  (localStorage.ud_token)?
                  (item.saved)?
                <span className="fa fa-heart fa-2x pull-right"  title='Remove this Course from wishlist' onClick={()=>this.saveCourse(item.series_id,'remove')}></span>
                :
                <span className="fa fa-heart-o fa-2x pull-right" title='Save this Course' onClick={()=>this.saveCourse(item.series_id,'add')}></span>
                :

                <span className="fa fa-heart-o fa-2x pull-right" title='Login to Save this Course' onClick={()=>this.saveCourse(item.series_id,'login')}></span>
                }
                </div>
              </div>
            </div>
            </div>
          {/* </Link> */}
          </div>
        

        );
    }
}

export default withRouter(CourseCardHorizontal);
