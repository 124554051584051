import React, { Component } from 'react';
import {Link} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';
import CourseCardHorizontal from './../../common/CourseCardHorizontal';
import FilterWidget from './../../common/FilterWidget';
import { get, post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

//const $ = window.$;

class BrowseCourses extends React.Component {

  constructor(){
    super();
    this.state={
      courselist:[],
      refineparam:{
        learningfor :'',
        limit: 10,
        offset:0,
        sortby:'rate',
        level:[],
        language:[],
        pricingtype:[],
        rating:'',
        duration:[],

      },
      browseoption:{topic:[],subject:[],technology:[],role:[]},
      resultcount:0,
      appliedFilter:[],
    }
  }

  refineAction = (e,type) =>{

    var refineparam = {...this.state.refineparam}
    if(type=='text')
    refineparam[e.target.name] = e.target.value;
    else if(type=='array')
    refineparam[e.target.name] = e.target.value;


    this.setState({refineparam})
    this.getCourse(refineparam);
  }



  processRadioButton = (e,type, id) => {
    var refineparam = {...this.state.refineparam}
    
    var cbarray = document.getElementsByName(type);
    if(e.target.checked==true){
      refineparam[type] = id;
      for(var i = 0; i < cbarray.length; i++){
        if(cbarray[i]!=e.target)
            cbarray[i].checked = false;
            
      }
    }else{
      refineparam[type] = '';
    }

    this.setState({refineparam})
    this.getCourse(refineparam);
  }

  processCheckbox = (type, id) => {
    var refineparam = {...this.state.refineparam}
     var target = refineparam[type];
     
     if (target.includes(id)) {
         var index = target.indexOf(id);
         if (index !== -1)
             target.splice(index, 1);
         //this.setState({ ['cb_' + type + id]: true })
     } else {
         target.push(id)
        //this.setState({ ['cb_' + type + id]: false })
     }
     refineparam[type] = target;
     this.setState({refineparam})
     this.getCourse(refineparam);
 }

  saveCourse =  (series_id,action) => {

    const { courselist } = this.state;

    let index = courselist.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      courselist[index]['saved'] = 0;
    }else if (action == 'add'){
      courselist[index]['saved'] = 1;
    }
    this.setState({courselist});

    }

  navigateToCourse(type,id){
    var refineparam= {
      learningfor :this.props.match.params.categoryId,
      limit: 10,
      offset:0,
      sortby:'rate',
      level:[],
      language:[],
      pricingtype:[],
      rating:'',
      duration:[]

    }
    refineparam[type]=id;
    this.setState({refineparam})
    this.getCourse(refineparam);

    
  }


  componentDidMount(){
    var refineparam = {...this.state.refineparam}
    refineparam['learningfor'] = this.props.match.params.categoryId;
  this.getCourse(refineparam);
  }


  componentDidUpdate(prevProps) {
    
    if (this.props.location !== prevProps.location) {
 
      var refineparam = {...this.state.refineparam}
      refineparam['learningfor'] = this.props.match.params.categoryId;
      this.setState({refineparam})
      this.getCourse(refineparam);
    }
  }
  
  getCourse = (refineparam)=>{
    if(refineparam===undefined)
    refineparam =this.state.refineparam;
    
    get('getseries', refineparam ).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ courselist: responseJson.data, appliedFilter: responseJson.appliedFilter  })
        this.setState({ resultcount: responseJson.resultcount })
        this.setState({ browseoption: responseJson.browseoption })
        
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }


  offsetChagne = (event,offset)=>{
    event.preventDefault();
     var refineparam = { ...this.state.refineparam }
     refineparam['offset'] = offset;
     this.setState({ refineparam })
     this.getCourse(refineparam);
     window.scrollTo(0, 0)
   }

render() {

const { refineparam, resultcount } = this.state;
let pagingItem = [];

if (refineparam.offset > 0)
  pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset - 10)}>{'Prev'}</a></li>);

for (let o = 0, p = 1; o <resultcount; o += 10, p++) {
  pagingItem.push(<li class={o=== refineparam.offset ?"page-item active":"page-item"}><a class="page-link" href={"#"+o} onClick={(e) => this.offsetChagne(e,o)}>{p}</a></li>);
}

if (refineparam.offset+10 < resultcount)
  pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset + 10)}>{'Next'}</a></li>);




  return (
    <div> 

<Header />

<div className="candiadte_course_section">
  
<div id="rightside" className="rside">
  
  <Breadcrumb path={[{link:'/',label:'Home'},{link:'/search',label:'All Course'},{link:'',label:'Technology'}, ]} />
<div className="body_main">


<div className="main">

<div className="div_title_banner">
  <p> <a href='#' className="mst_active">All Courses</a> {(localStorage.ud_token) ? <><strong>|</strong> <Link to="/mycourse/learning">My Courses</Link></>:null} </p>
<div className="learn_for_which">
  <div className="for_which_here">Learning For : </div>
  <div className={`courese_name  ${(this.props.match.params.categoryId===undefined)? 'clr_chng' : ''}`}><Link to="/browse-course"> All Courses</Link></div> | 
  <div className={`courese_name  ${(this.props.match.params.categoryId==1)? 'clr_chng' : ''}`}><Link to="/browse-course/1"> Bussiness</Link></div> |  
  <div className={`courese_name  ${(this.props.match.params.categoryId==2)? 'clr_chng' : ''}`}><Link to="/browse-course/2"> Development</Link></div>| 
  <div className={`courese_name  ${(this.props.match.params.categoryId==3)? 'clr_chng' : ''}`}><Link to="/browse-course/3"> Creative</Link></div>
</div>

</div>


{/* <!-- Portfolio Gallery Grid --> */}
<div className="row item">
{/* <!-- GRID --> */}

<div className="container">
  
<div className="filter_left_side">



{/* <!-------------------------------------------------------------------------------------> */}

  {
      (this.state.browseoption.topic.length)? 
      <div className="block_category_left">
        <h2>Topic</h2>
    
    <div className="sub_ject">

    {
      this.state.browseoption.topic.map( (item,index)=>{
       return  <div className="prog_ram">
           <div className="check" onClick={()=>{this.navigateToCourse('topic',item.id)} }>

            <span className="checkbox">
              <p className={refineparam.topic===item.id?'active':''}>{item.name} <span>({item.count})</span></p>
            </span>
          </div>
        </div>
      })
    }

      </div>

    </div> :<></>
    }
{/* <!-------------------------------------------------------------------------------------> */}
  

  {
      (this.state.browseoption.subject.length)? 
  <div className="block_category_left">
        <h2>Subject</h2>
    
    <div className="sub_ject">  
  {
  this.state.browseoption.subject.map( (item,index)=>{
   return   <div className="prog_ram">
      <div className="check"  onClick={()=>{this.navigateToCourse('subject',item.id)}} >

            <span className="checkbox">
            <p className={refineparam.subject===item.id?'active':''} >{item.name} <span>({item.count})</span></p>
            </span>
          </div>
        </div>
    })
  }
      </div>

    </div>:<></>
    }
{/* <!-------------------------------------------------------------------------------------> */}

{
  (this.state.browseoption.technology.length)? 
      <div className="block_category_left">
        <h2>Technology</h2>
    
    <div className="sub_ject">

{
  this.state.browseoption.technology.map( (item,index)=>{
   return   <div className="prog_ram">
      <div className="check"  onClick={()=>{this.navigateToCourse('technology',item.id)}} >

            <span className="checkbox">
            <p  className={refineparam.technology===item.id?'active':''}>{item.name} <span>({item.count})</span></p>
            </span>
          </div>
        </div>
    })
  }

      </div>

    </div>:<></>
    }
{/* <!-------------------------------------------------------------------------------------> */}

{
  (this.state.browseoption.role.length)? 
    <div className="block_category_left">
        <h2>Roles</h2>
    
    <div className="sub_ject">

    {
  this.state.browseoption.role.map( (item,index)=>{
   return   <div className="prog_ram">
      <div className="check"  onClick={()=>{this.navigateToCourse('role',item.id)}} >

            <span className="checkbox">
            <p  className={refineparam.role===item.id?'active':''}>{item.name} <span>({item.count})</span></p>
            </span>
          </div>
        </div>
    })
  }

      </div>

    </div>:<></>
    }


{/* <!------------------------------------------------------------------------------> */}


  </div>

  <div className="filter_right_side">


  <FilterWidget  appliedFilter={this.state.appliedFilter} refineparam={this.state.refineparam} refineaction={this.refineAction}  resultcount={this.state.resultcount} processCheckbox={this.processCheckbox} processRadioButton={this.processRadioButton} />



{
  this.state.courselist.map((item, key) => {
    return <CourseCardHorizontal   key={key} item={item}  saveCourse={this.saveCourse} />
    })
}
{
  ( this.state.courselist.length==0)? <h3 style={{textAlign:'center'}}>No Course Found!</h3>: null
}
{/* <!----==================-- */}

{
                        pagingItem.length ? <nav ariaLabel="Page navigation example">
                          <ul class="pagination">
                            {pagingItem.map((item) => {
                              return item;
                            })}
                          </ul>
                        </nav> : null
                      }


</div>
{/* <!-- all development course --> */}

  </div>
</div>

</div>



{/* <!-- END GRID --> */}
</div>


<Footer />

</div>

</div>


<script>
</script>


<script type="text/javascript">

</script>

</div>

 
  );
}

}
export default BrowseCourses;
