import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'


import { get, put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';


import ObjectInput from './ObjectiveInput';
import TargetsInput from './TargetInput';
import RequireInput from './RequirmentInput';


class ManageObjective extends React.Component {

    
	constructor(){
		super();
		
		this.state = {
			redirect: false,
			data:{},
			series_role:[],
				objectiveCount: 1,
				requirementCount: 1,
				targetCount: 1,
				formdata:{

				  series_id:'',
					target:[''],
					objective:[''],
					requirment:[''],
					series_role:[],

				},				
				series_id:'',
			
		};

		 this.addMoreObjective = this.addMoreObjective.bind(this);
		 this.addMoreRequirment = this.addMoreRequirment.bind(this);
		 this.addMoreTarget = this.addMoreTarget.bind(this);
		this.onChangeDropdown = this.onChangeDropdown.bind(this);
		// this.onSortItems = this.onSortItems.bind(this);
		 this.onChange = this.onChange.bind(this);
		 this.onChangeDropdown = this.onChangeDropdown.bind(this);
		 this.save = this.save.bind(this);
		

	} 


	addMoreObjective(){
		
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.objective= [...this.state.formdata.objective,''];                        //updating value
		this.setState({formdata});
	}

	addMoreRequirment(){
		
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.requirment= [...this.state.formdata.requirment,''];                        //updating value
		this.setState({formdata});
	}

	addMoreTarget(){
		//var newcount = this.state.targetCount + 1;
		//this.setState({targetCount: newcount })
	//	const content = '<div className="mt-10"><input type="text"  onClick={this.onchange} name="target_0" placeholder="eg: Target" onFocus="this.placeholder = \'\'" onBlur="this.placeholder = \'eg: Target\'" required className="single-input-accent" /></div>';
		//document.getElementById('targetwrapper').innerHTML += content;

		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.target= [...this.state.formdata.target,''];                        //updating value
		this.setState({formdata});

	}

	removeObjective(key){
		if(this.state.formdata.objective.length >1)
		{
		this.state.formdata.objective.splice(key,1)
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.objective= this.state.formdata.objective;                     //updating value
		this.setState({formdata});
		}else{
			alert('Can\'t Remove  Atleast One Objective is Mandatory!');
		}

	}

	removeRequirment(key){
		if(this.state.formdata.requirment.length >1)
		{
		this.state.formdata.requirment.splice(key,1)
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.requirment= this.state.formdata.requirment;                     //updating value
		this.setState({formdata});
		}else{
			alert('Can\'t Remove  Atleast One Requirment is Mandatory!');
		}

	}

	removeTarget(key){
		if(this.state.formdata.target.length >1)
		{
		this.state.formdata.target.splice(key,1)
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.target= this.state.formdata.target;                     //updating value
		this.setState({formdata});
		}else{
			alert('Can\'t Remove  Atleast One Target is Mandatory!');
		}

	}

	// onSortItems = (items) => {
	// 	this.setState({
	// 		items: items
	// 	});
	// }

	
  onChange(e,key){
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name][key]= e.target.value;  
		this.setState({ formdata });                      //updating value
		// this.setState((prevState) => {
		// 	return formdata;
		// });
		
  }

//   onChange2 = (e)=> {
// 	let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
// 	formdata[e.target.name] = e.target.value;                        //updating value
// 	this.setState({ formdata });
// }


onChangeDropdown(e) {

	let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
	formdata[e.target.name] =  this.getSelectedOptions(e)//.target.value;                        //updating value
	this.setState({ formdata });
}

getSelectedOptions(e) {
  var sel = e.target;
  var opts = [],
	opt;
  var len = sel.options.length;
  for (var i = 0; i < len; i++) {
	opt = sel.options[i];

	if (opt.selected) {
	  opts.push(opt.value);
	 
	}
  }
  return opts;
}




	save(e) {
		e.preventDefault();
		if(this.state.formdata.requirment.length > 0 && this.state.formdata.target.length > 0 && this.state.formdata.objective.length > 0){
			put('addmva',this.state.formdata).then((result) => {
			 let responseJson = result;
			 if( responseJson.error==false){
				notify.show(responseJson.msg,'success');
				this.setState({redirect: true});
			 }else  if(responseJson.error==true ){
				notify.show(responseJson.msg,'error');

			 }

			});
		}else{alert('Validation')}

	 }




	componentDidMount(){
		let  formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata.series_id= this.props.match.params.courseId;                     //updating value
		this.setState({formdata});


		 get('fetchmva',{series_id:this.props.match.params.courseId}).then((result) => {
		 	let responseJson = result;
		 	if(responseJson.data && responseJson.error==false){
		 		this.setState({formdata: responseJson.data })
			

		 	}else {
		 	 notify.show('Something Went Wrong!','error');
		 	}
		 });

		 get('getmaster', { type: 'role' }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ series_role: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});


	

	
	}



    render() {



		if (this.state.redirect) {
			let courseId=this.props.match.params.courseId;
			return <Redirect to={'/instructorportal/managecurriculum/'+courseId} />
			}
			
	
		var ObjectiveInput=[];

		// var SortableItem = sortable(ObjectInput);
		// for(var i=0;i<this.state.objectiveCount;i++){
		// 		// push the component to elements!
		// 		// ObjectiveInput.push(
		// 		// <SortableItem key={i} onSortItems={this.onSortItems} items={items} sortId={i}>
		// 		// 		{<ObjectInput key={ i } cname='objectinput' />}
		// 		// </SortableItem>onSortItems
				 
		// 		//  );
		// 		ObjectiveInput.push(<SortableItem key={ i } datakey={ i }  action={this.onChange}   onSortItems={this.onSortItems}  sortId={i}  />);
		// }

		// var RequirmentInput=[];
		// for(var i=0;i<this.state.requirementCount;i++){
		// 		 // push the component to elements!
		// 		 RequirmentInput.push(<RequireInput key={ i } datakey={ i } action={this.onChange} />);
		// }
		
		// var TargetInput=[];
		// for(var i=0;i<this.state.targetCount;i++){
		// 		 // push the component to elements!
		// 		 TargetInput.push(<TargetsInput key={ i } datakey={ i }  action={this.onChange}  />);
		// }



        return (
            <>
            <Header trainerPage={true} /> 
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />

    <div className="main-body manageobject">
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
    <div className="btac ttl_tg_all">&#8735;</div>
  <p className="aabtx ttl_tg_all"> <Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>
  <p className="crs_name ttl_tg_all">{this.state.data.series_title}</p>
  {/* <p className="crs_sts ttl_tg_all">inactive</p> */}
  </div>

  <div className="topic_exit">
    <Link to="/instructorportal/tips">Tips <i className="fa fa-info-circle"></i></Link>
  </div>
</div>
<TrainerSidebar courseId={this.props.match.params.courseId } />
<div className="dnc_right_bar">


<div id="Target_Individuals" className="tabcontent">

<div className="design_new_course_form">
  

<form className="form-horizontal" action="" method="post"  id="contact_form"   onSubmit={this.save} >

  <div className="container">


<fieldset>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Take away from this course</label>  
  <div className="col-md-12 inputGroupContainer">

  {	
			this.state.formdata.objective.map((value,key)=>{

  	return <div className="input-group">

                
  <input   type="text" name="objective" maxLength='180'   onChange={(e)=>this.onChange(e,key)}  value={value}  placeholder="ex: Basic of Web development*" className="form-control"  required/>
  <div className="on_hover_show">
    <span className="dnc_del fa"  onClick={()=>this.removeObjective(key)}  title="Remove">&#xf014;</span>
    <span className="dnc_swap">&#9776;</span>
  </div>
    </div>

})
}

    <div  onClick={this.addMoreObjective} ><span className="add_sym">+</span><span className="aaans"> Add an answer</span></div>
  </div>
</div>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course prerequisits</label>  
  <div className="col-md-12 inputGroupContainer">


  {	
			this.state.formdata.requirment.map((value,key)=>{

  	return <div className="input-group">

                
  <input   type="text" name="requirment" maxLength='180'   onChange={(e)=>this.onChange(e,key)}  value={value}  placeholder="ex: Knowledge of Database*" className="form-control"  required/>
  <div className="on_hover_show">
    <span className="dnc_del fa"   onClick={()=>this.removeRequirment(key,'R')}   title="Remove">&#xf014;</span>
    <span className="dnc_swap">&#9776;</span>
  </div>
    </div>

})
}

    <div onClick={this.addMoreRequirment} ><span className="add_sym">+</span><span className="aaans"> Add an answer</span></div>
  </div>
</div>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Who all will get benefit from this course</label>  
  <div className="col-md-12 inputGroupContainer">
  
  
  {	
			this.state.formdata.target.map((value,key)=>{

  	return <div className="input-group">

                
  <input   type="text" name="target" maxLength='180'   onChange={(e)=>this.onChange(e,key)}  value={value}  placeholder="ex: Basic of Web development*" className="form-control"  required/>
  <div className="on_hover_show">
    <span className="dnc_del fa"  onClick={()=>this.removeTarget(key)}  title="Remove">&#xf014;</span>
    <span className="dnc_swap">&#9776;</span>
  </div>
    </div>

})
}


    <div  onClick={this.addMoreTarget} ><span className="add_sym">+</span><span className="aaans"> Add an answer</span></div>
  </div>
</div>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course is designed for which role</label>  
  <div className="col-md-12 inputGroupContainer">
  <div className="input-group ">
	  
  <select name='series_role' value={this.state.formdata.series_role}    onChange={this.onChangeDropdown} multiple required className="form-control selectpicker multipleoptionseclector" >
      {
			this.state.series_role.map((item, key) => {
				return <option key={key} value={item.value}>{item.label}</option>;
			})
	}
    </select>

    </div>
  </div>
</div>

</fieldset>

</div>
<div className="continue_btn">
<button type="submit" className="btn btn-lg btn-primary">Save and continue</button>
</div>
</form>
    </div>
    {/* <!-- /.container --> */}
</div>


</div>


</div>

</div>
{/* <!-- END MAIN --> */}
</div>


  
    <Footer  NoNavigation={true} />
    </>
        );
    }
}

export default ManageObjective;