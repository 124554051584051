import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import $ from 'jquery';

import Header from './../../common/Header';
import Footer from './../../common/Footer';
import CourseCardVerticle from './../../common/CourseCardVertical';
import Slider from "react-slick";
import { get, post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import * as Constant from './../../services/Constant'

const $ = window.$;

class Index extends React.Component {

  constructor() {
    super();
    this.state = {
      loadingJobData:false,
      activeRole:'',
      data: { top_all: [], top_business: [], top_creative: [], top_technology: [] },
      homedata: {
        career_path: [],
        top_subject: [],
        top_job_role: []
      },
      job_data: {
        skills: [],
        joblist: []
      },
    }
  }

  componentDidMount() {

    /** for top course tabs */
    $('#myBtnContainer .btn').click(function () {
      $('#myBtnContainer .btn').each(() => {
        $('#myBtnContainer .btn').removeClass('active');

      })
      $(this).addClass('active');
      var target = $(this).data('target');
      if (target == 'all') {
        $('.column').removeClass('show');
        $('.column.' + target).addClass('show');
      } else {
        $('.column').removeClass('show');
        $('.column.' + target).addClass('show')
      }
    })
    /** end top course tab***/


    get('hometopseries', {}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });


    get('homedata', {}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        
        let activeRole= '';
        if(responseJson.data?.top_job_role?.[0]){
          activeRole=responseJson.data.top_job_role[0].role_name;
        }
        this.setState({ homedata: responseJson.data,job_data:responseJson.data.default_job_data, activeRole:activeRole })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });


    // $('.search-toggle').addClass('closed');

    // $('.search-toggle .search-icon').click(function(e) {
    //   if ($('.search-toggle').hasClass('closed')) {
    //     $('.search-toggle').removeClass('closed').addClass('opened');
    //     $('.search-toggle, .search-container').addClass('opened');
    //     $('#search-terms').focus();
    //   } else {
    //     $('.search-toggle').removeClass('opened').addClass('closed');
    //     $('.search-toggle, .search-container').removeClass('opened');
    //   }
    // });


    // $(".vpop").on('click', function (e) {
    //   e.preventDefault();
    //   $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    //   var srchref = '', autoplay = '', id = $(this).data('id');
    //   if ($(this).data('type') == 'vimeo') var srchref = "https://player.vimeo.com/video/";
    //   else if ($(this).data('type') == 'youtube') var srchref = "https://www.youtube.com/embed/";

    //   if ($(this).data('autoplay') == true) autoplay = '?autoplay=1';

    //   $("#video-popup-iframe").attr('src', srchref + id + autoplay);

    //   $("#video-popup-iframe").on('load', function () {
    //     $("#video-popup-container").show();
    //   });
    // });

    // $("#video-popup-close, #video-popup-overlay").on('click', function (e) {
    //   $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
    //   $("#video-popup-iframe").attr('src', '');
    // });

    /* 
    minified

    $(".vpop").on("click",function(o){o.preventDefault(),$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();var p="",e="",i=$(this).data("id");if("vimeo"==$(this).data("type"))var p="//player.vimeo.com/video/";else if("youtube"==$(this).data("type"))var p="https://www.youtube.com/embed/";1==$(this).data("autoplay")&&(e="?autoplay=1"),$("#video-popup-iframe").attr("src",p+i+e),$("#video-popup-iframe").on("load",function(){$("#video-popup-overlay, #video-popup-container").show()})}),$("#video-popup-close, #video-popup-overlay").on("click",function(o){$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").hide(),$("#video-popup-iframe").attr("src","")});
    */
  }

  vpopClose=()=>{
    $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
      $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
      $("#video-popup-iframe").attr('src', '');
    });
  }
  vpopOpen=()=>{
   $(".vpop").on('click', function (e) {
    $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    var srchref='',autoplay='',id=$(this).data('id');
    if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    else if($('#vpop').data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";

    if($('#vpop').data('autoplay') == true) autoplay = '?autoplay=1';

    $("#video-popup-iframe").attr('src', srchref+id+autoplay);

    $("#video-popup-iframe").on('load', function() {
      $("#video-popup-container").show();
    });
  });

  }

  loadJobData = (role_name,role_id)=>{

    if(!this.state.loadingJobData && this.state.activeRole!==role_name){
      this.setState({loadingJobData:true,activeRole:role_name});

      get('jobdata-by-role', {role_name,role_id}).then((result) => {
        let responseJson = result;
        if (responseJson.data && responseJson.error === false) {
          this.setState({ job_data: responseJson.data,loadingJobData:false })
        } else {
          this.setState({ loadingJobData:false })
          //notify.show('Something Went Wrong!', 'error');
        }
      });
      
    }

  }

  saveCourse =  (series_id,action) => {
    //var data = this.state.data
    const { top_all, top_business, top_creative, top_technology } = this.state.data;

    let index = top_all.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      top_all[index]['saved'] = 0;
    }else if (action == 'add'){
      top_all[index]['saved'] = 1;
    }

    index = top_business.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      top_business[index]['saved'] = 0;
    }else if (action == 'add'){
      top_business[index]['saved'] = 1;
    }

    index = top_creative.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      top_creative[index]['saved'] = 0;
    }else if (action == 'add'){
      top_creative[index]['saved'] = 1;
    }

    index = top_technology.findIndex(x => x.series_id === series_id);
    if(index!==-1)
    if (action == 'remove'){
      top_technology[index]['saved'] = 0;
    }else if (action == 'add'){
      top_technology[index]['saved'] = 1;
    }

    this.setState({data:{ top_all, top_business, top_creative, top_technology}})

  }

  render() {
    const { top_all, top_business, top_creative, top_technology } = this.state.data;
    const { top_subject, top_job_role, career_path, default_job_data } = this.state.homedata;
    const {job_data, loadingJobData,activeRole} = this.state;
  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // swipeToSlide: true,
    // centerMode: true,
    centerPadding: "15px",
    autoPlay: false,
    adaptiveHeight: true,
    responsive: [
    {
      breakpoint: 992,
      settings: {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: "15px",
    autoPlay: false,
    adaptiveHeight: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: "15px",
    autoPlay: false,
    adaptiveHeight: true,
      }
    },
  ]
  };

    return (
      <div>
        <Header />
        <div className="main-body">
          <div className="container-fluid">
            <div id="leftside" className="lside left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span><span className="side_txt">  Home </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span><span className="side_txt"> Trending </span> </a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span><span className="side_txt"> Subscription </span> </a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span><span className="side_txt"> Course </span> </a> </li>
                  {/* <!-- 			<li className="fivth"></li> -->  */}
                </ul>
              </div>

            </div>
            <div id="leftside1" className="lsidee left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span></a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span></a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span></a> </li>
                  {/* <!-- 			<li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="rightside" className="rside">

              <div className="home_top_banner">
                <div className="back_ground_imge_overlay">
                  <p className="box_conten_banner_text">
                    Upgrade Yourself With Continuous Learning
                  </p>

                  
                  
                  {((!localStorage.ud_token)) ?
                  <><p className="st_my_r_mnt"><Link to="/signup" id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" data-ga-action="click" data-ga-label="free-trial" data-qa="topcourses_individual_cta">Signup to Enroll Free Courses</Link></p>
                  
                    <p className="aavm"><Link to="/login">Already a VeriiPro E-Learning member? Sign in now.</Link></p></> : null
                  }
                </div>
              </div>

              <div className="body_main">
                <div className="div_title_top">
                  <p>Browse Our Top Courses</p>
                </div>
                <div className="main">
                  <div id="myBtnContainer">
                    <button className="btn active" data-target="all"> All Courses</button>
                    <button className="btn" data-target='business'> Business</button>
                    <button className="btn" data-target='technology'> Technology</button>
                    <button className="btn" data-target='creative'> Creative</button>
                  </div>

                  {/* <!-- <div className="div_title">
	<p>Trending Courses</p>
</div> --> */}

                  {/* <!-- Portfolio Gallery Grid --> */}
                  <div className="row item">
                    <ul id="content-slider" className="content-slider">
                      {
                        this.state.data.top_all.map((item, key) => {
                          // console.log(item)
                          return <li className={"column show all"} key={key}><CourseCardVerticle saveCourse={this.saveCourse}  vpopOpen={this.vpopOpen} item={item} /></li>
                        })
                      }
                      {
                        this.state.data.top_business.map((item, key) => {
                          // console.log(item)
                          return <li className={"column business"} key={key}><CourseCardVerticle saveCourse={this.saveCourse}  vpopOpen={this.vpopOpen}  item={item} /></li>
                        })
                      }
                      {
                        this.state.data.top_technology.map((item, key) => {
                          // console.log(item)
                          return <li className={"column technology"} key={key}><CourseCardVerticle saveCourse={this.saveCourse}  vpopOpen={this.vpopOpen}  item={item} /></li>
                        })
                      }
                      {
                        this.state.data.top_creative.map((item, key) => {
                          // console.log(item)
                          return <li className={"column creative"} key={key}><CourseCardVerticle saveCourse={this.saveCourse}  vpopOpen={this.vpopOpen}  item={item} /></li>
                        })
                      }
                      {/* <li className="column creative">
                        <div className="content">
                          <img src="/images/graphic.jpg" alt="Mountains" style={{ width: '100%' }} />

                          <div className="vpop" data-type="vimeo" data-id="17853140" data-autoplay="true">
                            <i className="fa video_po_pup"></i>
                          </div>

                         
                          <a href="course_detail.html">
                            <h4>The Complete Web Development BootCamp</h4>
                          </a>
                          <a href=""><p className="tutor">Martin Perhiniak</p></a>
                          <div className="rating">
                            <span className="st_ar">
                              <i className="fa">&#xf005;</i>
                              <i className="fa">&#xf005;</i>
                              <i className="fa">&#xf005;</i>
                              <i className="fa">&#xf005;</i>
                              <i className="fa">&#xf123;</i>
                              <div className="revie_w_left">
                                <div className="rev_iew_all_stars">

                                  <div className="rat_ing_review_block">
                                    <span className="rating_of_mine_here">
                                      4.2
                                    </span>
                                    <span>(450 Rating)</span>
                                    <div className="interstr">
                                      <i className="fa"></i>
                                      <i className="fa"></i>
                                      <i className="fa"></i>
                                      <i className="fa"></i>
                                      <i className="fa"></i>
                                    </div>
                                  </div>
                                  <div className="al_l_star_sh_ow">
                                    <div className="my_rating_he_re">
                                      <span className="ra_te_out_of">5 star</span>
                                      <span className="rate_prog_ress">
                                        <div className="progress">
                                          <div className="progress-bar excep" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '90%' }}>
                                            <span className="sr-only">90% Complete</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="my_rating_he_re">
                                      <span className="ra_te_out_of">4 star</span>
                                      <span className="rate_prog_ress">
                                        <div className="progress">
                                          <div className="progress-bar ab_av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '75%' }}>
                                            <span className="sr-only">75% Complete</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="my_rating_he_re">
                                      <span className="ra_te_out_of">3 star</span>
                                      <span className="rate_prog_ress">
                                        <div className="progress">
                                          <div className="progress-bar av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}>
                                            <span className="sr-only">60% Complete</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="my_rating_he_re">
                                      <span className="ra_te_out_of">2 star</span>
                                      <span className="rate_prog_ress">
                                        <div className="progress">
                                          <div className="progress-bar be_av" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '10%' }}>
                                            <span className="sr-only">10% Complete</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="my_rating_he_re">
                                      <span className="ra_te_out_of">1 star</span>
                                      <span className="rate_prog_ress">
                                        <div className="progress">
                                          <div className="progress-bar unsat" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: '30%' }}>
                                            <span className="sr-only">30% Complete</span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div className="c_al_review">
                                      <a href="">See All 456 coustomer Review</a>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </span>

                            <span className="rate">4.4</span>
                            <span className="total">(10,450)</span>
                          </div>
                          <div className="priceing">
                            <s> <span className="real">₹ 1034</span></s>
                            <span className="offer">₹ 432</span>
                          </div>
                        </div>
                      </li> */}

                    </ul>
                    {/* <!-- END GRID --> */}

                    <div className="col-xs-12 text-center cta">
                    {((!localStorage.ud_token)) ?
                      <Link to="/signup" id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" data-ga-action="click" data-ga-label="free-trial" data-qa="topcourses_individual_cta">Signup to Enroll Free Courses</Link>:
                      <Link to="/instructorportal" id="top-courses-signup" className="am-link btn btn-primary btn-action btn-sm redirect-links ga show_here" data-ga-action="click" data-ga-label="free-trial" data-qa="topcourses_individual_cta">Switch to Trainer</Link>}

                      <Link to="/browse-course" id="btn-developer" className="btn btn-complimentary browse-all-cta ga" data-ga-action="click" data-ga-label="subjects-developer" data-qa="link_subjects_developer">Browse All Courses</Link>
                      {/* <!-- <a href="/Business-training-tutorials/29-0.html" id="btn-business" className="ga btn btn-complimentary browse-all-cta business column" data-ga-action="click" data-ga-label="subjects-business" data-qa="link_subjects_business">Browse Business Courses</a>
                <a href="/Design-training-tutorials/40-0.html" id="btn-design" className="ga btn btn-complimentary browse-all-cta creative column" data-ga-action="click" data-ga-label="subjects-design" data-qa="link_subjects_design">Browse Design Courses</a> -->  */}
                    </div>


                  </div>
                  {/* <!-- CATEGORY --> */}
                  <div className="category">

                    <div className="div_title">
                      <p>Top Subjects</p>
                    </div>

                    <div className="cate_name">
                      <div className="container-fluid">
                        {
                          top_subject.map((item, key) => {
                            return <div key={key} className="col-lg-3 col-md-3 cat_name sfdev" style={{backgroundImage: 'url('+(item.image? item.image_url+item.image : "/images/sfdv.jpg")+')' }}>
                              <Link to={item.slug?'/subject/'+item.slug:'/subject/'+item.id}>
                                <span><img className="catimg" src={"/images/sd.png"} alt={item.name} /> </span>
                                <span className="catdetail">{item.name}</span>
                              </Link>
                            </div>
                          })
                        }
                        {/* <div className="col-lg-3 col-md-3 cat_name mobdev"><Link to="/subject/6"><span><img className="catimg" src="/images/stdy.png" alt="" /> </span><span className="catdetail"> Mobile Development</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name ithdk"><Link to="/subject/6"><span><img className="catimg" src="/images/mobile.png" alt="" /> </span><span className="catdetail">Software Testing</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name dtbse"><Link to="/subject/6"><span><img className="catimg" src="/images/itdesk.png" alt="" /> </span><span className="catdetail"> Database Management</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name dtsci"><Link to="/subject/6"><span><img className="catimg" src="/images/database.png" alt="" /> </span><span className="catdetail"> Data Science</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name artint"><Link to="/subject/6"><span><img className="catimg" src="/images/brif.png" alt="" /> </span><span className="catdetail"> Artificial Intelligence</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name maclrn"><Link to="/subject/6"><span><img className="catimg" src="/images/self.png" alt="" /> </span><span className="catdetail">Machine Learning</span></Link> </div>
                        <div className="col-lg-3 col-md-3 cat_name cldcom"><Link to="/subject/6"><span><img className="catimg" src="/images/beg.png" alt="" /> </span><span className="catdetail"> Cloud Computing</span></Link> </div> */}
                      </div>
                    </div>

                  </div>
                  {/* <!-- END CATEGORY --> */}


                  {/* <!-- BANNER --> */}

                  <div className="Banner">

                    <div className="container-fluid">
                      <div className="col-lg-4 col-md-4 ban_content"><span><img src="/images/arow.png" alt="" /> </span><span> <p>1000 Online Courses</p><p>explore a variety of fresh topics</p></span></div>
                      <div className="col-lg-4 col-md-4 ban_content"><span><img src="/images/msent.png" alt="" /> </span><span> <p>Expert instruction</p><p>find the right instructor for you</p></span></div>
                      <div className="col-lg-4 col-md-4 ban_content"><span><img src="/images/his.png" alt="" /> </span><span> <p>Lifetime Access</p><p>Learn on your schedule</p></span></div>
                    </div>

                  </div>

                  {/* <!-- END BANNER --> */}

                  {/* <!-- POPULAR TOPICs --> */}

                  <div className="popu_topic">

                    <div className="div_title_top">
                      <p>Upgrade to Improve your career Path</p>
                    </div>

                    <div className="container-fluid">
                      <ul>
                        {career_path.map((item,key)=>{
                        return <li>
                          <Link key={key} to={item.slug? 'learningpath/'+item.slug :'learningpath/'+item.id} >
                            <div className="content">
                            <img src={item.thumbnail_image?item.image_url+item.thumbnail_image:"/images/abcd.jpg"} alt={"Learning Path to "+item.title} style={{ width: '100%' }} />
                            <h4>{item.title}</h4>
                          </div>
                          </Link>
                        </li>
                        })
                        }

                     


                      </ul>

                    </div>


                  </div>

                  {/* <!-- POPULAR TOPICs --> */}


                  {/* find job by */}

                  {/* <!--filters--> */}
                  <div className="fjbfws">
                    <div className="container parentslider">

                      <div className="findjobbtlist">
                        <div className="fnjbbytil"><span>Jobs in Trend</span></div>
                        <div className="fnjbb__ytil"><span><a href={Constant._SITE_JOB_URL} target="_blank">View All Jobs</a></span></div>

                      </div>

                      <div className="row">
                        <div className="col-md-12">
    <Slider {...settings}>
                        {top_job_role.map((item,key)=>{
                            return <div key={key} className={key==0?"item active":"item"} onMouseEnter={()=>this.loadJobData(item.role_name,item.role_id)}>
                                <div className="col-lg-12 top_most_silder">
                                  <div className="single-fcat">
                                    <p className={activeRole==item.role_name? "tmslnm active":"tmslnm"}>{item.role_name}</p>
                                  </div>
                                </div>
                              </div>
                          })
                        }
                      </Slider>
                        </div>
                      </div>

                    </div>

                    <div className="childsilder_filter">
                      <div className="container">

                        <div className="left_side child_slider">
                          <p className="to_pTi_le">Skills Required For {activeRole}</p>
                          <div className="ski__lsset">
                            {/* <!-- box start --> */}
                            {!loadingJobData && job_data.skills.map((item,key)=>{
                            return <a href={Constant._SITE_JOB_URL+"jobs/"+encodeURIComponent((item.skill_name).replace(/\s+/g, '-').toLowerCase())+'-jobs'} target="_blank"><div key={key} className="boxcat">
                              <div className="single-fcat">
                                <p>{item.skill_name}</p>
                              </div>
                            </div></a>
                            })}

{loadingJobData? <div>Loading....</div>:null}
                            {/* <!--end box start --> */}

                          </div>

                          {/* <!-- first slide end --> */}


                        </div>
                        <div className="right_side child_slider">
                          <p className="to_pTi_le">Jobs For {activeRole}</p>
                          <div className="home-cat-companies subcategory-enter-done">

                            <div className="table-responsive">
                              <table summary="This table shows how to create responsive tables using Bootstrap's default functionality" className="table table-bordered table-hover">

                                <tbody>
                                  {!loadingJobData && job_data.joblist.map((item,key)=> {
                                  return<tr key={key}>
                                    <td> <a href={Constant._SITE_JOB_URL+'job/'+item.slug} target="_blank" >{item.job_title}</a></td>
                                    <td>{item.company_name}</td>
                                    <td>
                                    {item.experience_min}
                        {item.experience_min != '' &&
                          item.experience_max != ''
                          ? ' to '
                          : item.experience_min != '' &&
                            item.experience_max == ''
                            ? ' + '
                            : item.experience_min == '' &&
                              item.experience_max != ''
                              ? ' up to '
                              : ' 0 '}
                        {item.experience_max +
                          ' ' +
                          item.experience_type}{' '}

                                    </td>
                                    <td>{item.r_cityname+' '+item.r_statename}</td>
                                  </tr>
                                  })
                                  }
                                  
                                </tbody>
                              </table>
                              {loadingJobData? <div>Loading....</div>:null}
                              <a className="vw__ie__all__jb" href={Constant._SITE_JOB_URL+'jobs/'+encodeURIComponent((activeRole).replace(/\s+/g, '-').toLowerCase())+'-jobs'} target="_blank">View All</a>
                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>


    {/* <Slider {...settings}>
                        {top_job_role.map((item,key)=>{
                            return <div key={key} className={key==0?"item active":"item"} onMouseEnter={()=>this.loadJobData(item.role_name,item.role_id)}>
                                <div className="col-lg-12 top_most_silder">
                                  <div className="single-fcat">
                                    <p className="tmslnm active">{item.role_name}</p>
                                  </div>
                                </div>
                              </div>
                          })
                        }
                      </Slider> */}
                  {/* end of find job by */}


                  {/* <!-- END MAIN --> */}
                </div>

              </div>
              <Footer />

              <div id="video-popup-overlay" onClick={this.vpopClose}></div>
<div id="video-popup-container">
  <div id="video-popup-close"  onClick={this.vpopClose} className="fade">&#10006;</div>
  <div id="video-popup-iframe-container">
    <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</div>
            </div>


          </div>
        </div>



        <script src="js/jquery.js"></script>
        <script src="js/bootstrap.min.js"></script>

      </div>
    );
  }

}
export default Index;
