import { notify } from 'react-notify-toast';
import axios from 'axios';
let BaseURL;
if (process.env.NODE_ENV === 'production') {//} && 'serviceWorker' in navigator) {
  BaseURL = 'https://www.veriipro.com/trainingws/public/';
} else {
  BaseURL = 'http://learning.local/';
 //BaseURL = 'https://www.veriipro.com/trainingws/public/';
}



export function get(url, params, header=null) {

  let defaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'deviceid':  DeviceId(),
    'accesstoken':  AccessToken(),
    'devicetype':  DeviceType()
  };

  
  if(header===null)
     header = defaultHeader

  var esc = encodeURIComponent;
  var query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
    .join('&');
   url = BaseURL + url + '?' + query


  return axios.get(url, { headers: header })
      .then((result) => {
          var data = result.data;
          // console.log(data);
          if(checkIfTokenExpired(data))
          return {};//return new Promise((resolve, reject) => reject(new Error('401')) ); // reject('402');

          return data;
      })
      .catch(function (error) {
        let errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        let op = "";
        if (errorObject.message != undefined)
            op = errorObject.message;
        else
            op = getErrors(errorObject.result.errors);
            console.log(errorObject.message);
            if (errorObject.message == undefined)
            notify.show  (errorObject.response ? op : "No connection" );
            else
            notify.show  (errorObject.message,'error');
            return {};
        
    });
}

export function getElastic(url, header) {
  return axios.get("#" + url + "&from=0&size=50")
      .then((success) => { console.log(success.data.hits.hits); return success.data.hits.hits });
}

export function put(url, data, header=null) {

  let defaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'deviceid':  DeviceId(),
    'accesstoken':  AccessToken(),
    'devicetype':  DeviceType()
  };
  

  if(header===null)
     header = defaultHeader

  //data.gridParams = {};
  return axios.put(BaseURL + url, data, { headers: header })
      .then((result) => {
          var data = result.data;
          // console.log(data);
          if(checkIfTokenExpired(data))
          return {};//return new Promise((resolve, reject) => reject(new Error('401')) );  // reject('402');
          return data;
      })
      .catch(function (error) {
        let errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        let op = "";
        if (errorObject.message != undefined)
            op = errorObject.message;
        else
            op = getErrors(errorObject.result.errors);
            console.log(errorObject.message);
            if (errorObject.message == undefined)
            notify.show  (errorObject.response ? op : "No connection" );
            else
            notify.show  (errorObject.message,'error');
            return {};
        
    });
}

export function post(url, data, header=null) {

  let defaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'deviceid':  DeviceId(),
    'accesstoken':  AccessToken(),
    'devicetype':  DeviceType()
  };

  
    if(header===null)
       header = defaultHeader

  return  axios.post(BaseURL + url, data, { headers: header })
      .then((result) => {
          //console.log('Returned result');
          //console.log(result);
          var data = result.data;

          if(checkIfTokenExpired(data))
            return {};
          // reject(new Error('401')) ;  // reject('402');

          return data;
      })
      .catch(function (error) {
          let errorObject = JSON.parse(JSON.stringify(error));
          console.log(errorObject);
          let op = "";
          if (errorObject.message != undefined)
              op = errorObject.message;
          else
              op = getErrors(errorObject.result.errors);
          var errormsg='Server Error';
              if (errorObject.message == undefined)
              errormsg = errorObject.response ? op : "No connection" ;
              else
              errormsg = errorObject.message

              notify.show(errormsg,'error')
              return {};
          
      });

}


export function deleteData(url, data, header=null){

  let defaultHeader = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'deviceid':  DeviceId(),
    'accesstoken':  AccessToken(),
    'devicetype':  DeviceType()
  };
  

  if(header===null)
     header = defaultHeader

  var config = {
      headers: header,
      data: data
  };
  return axios.delete(BaseURL + url, config)
      .then((result) => {
          var data = result.data;
          // console.log(result);

          if(checkIfTokenExpired(data))
          return {};// return new Promise((resolve, reject) => reject(new Error('401')) );  // reject('402');
          return data;
      })
      .catch(function (error) {
        let errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject);
        let op = "";
        if (errorObject.message != undefined)
            op = errorObject.message;
        else
            op = getErrors(errorObject.result.errors);
        var errormsg='Server Error';
            if (errorObject.message == undefined)
            errormsg = errorObject.response ? op : "No connection" ;
            else
            errormsg = errorObject.message

            notify.show(errormsg,'error')
            return {};
        
    });
}


function getErrors(arr) {
  let op = ''
  Object.keys(arr).forEach((e) => { op = op + arr[e][0] + '\n' });
  return op;
}



















console.log(process.env.NODE_ENV)

// export function PostData(type, userData, method = 'POST') { //POST PUT DELETE 

//   return new Promise((resolve, reject) => {


//     fetch(BaseURL + type, {
//       method: method,
//       body: JSON.stringify(userData),
//       headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//         'deviceid': DeviceId(),
//         'accesstoken': AccessToken(),
//         'devicetype': DeviceType(),
//         //"HTTP_AUTHORIZATION": "11111",
//         // 'Access-Control-Allow-Origin':'http://localhost:3000',
//       }
//     })
//       .then((response) => {
//         const contentType = response.headers.get("content-type");
//         if (!response.ok) {
//           notify.show('Unable to Process Server Response ;(', 'error')
//           reject(response.status);
//         }
//         else if (contentType && contentType.indexOf("application/json") !== -1) {
//           return response.json()
//         } else {
//           notify.show('Unable to Process Server Response ;(', 'error')
//           reject(response.status);
//         }
//       })
//       .then((res) => {
//         //notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster','warning');
//         // notify.show(JSON.stringify(res),'error')

//         if (!("msg" in res) == 0) {
//           if (res.msg == 'Access Token Expired or Invalid') {
//             localStorage.removeItem('ud_fname');
//             localStorage.removeItem('ud_lname');
//             localStorage.removeItem('ud_mobile');
//             localStorage.removeItem('ud_email');
//             localStorage.removeItem('ud_tid');
//             localStorage.removeItem('ud_id');
//             localStorage.removeItem('ud_token');
//             notify.show('Session Expired Please Login', 'warning')
//             //this.props.history.push("/login");
//             window.location.reload();
//             reject('402');

//             // return (<Redirect to={'/'}/>)
//           }
//         }
//         return resolve(res);
//       })
//       .catch((error) => {
//         notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster', 'error');
//         // notify.show(error,'error')
//         reject(error);
//       });


//   });
// }

// export function GetData(type, params, method = 'GET') {

//   var esc = encodeURIComponent;
//   var query = Object.keys(params)
//     .map(k => esc(k) + '=' + esc(params[k]))
//     .join('&');
//   let url = BaseURL + type + '?' + query

//   return new Promise((resolve, reject) => {


//     fetch(url, {
//       method: 'GET',
//       headers: {
//         //"Content-Type": "application/json",
//         "Content-Type": "text/plain",
//         //Authorization: "",
//         'deviceid': DeviceId(),
//         'accesstoken': AccessToken(),
//         'devicetype': DeviceType(),

//       }
//     })

//       .then((response) => {
//         const contentType = response.headers.get("content-type");
//         if (!response.ok) {
//           notify.show('Unable to Process Server Response ;(', 'error')
//           reject(response.status);
//         }
//         else if (contentType && contentType.indexOf("application/json") !== -1) {
//           return response.json()
//         } else {
//           notify.show('Unable to Process Server Response ;(', 'error')
//           reject(response.status);
//         }
//       })
//       .then((res) => {
//         //notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster','warning');
//         // notify.show(JSON.stringify(res),'error')

//         if (!("msg" in res) == 0) {
//           if (res.msg == 'Access Token Expired or Invalid') {
//             localStorage.removeItem('ud_fname');
//             localStorage.removeItem('ud_lname');
//             localStorage.removeItem('ud_mobile');
//             localStorage.removeItem('ud_email');
//             localStorage.removeItem('ud_tid');
//             localStorage.removeItem('ud_id');
//             localStorage.removeItem('ud_token');
//             notify.show('Session Expired Please Login', 'warning')
//             //this.props.history.push("/login");
//             window.location.reload();
//             reject('402');

//             // return (<Redirect to={'/'}/>)
//           }
//         }
//         return resolve(res);
//       })
//       .catch((error) => {
//         reject(error);
//       });


//   });
// }





export function PostFiles(type, userData, method = 'POST') { //POST 
  var data = new FormData();
  for (var key in userData) {
    data.append(key, userData[key]);
  }



  return new Promise((resolve, reject) => {


    fetch(BaseURL + type, {
      method: method,
      body: data,// JSON.stringify(userData),			
      headers: {
        //'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        'deviceid': DeviceId(),
        'accesstoken': AccessToken(),
        'devicetype': DeviceType(),
        //"HTTP_AUTHORIZATION": "11111",
        // 'Access-Control-Allow-Origin':'http://localhost:3000',
      }
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!response.ok) {
          notify.show('Unable to Process Server Response ;(', 'error')
          reject(response.status);
        }
        else if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json()
        } else {
          notify.show('Unable to Process Server Response ;(', 'error')
          reject(response.status);
        }
      })
      .then((res) => {
        //notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster','warning');
        // notify.show(JSON.stringify(res),'error')

        if (!("msg" in res) == 0) {
          if (res.msg == 'Access Token Expired or Invalid') {
            localStorage.removeItem('ud_fname');
            localStorage.removeItem('ud_lname');
            localStorage.removeItem('ud_mobile');
            localStorage.removeItem('ud_email');
            localStorage.removeItem('ud_tid');
            localStorage.removeItem('ud_id');
            localStorage.removeItem('ud_token');
            notify.show('Session Expired Please Login', 'warning')
            //this.props.history.push("/login");
            window.location.reload();
            reject('402');

            // return (<Redirect to={'/'}/>)
          }
        }
        return resolve(res);
      })
      .catch((error) => {
        notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster', 'error');
        // notify.show(error,'error')
        reject(error);
      });


  });
}


export function VimeoCreate(videofile, videoTitle, videoDescription) {


  let vimeoCreateUrl = 'https://api.vimeo.com/me/videos';
  let access_token = 'f774725f543ad4eee9c24e663360c225';
  return new Promise((resolve, reject) => {
    var fileSize = videofile.size;

    // const reader = new FileReader();
    // reader.onload = r => {
    //   this.setState({ videobinaryData: r.target.result, fileSize });
    // };
    // reader.readAsArrayBuffer(videofile);

    fetch(vimeoCreateUrl, {
      method: 'POST',
      // body: '{ "upload": { "approach": "tus", "size": '+fileSize+' } }',
      body: '{"upload" : { "approach": "tus", "size": ' + fileSize + '},"name": "' + videoTitle + '","description" : "' + videoDescription + '","privacy": { "view": "anybody","embed":"public" }}',
      //  body:  '{"upload" : { "approach": "tus", "size": '+fileSize+'},"name": "'+videoTitle+'","description" : "'+videoDescription+'","privacy": { "view": "anybody","download":"false","embed":"public" }}',			
      headers: {
        'Accept': 'application/vnd.vimeo.*+json;version=3.4',
        'Content-Type': 'application/json',
        "Authorization": "bearer " + access_token,
      }
    })
      .then((response) => {
        // const contentType = response.headers.get("content-type");
        // if (!response.ok) {
        //   notify.show('Unable to Process Server Response ;(', 'error')

        //   reject(response.status);
        // }
        // else if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json()
        // } else {
        //   notify.show('Unable to Process Server Response ;(', 'error')
        //   reject(response.status);
        // }
      })
      .then((res) => {
        return resolve(res);
      })
      .catch((error) => {
        notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster', 'error');
        reject(error);
      });


  });

}



export function VimeoUpload(videofile, upload_link, offset = 0) {


  let access_token = 'f774725f543ad4eee9c24e663360c225';
  const reader = new FileReader();
  reader.onload = r => {
    let videobinaryData = r.target.result;
    // this.setState({ videobinaryData: r.target.result });
  };
  reader.readAsArrayBuffer(videofile);

  return new Promise((resolve, reject) => {

    fetch(upload_link, {
      method: 'PATCH',
      // body: {"upload": {"approach": "tus","size": fileSize}},			
      headers: {
        'Accept': 'application/vnd.vimeo.*+json;version=3.4',
        'Content-Type': 'application/offset+octet-stream',
        //  "Authorization": "bearer "+access_token,
        "Tus-Resumable": "1.0.0",
        "Upload-Offset": 0,
      },
      data: videofile,// videobinaryData
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!response.ok) {
          notify.show('Unable to Process Server Response ;(', 'error')
          reject(response.status);
        }
        else if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json()
        } else {
          notify.show('Unable to Process Server Response ;(', 'error')
          reject(response.status);
        }
      })
      .then((res) => {
        return resolve(res);
      })
      .catch((error) => {
        notify.show('Something Went Wrong while Processing your Request! Please verify your Request and Try Again or Contact Webmaster', 'error');
        reject(error);
      });


  });



}




export function DeviceId() {
  var deviceid = localStorage.getItem('deviceId');
  if (deviceid === '' || deviceid == NaN || deviceid === null) {

    deviceid = Math.floor(new Date().valueOf()) + '_' + Math.floor(Math.random() * 90000) + '_' + Math.floor(new Date().valueOf() * Math.random());
    localStorage.setItem('deviceId', deviceid);
  }
  return deviceid;
}

export function DeviceType() {
  return 'M';
}

export function AccessToken() {

  return localStorage.getItem('ud_token');
}

export function BaseUrl() {

  return BaseURL;
}

function checkIfTokenExpired(res){
    if (!("msg" in res) == 0) {
      if (res.msg == 'Access Token Expired or Invalid') {
        localStorage.removeItem('ud_fname');
        localStorage.removeItem('ud_lname');
        localStorage.removeItem('ud_mobile');
        localStorage.removeItem('ud_email');
        localStorage.removeItem('ud_tid');
        localStorage.removeItem('ud_id');
        localStorage.removeItem('ud_token');
        notify.show('Session Expired Please Login', 'warning')
        //this.props.history.push("/login");
        window.location.reload();
        return true;

        // return (<Redirect to={'/'}/>)
      }
    }
}