import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'


import { get, post } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';


class TrainerCourse extends React.Component {

    constructor() {
        super();
        this.state = {
          serieslist:[],
          resultcount:0,
          refineparam:{
            offset:0,
            type:'',
            sort:'latest'
          }
        }
    }

    componentDidMount(){
this.getCourse(this.state.refineparam)
    
  
    }
    getCourse=(refineparam)=>{

      if (refineparam === undefined)
      refineparam = this.state.refineparam;

      get('serieslist',refineparam).then((result) => {
        let responseJson = result;
        if(responseJson.data && responseJson.error==false){
          this.setState({serieslist: responseJson.data,resultcount:responseJson.resultcount })
        }else {
         notify.show('Something Went Wrong!','error');
        }
       });

    }
    
    sortData=(e)=>{
      //event.preventDefault();
      
      var refineparam = { ...this.state.refineparam }
      refineparam['sort'] = e.target.value;
      this.setState({ refineparam });
      this.getCourse(refineparam);
    }
    filterData=(event,offset)=>{
      event.preventDefault();
      var refineparam = { ...this.state.refineparam }
      refineparam['type'] = offset;
      this.setState({ refineparam });
      this.getCourse(refineparam);
    }
    offsetChagne = (event,offset)=>{
      event.preventDefault();
       var refineparam = { ...this.state.refineparam }
       refineparam['offset'] = offset;
       this.setState({ refineparam })
       this.getCourse(refineparam);
       window.scrollTo(0, 0)
     }


    render() {

      const { refineparam, resultcount } = this.state;
      let pagingItem = [];
  
      if (refineparam.offset > 0)
        pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset - 5)}>{'Prev'}</a></li>);
  
      for (let o = 0, p = 1; o <resultcount; o += 5, p++) {
        pagingItem.push(<li class={o=== refineparam.offset ?"page-item active":"page-item"}><a class="page-link" href={"#"+o} onClick={(e) => this.offsetChagne(e,o)}>{p}</a></li>);
      }
  
      if (refineparam.offset+5 < resultcount)
        pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset + 5)}>{'Next'}</a></li>);
  

        
        return (
            <>
            <Header trainerPage={true} /> 

            <div className="trainer_page_view">
  
  <div className="side_bar_trainer"></div>
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />
  <div className="main_trainer_page">
    
  
    
  <div className="sticky_float_filter">
  
  <div className="trainer_topic">
    
  <p>Courses</p>
  
  </div>
  
  <div className="search_sort_add_course">
    
  
  
  <div className="trainer_course_sort">
    
   <div className="sort_f">
  <select name="carlist" form="carform" onChange={e=>this.sortData(e)}>
  {/* <option value="highest-rated">Sort by : Highest Rated</option> */}
  <option value="latest">Sort by : Newest</option>
  <option value="oldest">Sort by : Oldest</option>
  {/* <option value="price-low-to-high">Sort by : Lowest Price</option>
  <option value="price-high-to-low">Sort by : Highest Price</option> */}
  </select>
  </div> 
  
  </div><div className="trainer_course_add_course">
    
  <Link to="/instructorportal/newcourse">New Course</Link>
    
  </div>
  
   <div id="myBtnContainer">
    <button className={refineparam.type==''?"btn active":"btn"} onClick={(e)=>this.filterData(e,'')} > All Courses</button> 
    <button className={refineparam.type=='active'?"btn active":"btn"} onClick={(e)=>this.filterData(e,'active')}> Active Course</button>
    <button className={refineparam.type=='draft'?"btn active":"btn"} onClick={(e)=>this.filterData(e,'draft')}> Drafted Course</button>
    <button className={refineparam.type=='underreview'?"btn active":"btn"} onClick={(e)=>this.filterData(e,'underreview')}> Under Review Course</button>
    <button className={refineparam.type=='inactive'?"btn active":"btn"} onClick={(e)=>this.filterData(e,'inactive')}> In-Active Course</button>
    {/* <button className="btn" onclick="filterSelection('people')"> Inactive coutrse</button> */}
  </div> 
  
  
  </div>
  
  
  </div>
  
  <div className="course_name_block">
  <div className="main">
  
  
  {/* <!--=========================================================--> */}

  {
		this.state.serieslist.map((item, key) => {
		return <CourseCardTrainer key={key} item= {item}/>
  })
}
{(this.state.serieslist.length==0)? <div><h3 style={{'textAlign':'center'}}>You have not created any Course</h3></div>:''}

  {/* <!--======================================================================--> */}
  
  

{
  pagingItem.length ? <nav ariaLabel="Page navigation example">
    <ul class="pagination">
      {pagingItem.map((item) => {
        return item;
      })}
    </ul>
  </nav> : null
}

  
  
  </div>
  </div>
  
  </div>
  
  </div>
  
    <Footer />
    </>
        );
    }
}

export default TrainerCourse;