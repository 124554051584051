import React, { Component } from 'react';
import { Link,Redirect } from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import CourseCardHorizontal from './../../common/CourseCardHorizontal';

import { get, post,put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import * as Helper from './../../services/Helper';
//const $ = window.$;

class CourseLanding extends React.Component {

  constructor() {
    super();
    this.state = {
      redirect: false,
      editreview: false,
      rformdata: {
        rating: 0,
        review: ''
      },
      data: {
        series_id: 2,
        series_title: "Title of Series",
        series_sub_title: "Sub Title of Series",
        series_description: "Description about series",
        series_language: "English",
        series_level: "All",
        series_category: "Category",
        series_sub_category: "Subcategory",
        series_primary_teaching: "Topic",
        series_promo_video: null,
        series_trainer: "Trainer Name",
        series_image: null,
        approved: 1,
        created_on: "Date",
        updated_on: "Date",
        image_url: "http:\/\/training.local\/publicdata\/series\/image\/",
        objective: [
          {
            value: "",
            position: 1
          }
        ],
        requirment: [
          {
            value: "",
            position: 1
          }
        ],
        target: [
          {
            value: "",
            position: 1
          }
        ],
        modules: [
          {
            module_name: "",
            module_position: 1,
            module_content: []
          }
        ],
        reviewself: {},
        review: [],
        recomemded: [],
        series_skills:[],
        reviewavg: 0,
        reviewcount: 0,
        enrolled: false,
        saved:0,
      }
    };
    this.enroll = this.enroll.bind(this);
    this.editReview = this.editReview.bind(this);
    this.onChange = this.onChange.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.saveReview = this.saveReview.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.paynenroll = this.paynenroll.bind(this);

  } //end constructor

  componentWillMount() {
    // $('.search-toggle').addclassName('closed');

    // $('.search-toggle .search-icon').click(function(e) {
    //   if ($('.search-toggle').hasclassName('closed')) {
    //     $('.search-toggle').removeclassName('closed').addclassName('opened');
    //     $('.search-toggle, .search-container').addclassName('opened');
    //     $('#search-terms').focus();
    //   } else {
    //     $('.search-toggle').removeclassName('opened').addclassName('closed');
    //     $('.search-toggle, .search-container').removeclassName('opened');
    //   }
    // });

    //   $(".vpop").on('click', function(e) {
    //   e.preventDefault();
    //   $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    //   var srchref='',autoplay='',id=$(this).data('id');
    //   if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    //   else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";

    //   if($(this).data('autoplay') == true) autoplay = '?autoplay=1';

    //   $("#video-popup-iframe").attr('src', srchref+id+autoplay);

    //   $("#video-popup-iframe").on('load', function() {
    //     $("#video-popup-container").show();
    //   });
    // });

    // $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
    //   $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
    //   $("#video-popup-iframe").attr('src', '');
    // });

  }
  vpopClose=()=>{
    $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
      $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
      $("#video-popup-iframe").attr('src', '');
    });
  }
  vpopOpen=()=>{
   $(".vpop, .vvpop").on('click', function(e) {
    $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    var srchref='',autoplay='',id=$(this).data('id');
    if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";

    if($(this).data('autoplay') == true) autoplay = '?autoplay=1';

    $("#video-popup-iframe").attr('src', srchref+id+autoplay);

    $("#video-popup-iframe").on('load', function() {
      $("#video-popup-container").show();
    });
  });

  }
  


  componentDidMount() {

    get('seriesdetail', { seriesid: this.props.match.params.courseId }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })
        var rformdata = {
          rating: responseJson.data.reviewself.rating,
          review: responseJson.data.reviewself.review,
          seriesid: this.props.match.params.courseId
        }
        this.setState({ rformdata: rformdata })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });



  }

  saveReview(e) {

    post('updateReview', this.state.rformdata).then((result) => {
      let responseJson = result;
      if (responseJson.error === false) {
        this.componentDidMount()
        notify.show('Saved Successfully!', 'success');
        this.setState({ editreview: false })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

    e.preventDefault();

  }
  cancelEdit(e) {
    this.setState({ editreview: false });
    var rformdata = {
      rating: this.state.data.reviewself.rating,
      review: this.state.data.reviewself.review,
      seriesid: this.props.match.params.courseId
    }
    this.setState({ rformdata: rformdata })
    e.preventDefault();
  }

  editReview(e) {
    this.setState({ editreview: true });
    e.preventDefault();
  }
  enroll() {


    post('enroll', { series_id: this.props.match.params.courseId }).then((result) => {
      let responseJson = result;
      if (responseJson.eid && responseJson.error == false) {
        let data = Object.assign({}, this.state.data);    //creating copy of object
        data['enrolled'] = true;                        //updating value
        this.setState({ data });
        notify.show('Enrolled Successfully!!', 'success');
      } else {
        notify.show(responseJson.msg, 'error');
      }
    });
  }
  paynenroll() {


    post('sorderrequest', { series_id: this.props.match.params.courseId }).then((result) => {
      let responseJson = result;
      if (responseJson.oid && responseJson.error === false) {
        // let  data = Object.assign({}, this.state.data);    //creating copy of object
        // data['enrolled'] = true;                        //updating value
        
        window.location.href='https://www.veriipro.com/trainingws/public/processorder?q='+responseJson.oid
        //this.setState({ checkout: responseJson.oid });
        //this.setState({ redirect: true });
        // notify.show('Enrolled Successfully!!','success');

      } else {
        notify.show(responseJson.msg, 'error');
      }
    });

  }



  onChange(e) {
    let rformdata = Object.assign({}, this.state.rformdata);    //creating copy of object
    rformdata[e.target.name] = e.target.value;                        //updating value
    this.setState({ rformdata });
  }

  changeRating(v) {
    let rformdata = Object.assign({}, this.state.rformdata);    //creating copy of object
    rformdata['rating'] = v;                        //updating value
    this.setState({ rformdata });
  }

  saveCourse  = (series_id,action) =>{
    if(action=='login')
      this.props.history.push('/login')
    else
    put('saveseries', {action:action,series_id:series_id} ).then((result) => {
      let responseJson = result;
      if (responseJson.error === false) {
        let stateData = Object.assign({}, this.state.data);    //creating copy of object
                              //updating value
    
        if(action=='add'){
          stateData['saved'] = 1;  
        }else{
          stateData['saved'] = 0;  
        }
        // console.log(stateData['saved'])
        this.setState({ data:stateData });

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }


  shareSocial = (type) => {
    let shareurl = this.props.location.pathname;
    let url = '';

    if (type == 'facebook')
      url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.hostname + shareurl;
    else if (type == 'linkedin')
      url = 'https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.hostname + shareurl;
    else if (type == 'twitter')
      url = 'http://twitter.com/share?text=' + window.location.hostname + shareurl + '&url=' + window.location.hostname + shareurl + '&hashtags=Veriipro,JobsInUS,VerifiedJobs';
    window.open(url, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=350');
  }

  render() {


		if (this.state.redirect) {
			let oid=this.state.checkout;
			return <Redirect to={'/checkout/'+oid} />
      }
      
    return (
      <div>
        <Header />
        <div className="main-body course">
          <div className="container-fluid">
            <div id="leftside" className="lside left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span><span className="side_txt">  Home </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span><span className="side_txt"> Trending </span> </a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span><span className="side_txt"> Subscription </span> </a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span><span className="side_txt"> Course </span> </a> </li>
                  {/* <!--      <li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="leftside1" className="lsidee left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span></a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span></a> </li>
                  <li className="fourth"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span></a> </li>
                  {/* <!--      <li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="rightside" className="rside">
              <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:this.state.data.series_title}, ]} />
              <div className="body_main">


                <div className="main cou_det_pg">

                  <div className="Com_detail_top_panel">
                    <h2>{this.state.data.series_title}</h2>
                    <p>{this.state.data.series_sub_title}</p>
                    {this.state.data.featured?<span className="course-badge--course-badge--1AN7r"><span data-purpose="badge" className="on-course-card badge badge-accented yellow"><span className="badge-text">Bestseller</span></span></span>:null}
                    <div className="dec_star dec_all">
                      <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"   initialRating={this.state.data.reviewavg}/>
                    </div>
                    <span className="cd_wishlist">
                      {/* <i style={{ fontSize: '15px' }} className='fa'>&#xf004;</i> */}
                      
                    { 
                      (localStorage.ud_token)?
                      (this.state.data.saved)?
                    <i className="fa fa-heart " style={{ fontSize: '15px' }} title='Remove this Course from wishlist' onClick={()=>this.saveCourse(this.state.data.series_id,'remove')}></i>
                    :
                    <i className="fa fa-heart-o " style={{ fontSize: '15px' }} title='Save this Course' onClick={()=>this.saveCourse(this.state.data.series_id,'add')}></i>
                    :

                    <i className="fa fa-heart-o " style={{ fontSize: '15px' }} title='Login to Save this Course' onClick={()=>this.saveCourse(this.state.data.series_id,'login')}></i>
                    }
                      Wishlist</span>
                    <span style={{ color: '#fff' }} className="dec_rate_me">{this.state.data.reviewavg}</span>
                    <span className="dec_total_rateing dec_all"> ({this.state.data.reviewcount} rating)</span>
                    <p>{(this.state.data.series_enrolled)} Students Enrolled</p>
                    <p><Link to={"/instructor/"+this.state.data.series_trainer_id}>Created By {this.state.data.series_trainer}</Link></p>
                    <p>Last Updated {this.state.data.updated_on?(this.state.data.updated_on): (this.state.data.created_on)}</p>
                  </div>
                  <div className="main_detail_section">
                    <div className="container">
                      <div className="col-md-7 col-lg-7">
                        <div className="course_intro_section">
                          <h2>Course Details</h2>
                          <p>{/*this.state.data.series_description.split("\n").map((item)=> {
                                return (
                                  {item+'<br/>'}
                                )
                                */}
                              
                              {this.state.data.series_description.split('\n').map((item, key) => {
                                return <span key={key}>{item}<br/></span>
                              })}</p>
                        </div>

                        <div className="course_content_panels">
                          <div className="panel_title_time_etc">
                            <span className="cor_con">COURSE CONTENT</span>
                            <span className="tim_all">{Helper.sec2HrMin(this.state.data.series_lecture_duration)}</span>
                            <span className="fr_lec">{this.state.data.series_lecture_count} Lectures</span>
                            <span className="exp_all expand_all"><div id="collapsebutton" className="nodisp expandcollapse no-print"><i className="glyphicon glyphicon-minus"></i> Collapse All</div>
                              <div id="expandbutton" className="disp expandcollapse no-print"><i className="glyphicon glyphicon-plus"></i> Expand All</div></span>

                          </div>

                          <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                          {
                          this.state.data.modules.map((item,key)=>{
                            return  <div className="panel panel-default"  key={key}>
                              <div className="panel-heading" role="tab" id={"heading"+key}>
                                <h4 className="panel-title">
                                  <a role="button" data-toggle="collapse" data-parent="#accordion" href={"#collapse"+key} aria-expanded="true" aria-controls="collapseOne">
                        <i className="more-less glyphicon glyphicon-plus"></i>
                                  <span className="according_content">{item.module_name}</span>
                                    <span>{Helper.sec2HrMin(item.lecture_duration)}</span>
                                  </a>
                                </h4>
                              </div>
                              <div id={"collapse"+key} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading"+key}>
                                <div className="panel-body">
                                {
                                item.module_content.map((module,keyM)=>{
                                  return(  
                                    (module.conten_type)? 
                                        (module.sample)? <div style={{ color: '#08bbf5' }} className="well well-sm"   key={keyM}>
                                                            {(module.content_type=='V')? <i style={{ color: '#08bbf5' }} className="material-icons"></i> : <i style={{ color: '#08bbf5' }}  className="fa" aria-hidden="true"></i> }
                                                            {module.title}  
                                                            <span className="tim_ing pa_es">{Helper.sec2HrMin(module.duration)}</span> 
                                                            <span className="tim_ing pa_es vvpop" onClick={(e)=>this.vpopOpen(e)} data-type="vimeo" data-id={module.samplevideo} data-video-ref={module.samplevideo}  data-article-ref={module.article} >Preview</span>
                                                        </div>
                                        
                                           : 
                                          <div className="well well-sm"  key={keyM}>
                                            {(module.content_type=='V')? <i className="material-icons"></i> : <i className="fa" aria-hidden="true"></i> }
                                            {module.title}
                                            <span className="tim_ing">{Helper.sec2HrMin(module.duration)}</span>
                                          </div>
                                        :
                                        null

                                  )
                                })
                              }
                                  
                                </div>
                              </div>
                            </div>

                          })
                        }

                          </div>
                          {/* <!-- panel-group --> */}

                        </div>

                        <div className="req_ment">
                          { (this.state.data.objective.length > 0) ?
                         <><h2>Objective</h2>
                          <ul>
                          {this.state.data.objective.map((item,key)=>{
                            return <li key={key}>{item.value}</li>
                          })}
                          </ul></>
                          : null

                          }

                        { (this.state.data.target.length > 0) ?
                         <>
                          <h2>Target Individuals</h2>
                          <ul>
                          {this.state.data.target.map((item,key)=>{
                            return <li key={key}>{item.value}</li>
                          })}
                          </ul></>
                          : null

                          }

                          { (this.state.data.requirment.length > 0) ?
                         <>
                          <h2>Requirements</h2>

                          <ul>
                          {this.state.data.requirment.map((item,key)=>{
                            return <li key={key}>{item.value}</li>
                          })}
                          </ul></>
                          : null

                          }

                        </div>

                        
{/* review rating section */}
<div className="rev__rting">
                
                <div className="rev__rting">
                <div className="rrting__sec main___rting_show">
                <div className="col-sm-12">

                {this.state.data.reviewcount?
                <div className="rev_iew_all_stars">
                <div  className="rat_ing_review_block">
                <span  className="rating_of_mine_here"> {this.state.data.reviewavg} </span>
                <span >({this.state.data.reviewcount} Rating)</span>
                <div  className="interstr">
                <div  className="my-rating" data-rating={this.state.data.reviewavg}>
                <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={this.state.data.reviewavg}/>
                </div></div> </div>
                
                
                {/* <div  className="al_l_star_sh_ow">
                <div  className="my_rating_he_re">
                <span  className="ra_te_out_of">5</span>
                <span  className="rate_prog_ress">
                <div  className="progress">
                <div  className="progress-bar excep" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:90}}>
                <span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
                <div  className="my_rating_he_re">
                <span  className="ra_te_out_of">4</span>
                <span  className="rate_prog_ress">
                <div  className="progress">
                <div  className="progress-bar ab_av" role="progressbar" aria-valuenow="66.666666666667" aria-valuemin="0" aria-valuemax="100" style={{width:66}}>
                <span  className="sr-only">66.666666666667% Complete</span> </div> </div> </span> </div>
                <div  className="my_rating_he_re">
                <span  className="ra_te_out_of">3</span>
                <span  className="rate_prog_ress">
                <div  className="progress">
                <div  className="progress-bar av" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:50}}>
                <span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
                <div  className="my_rating_he_re">
                <span  className="ra_te_out_of">2</span>
                <span  className="rate_prog_ress">
                <div  className="progress">
                <div  className="progress-bar be_av" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width:30}}>
                <span  className="sr-only">0% Complete</span> </div> </div> </span> </div>
                <div  className="my_rating_he_re">
                <span  className="ra_te_out_of">1</span>
                <span  className="rate_prog_ress">
                <div  className="progress">
                <div  className="progress-bar unsat" role="progressbar" aria-valuenow="33.333333333333" aria-valuemin="0" aria-valuemax="100" style={{width:20}}>
                <span  className="sr-only">33.333333333333% Complete</span> </div> </div> </span> </div> </div>
                */}
                </div>
                :null}
                </div></div> 

               
               
                <div className="rrting__sec main__comt_sec">
                <div className="col-sm-12">
                <div className="review-block">

                {this.state.data.reviewself.date?
                <div className="rrting__sec">
                <div className="col-sm-2"><img src={this.state.data.reviewself.image?this.state.data.reviewself.image_url+this.state.data.reviewself.image:'/images/user-dummy.png'} alt={this.state.data.reviewself.name} className="img-rounded"/>
                <div className="review-block-name"><Link to="#">{this.state.data.reviewself.name}</Link></div></div>
                <div className="col-sm-10">
                <div className="review-block-rate">
                <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={this.state.data.reviewself.rating}/>
                <div className="review-block-date">{Helper.formatDate(this.state.data.reviewself.date)} </div></div>
                <div className="review-block-description">{this.state.data.reviewself.review}</div></div>
                </div>
                :null}

                {this.state.data.review.map((item, key) => { 
                return <div className="rrting__sec">
                <div className="col-sm-2"><img src={item.image?item.image_url+item.image:'/images/user-dummy.png'} alt={item.name} className="img-rounded"/>
                <div className="review-block-name"><Link to="#">{item.name}</Link></div></div>
                <div className="col-sm-10">
                <div className="review-block-rate">
                <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star checked" readonly="true"  fractions={2} initialRating={item.rating}/>
                <div className="review-block-date">{Helper.formatDate(item.date)} </div></div>
                <div className="review-block-description">{item.review}</div></div>
                </div>
                }) }
                
               
      
                </div></div></div></div>        
                    </div>
                
                
                {/* end of review rating section */}

                        { ( this.state.data.recomemded.length >0)? 
                        <div className="related_course_detail_page">

                          <h2>Related courses</h2>

                          {
						              this.state.data.recomemded.map((item, key) => {
                            return <CourseCardHorizontal   key={key} item={item}  />
  //                        return  <div className="dec_course_info">
  //                           <a href="course_detail.html">
  //                             <div className="container">
  //                               <div className="col-md-4 col-lg-4">
  //                                 <img src="/images/cloud.jpg" alt="Chicago" />
  //                                 <div className="cou_tim_ng">
  //                                   04:20:50
  // </div>
  //                               </div>
  //                               <div className="col-md-8 col-lg-8">
  //                                 <div className="dec_back_info">
  //                                   <div className="al_crs_title">
  //                                     <h3>React - The Complete Guide (incl Hooks, React Router, Redux)</h3>
  //                                   </div>
  //                                   <div className="dec_detail_info">Master Node JS</div>


  //                                 </div>
  //                               </div>
  //                             </div>
  //                           </a>
  //                         </div>
                          })
                          }

                        </div> : null 
                        }

                      </div>
                      <div className="col-md-5 col-lg-5 padd_for_use">
                        <div className="all_right_bar_panel_detail">
                          <div className="cou_vid_tumb_nail">
                            <div className="topic_video_lesson">

                              <img src={this.state.data.series_image?this.state.data.image_url+this.state.data.series_image:"/images/cloud.jpg"} alt="Course Preview" style={{ width: '100%' }} />

                              <div className="vpop" id='vpop' onClick={(e)=>this.vpopOpen(e)} data-type="vimeo" data-id={this.state.data.series_promo_video} data-autoplay="true">
                                <i className="fa video_po_pup"></i>
                              </div>

                              {/* <!-- copy this stuff and down --> */}
                              <div id="video-popup-overlay"  onClick={this.vpopClose}>✖</div>

                              <div id="video-popup-container">
                                <div id="video-popup-close" onClick={this.vpopClose} className="fade">&#10006;</div>
                                <div id="video-popup-iframe-container">
                                  <iframe id="video-popup-iframe" src={'https://player.vimeo.com/video/'+this.state.data.series_promo_video} width="100%" height="100%" frameborder="0"></iframe>
                                </div>
                              </div>

                            </div>
                            {/* <!--   <div className="img_course_hover">
  <img src="https://www.udemy.com//staticx/udemy/js/webpack/icon-play.ac3f32ecb72a0c3f674fa5a3f3062a56.svg" alt="" className="hover_img_course"/>
  <p>Preview this course</p>
</div> --> */}
                          </div>

                          <div className="cou_pri_det_full">
                            <div className="cup_and_price">
                              {/* <span className="cup_on">Apply Coupon</span> */}
                              <span className="pri_ing">{(this.state.data.series_type=='PAID')? '$ '+this.state.data.series_price : 'Free '}</span>
                            </div>

                            
                            <div className="crs_vide_pric_es">

                          {
                            (this.state.data.enrolled) ?
                                <Link to={"/explorecourse/"+this.props.match.params.courseId}><button data-purpose="buy-this-course-button" type="button" className="course-cta btn btn-lg btn-quaternary btn-block">Go to Course</button></Link>
                              
                            :
                            (this.state.data.series_type==='PAID')?
                              (localStorage.getItem('ud_token'))?
                                  <button onClick={this.paynenroll} data-purpose="buy-this-course-button" type="button" className="course-cta btn btn-lg btn-quaternary btn-block">Buy now &amp; Enroll to the Course</button>
                                :
                                <Link to={'/login'}><button onClick={this.paynenroll}  type="button" className="course-cta btn btn-lg btn-quaternary btn-block"> Login to Buy Enroll </button></Link>
                              :
                              (localStorage.getItem('ud_token'))?
                                  <button onClick={this.enroll} data-purpose="buy-this-course-button" type="button" className="course-cta btn btn-lg btn-quaternary btn-block"> Enroll to the Course</button>
                                :
                                <Link to={'/login'}><button onClick={this.paynenroll}  type="button" className="course-cta btn btn-lg btn-quaternary btn-block"> Login to Enroll for Free</button></Link>
                          }
                          </div>

                            <div className="mon_y_ba_k">
                              {/* <span className="money-back">
                                30-Day Money-Back Guarantee
</span> */}
                            </div>
                            <div className="incentives">
                              <div className="clp-component-render"><div>
                                <span id="incentives" className="in-page-offset-anchor"></span>
                                <div className="incentives__header">
                                  This course includes
</div>
                              </div>
                                <ul className="incentives__list">

                                  <li className="incentives__item">
                                    <i className="incentives__icon incentives__icon--bold udi udi-video-design"></i>
                                    <span className="incentives__text" data-purpose="video-content-length">
                                      {this.state.data.series_duration } {this.state.data.series_duration_type} on-demand videos</span>
                                  </li>

                                  <li className="incentives__item">
                                    <i className="incentives__icon udi udi-file-o"></i>
                                    <span className="incentives__text">
                                    {this.state.data.series_article_count }  articles</span>
                                  </li>

                                  {/* <li className="incentives__item">
                                    <i className="incentives__icon udi udi-file-text-o"></i>
                                    <span className="incentives__text">
                                      19 downloadable resources</span>
                                  </li> */}

                                  {/* <li className="incentives__item">
                                    <i className="incentives__icon udi udi-curly-braces-bold"></i>
                                    <span className="incentives__text" data-purpose="incentive-coding-exercises">
                                      4 coding exercises</span>
                                  </li> */}

                                  <li className="incentives__item">
                                    <i className="incentives__icon incentives__icon--bold udi udi-what-lifetime"></i>
                                    <span className="incentives__text" data-purpose="incentive-lifetime-access">
                                      Full lifetime access</span>
                                  </li>

                                  <li className="incentives__item">
                                    <i className="incentives__icon incentives__icon--bold udi udi-mobile-apps"></i>
                                    <span className="incentives__text">
                                      Access on mobile app and website</span>
                                  </li>
                                  
                                </ul></div>
                              <div className="clp-component-render"><ul className="incentives__list">
                                <li className="incentives__item">
                                  <i className="incentives__icon incentives__icon--bold udi udi-academics"></i>
                                  <span className="incentives__text" data-purpose="incentive-certificate">
                                    Certificate of Completion
</span>
                                </li>
                              </ul></div>
                            </div>
                            <div className="marketplace-social-share-button--share-button--79rGp">
                              <div className="marketplace-social-share-button--share-button__top-border--bacJS"></div>
                              <div className="shre__option">
                                <div className="my__shre__op">
                                <span className="fa fa-share"></span>
                              <span className="marketplace-social-share-button--share-button__text--1jqY7">Share</span>
                              
                                </div>
                              <div className="social__icon___onhover">
                                <i className="fa fa-facebook" onClick={()=>this.shareSocial('facebook')} ></i>
                                <i className="fa fa-linkedin" onClick={()=>this.shareSocial('linkedin')} ></i>
                                <i className="fa fa-twitter" onClick={()=>this.shareSocial('twitter')} ></i>
                              </div>
                              </div>
                            </div>



                            <section className="overview-panel__authors">
                              <h3 className="overview-panel__authors-headline">Instructor</h3>

                              <ul className="overview-panel__authors-list">
                                <li>
                                  <div className="overview-panel__entity-lockup">
                                    <figure>
                                    <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        <img className="lazy-loaded" alt={this.state.data.series_trainer} src={this.state.data.series_trainer_image?this.state.data.trainer_image_url+this.state.data.series_trainer_image:'/images/user-dummy.png'}/>
                                      </Link>
                                    </figure>
                                    <div>
                                      <h4 className="overview-panel__entity-lockup-title">
                                      <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        {this.state.data.series_trainer} </Link>
                                      </h4>
                                      <h5 className="overview-panel__entity-lockup-headline">{this.state.data.series_trainer_professional_title}</h5>

                                      <label className="overview-panel__entity-lockup-label" for="author0-readmore">
                                      <Link to={"/instructor/"+this.state.data.series_trainer_id} >
                                        <span className="overview-panel__entity-lockup-label-more">Learn More</span></Link>
                                      </label>
                                      {this.state.data.series_trainer_linkedin?
                                      <>
                                      <span className="vertical-divider"></span>
                                      <a target="_blank" href={ Helper.url(this.state.data.series_trainer_linkedin)} className="entity-action" >
                                        View on LinkedIn  </a></>:null}

                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </section>


                            <div className="overview-panel__skills">
                              <h3 className="panel__headline">Skills covered in this course</h3>
                              <ul className="skills__list">
                                {this.state.data.series_skills.map((item,key)=>{
                                return <li className="skills__skill">
                                    <Link to={ item.slug?'/technology/'+item.slug:'/technology/'+item.id} data-tracking-control-name="course_skill" data-tracking-will-navigate="" className="pill" saprocessedanchor="true">
                                     {item.technology_name}</Link>
                                    </li>
                                   })
                                  }
                                
                                
                              </ul>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>


                </div></div>


              <Footer />

            </div>


          </div>
        </div>




      </div>
    );
  }

}
export default CourseLanding;
