import React from 'react';
//import $ from 'jquery'
import { Link } from 'react-router-dom'

import Header from './../../common/Header'
import Footer from './../../common/Footer'
import Breadcrumb from './../../common/Breadcrumb'

import { get } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

const $ = window.$;

class OrderHistory extends React.Component {

  constructor() {
    super();
    this.state = {
      data: []
    };

  } //end constructor

  componentDidMount() {

    get('orderhistory', {}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });



  }


  render() {

    return (

      <>
        <Header />


        <div className="check_out_page_start">
          <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Order History'}]} />
          <div className="check_out_page">

            <div className="check_out_banner">

              <p>Order History</p>

            </div>


            <div className="main_section_of_check_out">

              <div className="check_out_detail_section">


                <table className="table table-dark">
                  <tr>
                    <th scope="col">S No.</th>


                    <th scope="row">Order Id</th>

                    <th scope="row">Date</th>

                    <th scope="row">Product Description</th>

                    <th scope="row">Amount</th>

                  </tr>

                  {
                    this.state.data.map((item, key) => {
                      return <tr>
                        <td scope="col">{key + 1}</td>

                        <td scope="row">{item.ORDER_ID}</td>

                        <td scope="row">{item.DATE}</td>

                        <td scope="row"><Link to={'/course/' + item.ITEM_ID} target='_blank'>{item.REMARK}</Link>	</td>

                        <td scope="row">$ {item.TOTAL}</td>
                      </tr>

                    })}
                  {(this.state.data.length === 0) ? <tr><td colspan={5} style={{ textAlign: 'center'}}>You have not purchased any Course Yet!</td></tr> : ''}


                </table>

              </div>

            </div>



          </div>

        </div>


        <Footer />
      </>




    );
  }
}

export default OrderHistory;
