import React from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Notifications from 'react-notify-toast';
import { PrivateRoute, LoginRoute } from './services/Router';
import ScrollToTop from './services/ScrollToTop';

import Home from './pages/home/Index';
import CourseLanding from './pages/course/CourseLanding';
import BrowseCourses from './pages/course/BrowseCourses';
import MyCourses from './pages/course/MyCourses';
import CourseExplore from './pages/course/Course_explore_video';
import SearchResult from './pages/course/SearchResult';
import TermUse from './pages/privacy/Termuse';
import Privacy from './pages/privacy/Privacy';
import TopSubject from './pages/category/TopSubject';
import TopTechnology from './pages/category/TopTechnology';
import Trainer from './pages/trainer/Trainer';
import About from './pages/about/About';
import Login from './pages/login/Login';
import Signup from './pages/login/Signup';
import Forgot from './pages/login/Forgot';
import Chngpwd from './pages/login/Chngpwd';

import { connect } from 'react-redux'
import OrderHistory from './pages/order/OrderHistory';
import Checkout from './pages/order/Checkout';
import Confirmation from './pages/order/Confirmation';
import LearningPath from './pages/learningpath/LearningPath';

import TrainerHome from './pages/trainer/Index';
import TrainerCourse from './pages/trainer/Homepage';
import TrainerProfile from './pages/trainer/Profile';
import CreateNewCourse from './pages/trainer/NewCourse';
import ManageCourse from './pages/trainer/ManageCourse';
import ManageObjective from './pages/trainer/ManageObjective';
import ManageCurriculum from './pages/trainer/ManageCurriculum';
import ManagePreview from './pages/trainer/ManagePreview';
import ManagePricing from './pages/trainer/ManagePricing';
import ManageSubmit from './pages/trainer/ManageSubmit';
import Tips from './pages/trainer/Tips';
import Contact from './pages/contact/Contact';
function App(prop) {
  if (prop.redirectToHome === true) {
    prop.redirect(false);
    return <Redirect to={'/'} />
  }


  return (
    <>
      <ScrollToTop />
      <Notifications />

      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />

      <Route exact path="/course/:courseId" component={CourseLanding} />
      <Route exact path="/browse-course/:categoryId?" component={BrowseCourses} />
      <PrivateRoute exact path="/explorecourse/:courseId/:moduleId?" component={CourseExplore} />
      <Route exact path="/mycourse/:type?" component={MyCourses} />

      <Route exact path="/search/:query" component={SearchResult} />
      <Route exact path="/instructor/:trainerId" component={Trainer} />
      <Route exact path="/subject/:subjectId?" component={TopSubject} />
      <Route exact path="/learningpath/:careerId?" component={LearningPath} />
      <Route exact path="/technology/:technologyId?" component={TopTechnology} />
      <PrivateRoute exact path="/myorders" component={OrderHistory} />
      <PrivateRoute exact path="/checkout/:orderId" component={Checkout} />
      <PrivateRoute exact path="/Confirmation/:orderId" component={Confirmation} />
      <Route exact path="/contact" component={Contact} />


      <Route exact path="/instructorportal" component={TrainerHome} />
      <PrivateRoute exact path="/instructorportal/profile" component={TrainerProfile} />
      <PrivateRoute exact path="/instructorportal/mycourses" component={TrainerCourse} />

      <PrivateRoute path="/instructorportal/newcourse" component={CreateNewCourse} />
      
      <PrivateRoute path="/instructorportal/managecourse/:courseId?" component={ManageCourse} />
      <PrivateRoute path="/instructorportal/manageobjective/:courseId" component={ManageObjective} />
      <PrivateRoute path="/instructorportal/managecurriculum/:courseId" component={ManageCurriculum}   />
      <PrivateRoute path="/instructorportal/managepricing/:courseId" component={ManagePricing} />
      <PrivateRoute path="/instructorportal/managepreview/:courseId" component={ManagePreview} />
      <PrivateRoute path="/instructorportal/submit/:courseId" component={ManageSubmit} />
      <PrivateRoute path="/instructorportal/tips" component={Tips} />

      {/* <Route exact path="/about" component={About} />
      <Route exact path="/gallery" component={Gallery} />
      <Route exact path="/contact" component={Contact} />
      <Route exact path="/courses/:categoryId" component={CourseCatalog} />
      <Route exact path="/search/:query" component={SearchResult} />
      <Route exact path="/course/:seriesId" component={CourseLanding} />
      <PrivateRoute path="/exploreseries/:seriesId/:moduleId?" component={CourseExplore} />
      <PrivateRoute exact path="/enrolledseries" component={EnrolledCourse} />
      <PrivateRoute exact path="/checkout/:orderId" component={Checkout} />
      <PrivateRoute exact path="/confirmation/:orderId" component={Confirmation} />
      <PrivateRoute exact path="/myorders" component={OrderHistory} />

      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/changepassword" component={ChangePassword} />

      <PrivateRoute path="/myseries" component={MyCoursesi} />
      <PrivateRoute path="/newseries" component={NewCoursei} />
      <PrivateRoute path="/manageseries/:seriesId" component={ManageCoursei} />
      <PrivateRoute path="/managepricing/:seriesId" component={ManagePricingi} />
      <PrivateRoute path="/managepreview/:seriesId" component={ManagePreviewi} />
      <PrivateRoute path="/manageseriegoal/:seriesId" component={ManageCourseGoali} />
      <PrivateRoute path="/manageseriescurriculum/:seriesId" component={ManageCurriculumi} />

      <LoginRoute path="/login" component={Login} />
      <LoginRoute path="/signup" component={Signup} />
      <LoginRoute path="/forgot" component={Forgotpassword} /> */}

      <Route exact path="/terms-of-use" component={TermUse} />
      <Route exact path="/privacy" component={Privacy} />
      <LoginRoute path="/login" component={Login} />
      <LoginRoute path="/signup" component={Signup} />
      <LoginRoute path="/forgot" component={Forgot} />
      <PrivateRoute path="/update-password" component={Chngpwd} />
    </>

  );
}

const mapStateToProps = (state) => {
  return {
    redirectToHome: state.redirectToHome
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    redirect: (value) => { dispatch({ type: 'CHANGE_REDIRECT2HOME', payload: value }) }
  }
}
//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(App);
