import React from "react";

class RequirmentInput extends React.Component {

  render() {

    return (
    <div className="mt-10">
					
        <input type="text" name="requirment"   onClick={e=>this.props.action(e,this.props.datakey)} placeholder="eg: xxxx" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'eg: xxxx'" required className="single-input-accent" />
    </div>


);
}
}

export default RequirmentInput;