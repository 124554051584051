import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'


import { get, put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';


class ManageSubmit extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

      const formdata = {
        series_id: this.props.match.params.courseId
  
      };
  
      put('publishseries', formdata).then((result) => {
        let responseJson = result;
        if (responseJson.error == false) {
  
          notify.show('Submited For Review  Successfully!', 'success');
  
  
        } else {
          notify.show('Something Went Wrong!', 'error');
        }
      });
  
       
    }

    render() {
        return (
            <>
            <Header trainerPage={true} /> 
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />

    <div className="main-body">
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
    <div className="btac ttl_tg_all">&#8735;</div>
  <p className="aabtx ttl_tg_all"><Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>
  <p className="crs_name ttl_tg_all"></p>
  {/* <p className="crs_sts ttl_tg_all">inactive</p> */}
  </div>

  <div className="topic_exit">
    <Link to="/instructorportal/tips">Tips <i className="fa fa-info-circle"></i></Link>
  </div>
</div>

<TrainerSidebar  courseId={this.props.match.params.courseId } />

<div className="dnc_right_bar">

<div id="Submit_for_review" className="tabcontent">

<div className="design_new_course_form">

<div className="container">

<div className="dnc_crs_img review_request">
<p>
  <span>⚠</span>
Your course review request is under process. During this time you cannot do any updation. It will take upto 48hrs
</p>
</div>

</div>
{/* <!-- /.container --> */}

</div>

</div>

</div>


</div>

</div>
{/* <!-- END MAIN --> */}
</div>


  
    <Footer  NoNavigation={true} />
    </>
        );
    }
}

export default ManageSubmit;