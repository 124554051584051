import React from 'react';
//import $ from 'jquery'
import { Link } from 'react-router-dom'

import Header from './../../common/Header'
import Footer from './../../common/Footer'
import Breadcrumb from './../../common/Breadcrumb'

import { get, BaseUrl } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

const $ = window.$;

class Checkout extends React.Component {

  constructor() {
    super();
    this.state = {
      data: []
    };

  } //end constructor

  componentDidMount() {

    get('fertchcheckout', { oid: this.props.match.params.orderId }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });



  }


  render() {

    return (

      <>

        <Header />



        <div className="check_out_page_start">
          <Breadcrumb path={[{ link: '/', label: 'Home' }, { link: '', label: 'Checkout' }]} />
          <div className="check_out_page">

            <div className="check_out_banner">

              <p>Order Summary</p>

            </div>


            <div className="main_section_of_check_out">

              <div className="check_out_detail_section">

                <h2>Order# {this.state.data.ORDER_ID}</h2>

                <table className="table table-dark">
                  <tr>
                    <th scope="row">Description</th>

                    <th scope="row">Price</th>

                    <th scope="row">Quantity</th>

                    <th scope="row">Total</th>
                  </tr>
                  <tr>

                    <td scope="row"><Link to={'/course/'+this.state.data.ITEM_ID} target='_blank'>{this.state.data.REMARK}</Link></td>

                    <td scope="row">$ {this.state.data.PRICE}</td>

                    <td scope="row">1</td>

                    <td scope="row">${this.state.data.PRICE}</td>
                  </tr>

</table>

              <div className="check_ot_btn">
                <a href={BaseUrl()+'/processorder?q='+this.state.data.ORDER_ID}> Pay Now</a>
              </div>

            </div>

          </div>



        </div>

      </div>



      <Footer />
      </>




    );
  }
}

export default Checkout;
