import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';

import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';

import * as Constant from './../../services/Constant'

//const $ = window.$;

class About extends React.Component {

  constructor(){
    super();
  }

  componentDidMount(){

    //     filterSelection("all")
    // function filterSelection(c) {
    //   var x, i;
    //   x = document.getElementsByclassNameName("column");
    //   if (c == "all") c = "";
    //   for (i = 0; i < x.length; i++) {
    //     w3RemoveclassName(x[i], "show");
    //     if (x[i].classNameName.indexOf(c) > -1) w3AddclassName(x[i], "show");
    //   }
    // }

    // function w3AddclassName(element, name) {
    //   var i, arr1, arr2;
    //   arr1 = element.classNameName.split(" ");
    //   arr2 = name.split(" ");
    //   for (i = 0; i < arr2.length; i++) {
    //     if (arr1.indexOf(arr2[i]) == -1) {element.classNameName += " " + arr2[i];}
    //   }
    // }

    // function w3RemoveclassName(element, name) {
    //   var i, arr1, arr2;
    //   arr1 = element.classNameName.split(" ");
    //   arr2 = name.split(" ");
    //   for (i = 0; i < arr2.length; i++) {
    //     while (arr1.indexOf(arr2[i]) > -1) {
    //       arr1.splice(arr1.indexOf(arr2[i]), 1);     
    //     }
    //   }
    //   element.classNameName = arr1.join(" ");
    // }


    // // Add active className to the current button (highlight it)
    // var btnContainer = document.getElementById("myBtnContainer");
    // var btns = btnContainer.getElementsByclassNameName("btn");
    // for (var i = 0; i < btns.length; i++) {
    //   btns[i].addEventListener("click", function(){
    //     var current = document.getElementsByclassNameName("active");
    //     current[0].classNameName = current[0].classNameName.replace(" active", " ");
    //     this.classNameName += " active";
    //   });
    // }

    //     $('.search-toggle').addclassName('closed');

    // $('.search-toggle .search-icon').click(function(e) {
    //   if ($('.search-toggle').hasclassName('closed')) {
    //     $('.search-toggle').removeclassName('closed').addclassName('opened');
    //     $('.search-toggle, .search-container').addclassName('opened');
    //     $('#search-terms').focus();
    //   } else {
    //     $('.search-toggle').removeclassName('opened').addclassName('closed');
    //     $('.search-toggle, .search-container').removeclassName('opened');
    //   }
    // });

  
    // $(".vpop").on('click', function(e) {
    //     e.preventDefault();
    //     $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();
        
    //     var srchref='',autoplay='',id=$(this).data('id');
    //     if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    //     else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";
        
    //     if($(this).data('autoplay') == true) autoplay = '?autoplay=1';
        
    //     $("#video-popup-iframe").attr('src', srchref+id+autoplay);
        
    //     $("#video-popup-iframe").on('load', function() {
    //       $("#video-popup-container").show();
    //     });
    //   });
      
    //   $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
    //     $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
    //     $("#video-popup-iframe").attr('src', '');
    //   });
      
    //   /* 
    //   minified
      
    //   $(".vpop").on("click",function(o){o.preventDefault(),$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();var p="",e="",i=$(this).data("id");if("vimeo"==$(this).data("type"))var p="//player.vimeo.com/video/";else if("youtube"==$(this).data("type"))var p="https://www.youtube.com/embed/";1==$(this).data("autoplay")&&(e="?autoplay=1"),$("#video-popup-iframe").attr("src",p+i+e),$("#video-popup-iframe").on("load",function(){$("#video-popup-overlay, #video-popup-container").show()})}),$("#video-popup-close, #video-popup-overlay").on("click",function(o){$("#video-popup-iframe-container,#video-popup-container,#video-popup-close").hide(),$("#video-popup-iframe").attr("src","")});
    //   */   
  }

render() {
  return (
    <div> 

<Header />

<div className="main-body">
<div className="container-fluid">
<div id="leftside" className="lside left_bar">
	<div className="side-bar">
		<ul className="left-side-bar">
			<li className="first active_side_bar"><a href="index.html"> <span className="side_icon"><img src="images/home.png" alt=""/> </span><span className="side_txt">  Home </span> </a> </li>
			<li className="second"><a href=""> <span className="side_icon"><img src="images/trending.png" alt=""/> </span><span className="side_txt"> Trending </span> </a> </li>
			<li className="third"><a href=""> <span className="side_icon"><img src="images/sub.png" alt=""/> </span><span className="side_txt"> Subscription </span> </a> </li>
			<li className="fourth"><a href="course.html"> <span className="side_icon"><img src="images/course.png" alt=""/> </span><span className="side_txt"> Course </span> </a> </li>
{/* <!-- 			<li className="fivth"></li> --> */}
		</ul>
	</div>

</div>
<div id="leftside1" className="lsidee left_bar">
	<div className="side-bar">
  <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'About us'} ]} />
	</div>

</div>
<div id="rightside" className="rside">
<Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'About us'} ]} />

<div className="our_story">
  
<div className="content_our_story">

<div className="con_os">
  
<div className="con_os_left">
  
<div className="mission__statement">
  <h2 className="white">Our Mission</h2>
  <p className="white">Our mission is to power careers through tech education. We partner with leading technology companies to learn how technology is transforming industries, and teach the critical tech skills that companies are looking for in their workforce. With our powerful and flexible digital education platform, even the busiest learners can prepare themselves to take on the most in-demand tech roles.</p>
</div>

</div>
{/* <div className="con_os_right">
  
  <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

    <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

    <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

    <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

    <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

    <div className="os_img">
    <img className="company-minimal-image" src="https://www.veriipro.com/wc4u_assets/img/company_logo.svg" alt=""/>
  </div>

</div> */}

</div>

</div>

</div>




<div className="abt_us_page_here">
  
  <div className="abtuspg">
    <h2>About us</h2>
    
<p>VeriiPro – Verified Professionals is a leading digital platform enabling highly skilled individuals to connect with potential matching employers looking to fill open positions within their organization. Modern-day recruiting and job search has many challenges, which include finding the right employer and working environment for individuals as well as finding a verified quality individual for an employer. We are working to achieve our only goal of improving the job-seeking experience of individuals and recruiting experience of employers.</p>

<p>We offer verified talent, which our highly skilled recruiting team has thoroughly verified in terms of skill set and experience the individual possesses. Our the verification process helps employers eliminate their first step of recruiting in terms of verifying an individual’s experience and skillset as displayed on their profiles. This verification process helps our employers reduce their recruiting efforts and in finding the right talent quickly, which we all know is a very big edge in the recruiting world to parse through millions of potential individuals and finding the right match as quickly as possible.</p>

<p>Due to our robust verification process, the best employers in term of the work environment, innovation, employee-driven, and leaders in their sector are attracted to recruit on our platform. Individuals enrolled on our platform are able to connect with industry-leading employers to land their next dream job.</p>

<p>We at Veriipro cordially invite highly skilled individuals and industry-leading employer to join our platform and provide their valuable support in improving the modern-day Job search and recruiting experience.</p>

<p>VeriiPro - Learning is an online learning platform to learn business, technology, creative, and marketing skills to achieve personal and professional goals. We provide a wide range of video library having top-quality courses taught by recognized industry experts.</p>

  </div>

</div>





<div className="join_us">
<section _ngcontent-iridium-us-c22="" className="join-us ng-star-inserted"><div _ngcontent-iridium-us-c22="" className="contain"><div _ngcontent-iridium-us-c22="" className="join-us__copy bg-white"><h4 _ngcontent-iridium-us-c22="" className="text-center light">Join Our Mission To Democratize Education</h4><a _ngcontent-iridium-us-c22="" className="button--primary center-block join_btn" href={Constant._SITE_JOB_URL} target='_blank'>Jobs @ Veriipro</a></div></div></section>
</div>
{/* <!-- FOOTER --> */}

<Footer />

{/* <!-- END MAIN-FOOTER --> */}

</div>


</div>
</div>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
 <script src="js/jquery.js"></script>                
<script src="js/bootstrap.min.js"></script>  
<script>
</script>

<script type="text/javascript">

</script>

<script type="text/javascript">

</script>
</div>



  );
}

}
export default About;
