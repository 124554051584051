const iState = {
    redirectToHome :false,
}
const reducer = (state = iState, action)=>{

    if(action.type ==='CHANGE_REDIRECT2HOME'){
        return {
            ...state,
            redirectToHome : action.payload
        }
    }
    return state;
}

export default reducer;