import React from 'react';
//import $ from 'jquery'
import { Link, Redirect } from "react-router-dom";
import { post, DeviceId, DeviceType } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

class Login extends React.Component {

	constructor() {
		super();

		this.state = {
			email: '',
			password: '',
			deviceid: DeviceId(),
			devicetype: DeviceType(),
			redirectToReferrer: false,
			otp: '',
			userid: '',

		};
	}
	componentDidMount() {

	}

	

	login = (e) => {
		e.preventDefault();
		if (this.state.email && this.state.password) {

			post('login', this.state).then((result) => {
				let responseJson = result;
				if (responseJson.data && responseJson.error === false) {
					localStorage.setItem('ud_fname', responseJson.data.first_name);
					localStorage.setItem('ud_lname', responseJson.data.last_name);
					localStorage.setItem('ud_mobile', responseJson.data.phone);
					localStorage.setItem('ud_email', responseJson.data.email);
					localStorage.setItem('ud_tid', responseJson.data.training_id);
					localStorage.setItem('ud_id', responseJson.data.id);
					localStorage.setItem('ud_token', responseJson.access_token);
					if(responseJson.data.image)
      					localStorage.setItem('ud_image',responseJson.data.image);
					//this.props.history.push('/dashboard')
					this.setState({ redirectToReferrer: true });
				} else if (responseJson.error === true && responseJson.uid) {
					notify.show('Please Verify Your Account', 'warning');
					this.setState({ userid: responseJson.uid });

				} else if (responseJson.error === true) {
					notify.show(responseJson.msg, 'error');

				}

			});

			// PostData('login',this.state).then((result) => {
			//  let responseJson = result;
			//  if(responseJson.data && responseJson.error===false){         
			// 	localStorage.setItem('ud_fname',responseJson.data.first_name);
			// 	localStorage.setItem('ud_lname',responseJson.data.last_name);
			// 	localStorage.setItem('ud_mobile',responseJson.data.phone);
			// 	localStorage.setItem('ud_email',responseJson.data.email);
			// 	localStorage.setItem('ud_tid',responseJson.data.training_id);
			// 	localStorage.setItem('ud_id',responseJson.data.id);
			// 	localStorage.setItem('ud_token',responseJson.access_token);
			// 	 this.setState({redirectToReferrer: true});
			//  }else  if(responseJson.error==true && responseJson.uid){
			// 	notify.show('Please Verify Your Account','warning');
			// 	this.setState({userid: responseJson.uid});

			//  }else  if(responseJson.error==true ){
			// 	notify.show(responseJson.msg,'error');

			//  }

			// });


		}

	}

	verifyaccount = (e) => {
		e.preventDefault();
		if (this.state.otp && this.state.userid) {
			post('signupotpverify', this.state).then((result) => {
				let responseJson = result;
				if (responseJson.data && responseJson.error === false) {
					localStorage.setItem('ud_fname', responseJson.data.first_name);
					localStorage.setItem('ud_lname', responseJson.data.last_name);
					localStorage.setItem('ud_mobile', responseJson.data.phone);
					localStorage.setItem('ud_email', responseJson.data.email);
					localStorage.setItem('ud_tid', responseJson.data.training_id);
					localStorage.setItem('ud_id', responseJson.data.id);
					localStorage.setItem('ud_token', responseJson.access_token);
					if(responseJson.data.image)
      					localStorage.setItem('ud_image',responseJson.data.image);
					this.setState({ redirectToReferrer: true });
					notify.show('Welcome to the Network of Professionals', 'success');
				} else if (responseJson.error === true) {
					notify.show(responseJson.msg, 'error');

				}

			});
		}

	}

	resendOtp = (e) => {
		e.preventDefault();
		if (this.state.userid) {
			post('resendsignupotp', {user_id:this.state.userid}).then((result) => {
				let responseJson = result;
				if (responseJson.msg && responseJson.error === false) {
					notify.show('OTP sent to your Registered Email ID '+this.state.email, 'success');
				} else if (responseJson.error === true) {
					notify.show(responseJson.msg, 'error');

				}

			});
		}

	}

	onChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	}




	render() {

		if (this.state.redirectToReferrer || localStorage.getItem('ud_token')) {
			window.location.href='/'
			//return (<Redirect to={'/'} />)
			//return (<Redirect to={window.location.pathname} />)
		}

		// if (localStorage.getItem('ud_token')) {
		// 	return (<Redirect to={'/'} />)
		// }

		return (
			<div className="loginpage">
				<div className="logo">

					<Link to='/'><img className="logo_img" src="/images/whitelogo.png" alt="Veriipro Learning" /></Link>
					<h1>WE ENSURE BETTER EDUCATION FOR A BETTER WORLD</h1>


					<form id="regForm" action="#" onSubmit={this.login} style={ (this.state.userid==='')? {display:'block'} :  {display:'none'} }>
						<h1>Login</h1>
						{/* <!-- One "tab" for each step in the form: --> */}
						<div className="tab">
							<p><input placeholder="Username..." type="email" name="email" onChange={this.onChange} required /></p>
							<p><input placeholder="Password..." name="password" type="password" onChange={this.onChange} required /></p>
						</div>
						{/* <!--     <p><input placeholder="Confirm Password" oninput="this.className = ''" name="pword" type="password" /></p> --> */}
						<div style={{ overflow: 'auto' }}>
							<div className="down_bottom">
								<button type="button" id="" className="ntvp"> <Link to="/signup" className="already_oelv" >New To VeriiPro</Link></button>
								<button type="submit" id="">Login</button>
								<Link to="/forgot"><button type="button" className="mybtn" >Forgot Password</button></Link>

							</div>
						</div>
					</form>

					<form id="otpForm" action="#" onSubmit={this.verifyaccount} style={ (this.state.userid!=='')?  {display:'block'}  :  {display:'none'} } >
						<h1>Verify Account</h1>
						{/* <!-- One "tab" for each step in the form: --> */}
						<div className="tab">
							<p><input placeholder="Enter OTP..." name="otp" type="password" onChange={this.onChange} required /></p>
						</div>
						{/* <!--     <p><input placeholder="Confirm Password" oninput="this.className = ''" name="pword" type="password" /></p> --> */}
						<div style={{ overflow: 'auto' }}>
							<div className="down_bottom">
							<button type="button" id="" className="ntvp" onClick={this.resendOtp}> <a href="#" className="already_oelv" >Resend OTP</a></button>
								<button type="submit" id="">Verify</button>

							</div>
						</div>
					</form>

				</div>

			</div>
		);
	}
}

export default Login;
