import React from 'react';
import { Link } from 'react-router-dom'

class CategoryMegaMenu extends React.Component {

    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {

    }

    render() {
        return (

            <ul className="nav navbar-nav">
                <li className="dropdown mega-dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <img style={{ width: 16 }} src="/images/grid.png" alt="" /> Category </a>
                    <ul className="dropdown-menu mega-dropdown-menu row">

                        <li className="col-sm-3">
                            <ul>
                                <li className="dropdown-header">New in Stores</li>
                                <div id="myCarousel" className="carousel slide" data-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="item active">
                                            <a href="#"><img style={{ width: '100%' }} src="/images/cloud.jpg" className="img-responsive" alt="product 1" /></a>
                                            <h4><small>React - The Complete Guide (incl Hooks, React Router, Redux)</small></h4>
                                            <button className="btn btn-primary" type="button">49,99 €</button>
                                            <button href="#" className="btn btn-default" type="button"><span className="glyphicon glyphicon-heart"></span> Add to Wishlist</button>
                                        </div>
                                        {/* <!-- End Item --> */}
                                        <div className="item">
                                            <a href="#"><img src="/images/graphic.jpg" className="img-responsive" alt="product 2" /></a>
                                            <h4><small>The Complete Android N Developer Course</small></h4>
                                            <button className="btn btn-primary" type="button">9,99 €</button>
                                            <button href="#" className="btn btn-default" type="button"><span className="glyphicon glyphicon-heart"></span> Add to Wishlist</button>
                                        </div>
                                        {/* <!-- End Item --> */}
                                        <div className="item">
                                            <a href="#"><img src="/images/python.png" className="img-responsive" alt="product 3" /></a>
                                            <h4><small>iOS 13 & Swift 5 - The Complete iOS App Development Bootcamp</small></h4>
                                            <button className="btn btn-primary" type="button">49,99 €</button>
                                            <button href="#" className="btn btn-default" type="button"><span className="glyphicon glyphicon-heart"></span> Add to Wishlist</button>
                                        </div>
                                        {/* <!-- End Item --> */}
                                    </div>
                                    {/* <!-- End Carousel Inner --> */}
                                </div>
                                {/* <!-- /.carousel --> */}
                            </ul>
                        </li>

                        <li className="col-sm-3">
                            <ul>
                                <li className="dropdown-header">Technology</li>
                                <li><a href="course_detail.html">Web Development</a></li>
                                <li><a href="course_detail.html">Software Development</a></li>
                                <li><a href="course_detail.html">Mobile Development</a></li>
                                <li><a href="course_detail.html">IT Help Desk</a></li>
                                <li><a href="course_detail.html">Database Management</a></li>
                                <li><a href="course_detail.html">Data Science</a></li>
                                <li><a href="course_detail.html"> Cloud Computing</a></li>
                                <li><a href="course_detail.html">Aartifical Intelligent</a></li>

                            </ul>
                        </li>

                        <li className="col-sm-3">

                            <ul><li className="dropdown-header">Business</li>
                                <li><a href="course_detail.html">Unique Features</a></li>
                                <li><a href="course_detail.html">Image Responsive</a></li>
                                <li><a href="course_detail.html">Auto Carousel</a></li>
                                <li><a href="course_detail.html">Newsletter Form</a></li>
                                <li><a href="course_detail.html">Four columns</a></li>
                            </ul>
                        </li>
                        <li className="col-sm-3">
                            <ul>
                                <li className="dropdown-header">Creative</li>
                                <li><a href="course_detail.html">Unique Features</a></li>
                                <li><a href="course_detail.html">Image Responsive</a></li>
                                <li><a href="course_detail.html">Auto Carousel</a></li>
                                <li><a href="course_detail.html">Newsletter Form</a></li>
                                <li><a href="course_detail.html">Four columns</a></li>

                            </ul>
                        </li>

                    </ul>
                </li>

                { (this.props.trainerPage===true)? null:
                <li className="searchbar">
                    <div className="search-container">
                        <form action="#"  onSubmit={this.props.search}>
                            <input type="text" placeholder="Search.."  required name='query'  onChange={this.props.onChange}  />
                            <button className="srcbtn" type="submit"><i style={{ padding: '7px 25px' }} className="fa fa-search"></i></button>
                        </form>
                    </div>
                </li>}

                <li className="add_video">
                    <img src="/images/addvideo.png" alt="" />
                </li>
            </ul>

        );
    }
}

export default CategoryMegaMenu;
