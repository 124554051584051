import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { post, DeviceId, DeviceType } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

class Signup extends React.Component {

    constructor() {
        super();
        this.state = {
          name: '',
          email: '',
          phone: '',
          password: '',
          agreement: '',
          deviceid: DeviceId(),
          devicetype: DeviceType(),
           redirectToReferrer: false,
          otp:'',
          userid:'',
        }

        this.verifyaccount = this.verifyaccount.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        // $('.mybtn').on('click', function(event) {
        //     event.preventDefault();
        //     var url = $(this).data('target');
        //     window.location.replace(url);
        // });
    }

    
    signup = (e) => {
      e.preventDefault();
      
      if(this.validate() ){
        post('register',this.state).then((result) => {
         let responseJson = result;
         
         if(responseJson.error==false && responseJson.uid){
          notify.show('Registered Successfully! Please Verify Your Account to get Access ','success');
          this.setState({userid: responseJson.uid});
  
         }else  if(responseJson.error==true ){
          notify.show(responseJson.msg,'error');
  
         }
         
        });
      }else{
        notify.show('Please Fill All the Required Fields','error');
       }
      
     }

   verifyaccount(e) {
     e.preventDefault();
    if(this.state.otp && this.state.userid){
      post('signupotpverify',this.state).then((result) => {
       let responseJson = result;
       if(responseJson.data && responseJson.error==false){         
        localStorage.setItem('ud_fname',responseJson.data.first_name);
        localStorage.setItem('ud_lname',responseJson.data.last_name);
        localStorage.setItem('ud_mobile',responseJson.data.phone);
        localStorage.setItem('ud_email',responseJson.data.email);
        localStorage.setItem('ud_tid',responseJson.data.training_id);
        localStorage.setItem('ud_id',responseJson.data.id);
        localStorage.setItem('ud_token',responseJson.access_token);

      if(responseJson.data.image)
      localStorage.setItem('ud_image',responseJson.data.image);
         this.setState({redirectToReferrer: true});
       }else  if(responseJson.error==true ){
        notify.show(responseJson.msg,'error');

       }
       
      });
    }
    
   }

   resendOtp = (e) => {
		e.preventDefault();
		if (this.state.userid) {
			post('resendsignupotp', {user_id:this.state.userid}).then((result) => {
				let responseJson = result;
				if (responseJson.msg && responseJson.error === false) {
					notify.show('OTP sent to your Registered Email ID '+this.state.email, 'success');
				} else if (responseJson.error === true) {
					notify.show(responseJson.msg, 'error');

				}

			});
		}

	}
  
onChange(e){
  this.setState({[e.target.name]:e.target.value});
 }

 validateAlphaOnly(e){
  var re = /^[a-zA-Z ]+$/;
  if (!re.test(e.key)) {
    e.preventDefault();
  }
 }

 validateMobile = (e)=>{
  var re = /^[0-9]+$/;
  if (!re.test(e.key) || this.state.phone.length>=10) {
    e.preventDefault();
  }
  
 }

 
 validate=()=>{
  var regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  var regexPhone = /^\d{10}$/;
  var regexName = /^(?=.{3,30}$)[a-zA-Z ]*$/;
  var regexPass = /^(?=.{8,20}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*/;

  if(!regexName.test(this.state.name)){
    notify.show("Enter a Valid Name", 'error');return false;
  }else if(!regexEmail.test(String(this.state.email).toLowerCase())){
      notify.show("Enter a Valid Email", 'error');return false;
  }else if(!regexPhone.test(String(this.state.phone).toLowerCase())){
      notify.show("Enter a Valid 10 Digit Phone No.", 'error');return false;
  }else if(!regexPass.test(this.state.password)){
      notify.show("Enter  8 Character Password Containing atleast 1 Uppercase 1 lowercase Alphabet and 1 Numeric", 'error');return false;
  }
  // else if(this.state.password!==this.state.cpassword){
  //     notify.show("Confirm Password Missmatch", 'error');return false;
  // }
  else if(!this.state.agreement){
      notify.show("Accept  Terms & Conditions", 'error');return false;
  }else{
      return true;
  }
}


    render() {

      if (this.state.redirectToReferrer) {
        return (<Redirect to={'/'}/>)
        }
       
        if(sessionStorage.getItem('userData')){
        return (<Redirect to={'/'}/>)
        }
        return (
            <div className="signuppage">
                <div className="logo">
  
                <Link to='/'><img className="logo_img" src="/images/whitelogo.png" alt="Veriipro Learning" /></Link>
  <h1>WE ENSURE BETTER EDUCATION FOR A BETTER WORLD</h1>
  
  <form id="regForm"  onSubmit={this.signup} style={ (this.state.userid=='')?  {display:'block'} :  {display:'none'} } >
    <h1>SignUp</h1>
    {/* <!-- One "tab" for each step in the form: --> */}
  
      <p><input placeholder="Full Name..."  name="name" minLength='3' maxLength='30'onKeyPress={this.validateAlphaOnly}  onChange={this.onChange} required className="single-input" /></p>
      <p><input placeholder="Phone..."  name="phone"  min='1000000000' max='9999999999' onKeyPress={this.validateMobile} onChange={this.onChange} required className="single-input-accent" /></p>

      <p><input placeholder="E-mail..."  name="email"  onChange={this.onChange} required className="single-input" /></p>
      <p><input placeholder="Password..."  name="password" minLength='8' maxLength='25'   onChange={this.onChange} required className="single-input-secondary" type="password" /></p>
  {/* <!--     <p><input placeholder="Confirm Password"  name="pword" type="password"></p> --> */}
    <div style={{overflow:'auto'}}>
      <div className="down_bottom">
       <span><input className="ottff_star" type="checkbox" name="agreement" value="Y" onChange={this.onChange} required className="pull-left" /></span>
        <span className="tnc">I agree VeriiPro <Link to="/terms-of-use"> Terms & Condition</Link>.</span>
              
      </div>
      <span>
      <button type="submit" id="nextBtn">Submit</button>
      </span>
      <span className="btl">
      <Link to="/login" className="already_oelv" >
        <i style={{fontSize:20,padding: '0px 10px 0px 0px',color: '#fff'}} class='fa fa-arrow-left'></i>Back To Login</Link>
      </span>
    </div>
    {/* <!-- Circles which indicates the steps of the form: --> */}
  </form>
    
  <form id="regForm" onSubmit={this.verifyaccount} style={ (this.state.userid!=='')?  {display:'block'} :  {display:'none'} } >
    <h1>Enter OTP received in your mailbox</h1>
    {/* <!-- One "tab" for each step in the form: --> */}
       <p><input placeholder="Enter OTP"  name="otp"  onChange={this.onChange} required className="single-input-secondary" type="password" />
      </p>
  {/* <!--     <p><input placeholder="Confirm Password"  name="pword" type="password"></p> --> */}
    <div style={{overflow:'auto'}}>
      <div className="down_bottom">
      <button type="button" id="" className="ntvp" onClick={this.resendOtp}> <a href="#" className="already_oelv" >Resend OTP</a></button>
               <button type="submit" id="nextBtn" >Verify</button>
      </div>
    </div>
    {/* <!-- Circles which indicates the steps of the form: --> */}
  </form>
  

  
  </div>
            </div>
        );
    }
}

export default Signup;
