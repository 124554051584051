import React from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom'
import Rating from 'react-rating';
import Header from './../../common/Header'
import Footer from './../../common/Footer'
import Breadcrumb from './../../common/Breadcrumb'
import CourseCardVerticle from './../../common/CourseCardVertical'
import { get } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

import FilterWidget from './../../common/FilterWidget';
import CourseCardHorizontal from './../../common/CourseCardHorizontal';

//const $ = window.$;

class TopTechnology extends React.Component {

  constructor() {
    super();
    this.state = {
      data:{
        title: '',
        top_course:[],
        featured_course:[],
        top_technologies:[]
      },
      courselist:[],
      refineparam:{
        learningfor :'',
        limit: 10,
        offset:0,
        sortby:'rate',
        level:[],
        language:[],
        pricingtype:[],
        rating:'',
        duration:[]

      },
      resultcount:0,
      appliedFilter:[]
  
    }
  }

  componentDidMount() {

    get('browsebytechnology', {tech_id:this.props.match.params.technologyId}).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ data: responseJson.data })
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

    var refineparam = {...this.state.refineparam}
    refineparam['technology'] = this.props.match.params.technologyId;
    this.setState({refineparam});
    this.getCourse(refineparam);
  }



  refineAction = (e,type) =>{

    var refineparam = {...this.state.refineparam}
    if(type=='text')
    refineparam[e.target.name] = e.target.value;
    else if(type=='array')
    refineparam[e.target.name] = e.target.value;


    this.setState({refineparam})
    this.getCourse(refineparam);
  }



  processRadioButton = (e,type, id) => {
    var refineparam = {...this.state.refineparam}
    
    var cbarray = document.getElementsByName(type);
    if(e.target.checked==true){
      refineparam[type] = id;
      for(var i = 0; i < cbarray.length; i++){
        if(cbarray[i]!=e.target)
            cbarray[i].checked = false;
            
      }
    }else{
      refineparam[type] = '';
    }

    this.setState({refineparam})
    this.getCourse(refineparam);
  }

  processCheckbox = (type, id) => {
    var refineparam = {...this.state.refineparam}
     var target = refineparam[type];
     
     if (target.includes(id)) {
         var index = target.indexOf(id);
         if (index !== -1)
             target.splice(index, 1);
         //this.setState({ ['cb_' + type + id]: true })
     } else {
         target.push(id)
        //this.setState({ ['cb_' + type + id]: false })
     }
     refineparam[type] = target;
     this.setState({refineparam})
     this.getCourse(refineparam);
 }

  saveCourse =  (series_id,action) => {
    const { courselist } = this.state;
    const { top_course } = this.state.data;

    let index = courselist.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      courselist[index]['saved'] = 0;
    }else if (action == 'add'){
      courselist[index]['saved'] = 1;
    }

     index = top_course.findIndex(x => x.series_id === series_id);
    if(index!==-1 && index!==null)
    if (action == 'remove'){
      top_course[index]['saved'] = 0;
    }else if (action == 'add'){
      top_course[index]['saved'] = 1;
    }
    this.setState({courselist});
 

    }




  // componentDidMount(){
  //   var refineparam = {...this.state.refineparam}
  //   refineparam['learningfor'] = this.props.match.params.categoryId;
  // this.getCourse(refineparam);
  // }


  componentDidUpdate(prevProps) {
    
    if (this.props.location !== prevProps.location) {
 
      var refineparam = { learningfor :'',
      limit: 10,
      offset:0,
      sortby:'rate',
      level:[],
      language:[],
      pricingtype:[],
      rating:'',
      duration:[]}
      refineparam['technology'] = this.props.match.params.technologyId;
      this.setState({refineparam})
      this.getCourse(refineparam);
    }
  }
  
  getCourse = (refineparam)=>{
    if(refineparam===undefined)
    refineparam =this.state.refineparam;
    
    get('getseries', refineparam ).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ courselist: responseJson.data, appliedFilter: responseJson.appliedFilter  })
        this.setState({ resultcount: responseJson.resultcount })
        this.setState({ browseoption: responseJson.browseoption })
        
      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }

  vpopClose=()=>{
    $("#video-popup-close, #video-popup-overlay").on('click', function(e) {
      $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
      $("#video-popup-iframe").attr('src', '');
    });
  }
  vpopOpen=()=>{
   $(".vpop").on('click', function (e) {
    $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

    var srchref='',autoplay='',id=$(this).data('id');
    if($(this).data('type') == 'vimeo') var srchref="https://player.vimeo.com/video/";
    else if($(this).data('type') == 'youtube') var srchref="https://www.youtube.com/embed/";

    if($(this).data('autoplay') == true) autoplay = '?autoplay=1';

    $("#video-popup-iframe").attr('src', srchref+id+autoplay);

    $("#video-popup-iframe").on('load', function() {
      $("#video-popup-container").show();
    });
  });

  }


  offsetChagne = (event,offset)=>{
    event.preventDefault();
     var refineparam = { ...this.state.refineparam }
     refineparam['offset'] = offset;
     this.setState({ refineparam })
     this.getCourse(refineparam);
     window.scrollTo(0, 1050)
   }

  render() {
    const { refineparam, resultcount } = this.state;
    let pagingItem = [];

    if (refineparam.offset > 0)
      pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset - 10)}>{'Prev'}</a></li>);

    for (let o = 0, p = 1; o <resultcount; o += 10, p++) {
      pagingItem.push(<li class={o=== refineparam.offset ?"page-item active":"page-item"}><a class="page-link" href={"#"+o} onClick={(e) => this.offsetChagne(e,o)}>{p}</a></li>);
    }

    if (refineparam.offset+10 < resultcount)
      pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset + 10)}>{'Next'}</a></li>);



    return (

      <>
        <Header />
        <div className="main-body">
          <div className="container-fluid">
            <div id="leftside" className="lside left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span><span className="side_txt">  Home </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span><span className="side_txt"> Trending </span> </a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span><span className="side_txt"> Subscription </span> </a> </li>
                  <li className="fourth active_side_bar"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span><span className="side_txt"> Course </span> </a> </li>
                  {/* <!--      <li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="leftside1" className="lsidee left_bar">
              <div className="side-bar">
                <ul className="left-side-bar">
                  <li className="first"><a href="index.html"> <span className="side_icon"><img src="/images/home.png" alt="" /> </span> </a> </li>
                  <li className="second"><a href=""> <span className="side_icon"><img src="/images/trending.png" alt="" /> </span></a> </li>
                  <li className="third"><a href=""> <span className="side_icon"><img src="/images/sub.png" alt="" /> </span></a> </li>
                  <li className="fourth active_side_bar"><a href="course.html"> <span className="side_icon"><img src="/images/course.png" alt="" /> </span></a> </li>
                  {/* <!--      <li className="fivth"></li> --> */}
                </ul>
              </div>

            </div>
            <div id="rightside" className="rside">
            <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:this.state.data.title}]} />
              <div className="body_main">

                <div className="main">
                  {/* <!-- <div id="myBtnContainer">
  <button className="btn active" onclick="filterSelection('all')"> Show all</button>
  <button className="btn" onclick="filterSelection('nature')"> Nature</button>
  <button className="btn" onclick="filterSelection('cars')"> Cars</button>
  <button className="btn" onclick="filterSelection('people')"> People</button>
</div> --> */}
                  <div className="development_title_banner">
                    <p>{this.state.data.title} Courses</p>
                  </div>


                  <div className="div_title">
                    <p>Trending {this.state.data.title} Courses</p>
                  </div>

                  {/* <!-- Portfolio Gallery Grid --> */}
                  <div className="row item">
                    <ul id="content-slider" className="content-slider">
                    {
      this.state.data.top_course.map((item,key)=>{
        return <li  className="column show"><CourseCardVerticle   vpopOpen={this.vpopOpen}  saveCourse={this.saveCourse}   key={key} item={item} /></li>
      })
    }
         </ul>

         {!this.state.data.top_course.length? <div className="nodata"><span>No data to display</span></div>:null}
                    {/* <!-- END GRID --> */}
                  </div>
               

                  {/* <!-- feature course--> */}

                  <div className="feature_course">

                    <div className="div_title">
                      <p>Feature {this.state.data.title} Courses</p>
                    </div>

                    <div className="container">
                      <div id="myCarousel" className="carousel slide" data-ride="carousel">
                        {/* <!-- Indicators --> */}
                        <ol className="carousel-indicators">
                          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                          <li data-target="#myCarousel" data-slide-to="1"></li>
                          <li data-target="#myCarousel" data-slide-to="2"></li>
                        </ol>

                        {/* <!-- Wrapper for slides --> */}
                        <div className="carousel-inner">
                          {
                             this.state.data.featured_course.map((item,key)=>{
                        return   <div key={key} className={ (key)? "item " : "item active"}>
                        <Link to={item.slug?"/course/"+item.slug:"/course/" + item.series_id}>
                              <div className="container">
                                <div className="col-md-3 col-lg-3">
                                <img src={item.series_image? item.image_url+item.series_image:"/images/cloud.jpg"} alt={item.series_title} style={{ width: '100%' }} />
                                </div>
                                <div className="col-md-9 col-lg-9">
                                  <div className="fc_top_title">
                                    <h3>{item.series_title}</h3>
                                    <h4> Last Updated {item.updated_on?item.updated_on: item.created_on}</h4>
                                  </div>
                                  <p>{item.series_sub_title}</p>
                                  <div className="fc_rating">
                                  <span className="">{item.series_duration+' '+item.series_duration_type}</span>
                                    <span className="">{item.series_lecture_count} lectures</span>
                                    <span className="">{(item.series_level=='1')? 'Beginner Level' : (item.series_level=='2')? 'Intermediate Level' : (item.series_level=='3')? 'Expert Level' : 'All Level' }</span>
                                    {/* <span className="">
                                      <i className="fa">&#xf005;</i>	<i className="fa">&#xf005;</i>
                                      <i className="fa">&#xf005;</i>	<i className="fa">&#xf005;</i>
                                      <i className="fa">&#xf123;</i>
                                    </span> */}
                                    <Rating emptySymbol="fa fa-star-o " fullSymbol="fa fa-star" readonly="true"  fractions={2} initialRating={item.series_rating}/>
                                    <span className="">{item.series_rating}</span>
                                    <span className="">({parseInt(item.series_ratedby)} ratings)</span>
                                    <div className="fc_btn">
                                      <span className="fc_price">{(item.series_type=='PAID')? '$ '+item.series_price : 'FREE '}</span>
                                      <Link to={item.slug?"/course/"+item.slug:"/course/" + item.series_id}> EXPLORE MORE</Link>
                                    </div>
                                  </div>

                                </div>
                              </div>
                            </Link>
                          </div>
                             })
                          }

                      </div>

                        {/* <!-- Left and right controls --> */}
                        {!this.state.data.featured_course.length? <div className="nodata"><span>No data to display</span></div>:
                       <> <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                          <i className="material-icons">&#xe314;</i>
                          <span className="sr-only">Previous</span>
                        </a>
                        <a className="right carousel-control" href="#myCarousel" data-slide="next">
                          <i className="material-icons">&#xe315;</i>
                          <span className="sr-only">Next</span>
                        </a>
  </>}
                      </div>
                    </div>



                  </div>

                  {/* <!--end of feature of course--> */}


                  {/* <!-- all development course--> */}
                  <div className="div_title">
                    <p>All {this.state.data.title} Courses</p>
                  </div>
                  <div className="al_dev_cs">
{/* 
                    <div className="dev_course_filter">

                      <div className="filt_btns">
                        <div className="dev_filetr cfcs fil_er_btn_to_show_all_filter"><span className="material-icons">		&#xe152;</span><span>Filter</span></div>
                        <div className="dev_rating cfcs fil_er_btn_to_show_all_filter_rate"><span>Ratings</span><span className="material-icons">	&#xe313;</span></div>
                        <div className="dev_duration cfcs fil_er_btn_to_show_all_filter_dur"><span>Duration</span><span className="material-icons">	&#xe313;</span></div>
                      </div>

                    </div>
 */}



                    {/* <!--filter--> */}
{/* 
                    <div className="frating_f_show_hide filter_block_none_rate">

                      <div className="rat_ing">

                        <table>
                          <tbody>
                            <tr><td><strong>Topics</strong></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="All" /><div className="rat_block">
                              <span>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                              </span>
                            </div>
                            </td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="PHP" /><div className="rat_block">
                              <span>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                              </span>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="JavaScript" /><div className="rat_block">
                              <span>
                                <i className="fa"></i>
                                <i className="fa"></i>
                                <i className="fa"></i>
                              </span>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="CSS3" /><div className="rat_block">
                              <span>
                                <i className="fa"></i>
                                <i className="fa"></i>
                              </span>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value=" Angular.js" /><div className="rat_block">
                              <span>
                                <i className="fa"></i>
                              </span>
                            </div></td></tr>
                          </tbody>
                        </table>

                      </div>

                    </div>

                    <div className="fdur_f_show_hide filter_block_none_duration">

                      <div className="rat_ing">

                        <table>
                          <tbody>
                            <tr><td><strong>Topics</strong></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="All" /><div className="ttc_block">
                              <p> 10 min(15+)</p>
                            </div>
                            </td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="PHP" /><div className="ttc_block">
                              <p>10-30 mins</p>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="JavaScript" /><div className="ttc_block">
                              <p>30-60 mins</p>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value="CSS3" /><div className="ttc_block">
                              <p>1-2 hours</p>
                            </div></td></tr>
                            <tr><td><input className="ottff_star" type="checkbox" name="Software" value=" Angular.js" /><div className="ttc_block">
                              <p>3+ Hours</p>
                            </div></td></tr>
                          </tbody>
                        </table>

                      </div>

                    </div>

                    <div className="fi_ter_wra_per_here filter_block_none">

                      <div className="fi_ter_op_en_here ">

                        <div className="filter_sof_ware">
                          <table>
                            <tbody>
                              <tr><td><strong>Topics</strong></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="PHP" /><p>PHP</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="JavaScript" /><p>JavaScript</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="CSS3" /><p>CSS3</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value=" Angular.js" /><p> Angular.js</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value=" Reach.js" /><p> Reach.js</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="Node.js" /><p>Node.js</p></td></tr>
                              <tr><td><input type="checkbox" name="Software" value="HTML5" /><p>HTML5</p></td></tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="filter_le_el_filter_ty_e">
                          <table>
                            <tbody>
                              <tr><td><strong>Level</strong></td></tr>
                              <tr><td><input type="checkbox" name="Level" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Level" value="Beginner" /><p>Beginner</p></td></tr>
                              <tr><td><input type="checkbox" name="Level" value="Imtermediate" /><p>Imtermediate</p></td></tr>
                              <tr><td><input type="checkbox" name="Level" value="Advanced" /><p>Advanced</p></td></tr>
                              <tr><td><strong>Language</strong></td></tr>
                              <tr><td><input type="checkbox" name="Type" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Type" value="English" /><p>English</p></td></tr>
                              <tr><td><input type="checkbox" name="Type" value="Hindi" /><p>Hindi</p></td></tr>
                              <tr><td><input type="checkbox" name="Type" value="Português" /><p>Português</p></td></tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="filter_pr_ce">
                          <table>
                            <tbody>
                              <tr><td><strong>Price</strong></td></tr>
                              <tr><td><input type="checkbox" name="Price" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Price" value="Paid" /><p>Paid</p></td></tr>
                              <tr><td><input type="checkbox" name="Price" value="Free" /><p>Free</p></td></tr>
                              <tr><td><strong>Features</strong></td></tr>
                              <tr><td><input type="checkbox" name="Features" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Features" value="Quizz" /><p>Paid</p></td></tr>
                              <tr><td><input type="checkbox" name="Features" value="Assessment" /><p>Free</p></td></tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="filter_t_t_c">
                          <table>
                            <tbody>
                              <tr><td><strong>Time To Complete</strong></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value="All" /><p>All</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value="< 10 min(15+)" /><p> 10 min(15+)</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value=" 10-30 mins" /> <p>10-30 mins</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value="30-60 mins" /><p>30-60 mins</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value=" 1-2 hours" /> <p>1-2 hours</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value=" 2-3 hours" /><p> 2-3 hours</p></td></tr>
                              <tr><td><input type="checkbox" name="Time_To_Complete" value="3+ Hours" /><p>3+ Hours</p></td></tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    */}
                    {/* <!--end of filter--> */}

                    <FilterWidget refineparam={this.state.refineparam} appliedFilter={this.state.appliedFilter} refineaction={this.refineAction} resultcount={this.state.resultcount} processCheckbox={this.processCheckbox} processRadioButton={this.processRadioButton} border={true} />

{
  this.state.courselist.map((item, key) => {
    return <CourseCardHorizontal   key={key} item={item}  saveCourse={this.saveCourse} />
    })
}
{
  ( this.state.courselist.length==0)? <h3 style={{textAlign:'center'}}>No Course To Display!</h3>: null
}

{
                        pagingItem.length ? <nav ariaLabel="Page navigation example">
                          <ul class="pagination">
                            {pagingItem.map((item) => {
                              return item;
                            })}
                          </ul>
                        </nav> : null
                      }


                  </div></div></div>

              {/* <!-- all development course --> */}

              <Footer />

              <div id="video-popup-overlay" onClick={this.vpopClose}></div>
<div id="video-popup-container">
  <div id="video-popup-close"  onClick={this.vpopClose} className="fade">&#10006;</div>
  <div id="video-popup-iframe-container">
    <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
  </div>
</div>

            </div>

          </div>
        </div>
      </>





    );
  }
}

export default TopTechnology;
