import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'

import VimeoUpload from 'vimeo-upload';
import EditLecture from './EditLecture';
import EditModule from "./EditModule";

import { get, post, put, deleteData, PostFiles } from './../../services/Apicall'
import * as Helper from './../../services/Helper';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';



class ManageCurriculum extends React.Component {

  constructor() {
    super();

    this.state = {
      progress:false,
      progressDone:0,
      progressTotal:0,
      series_id: '',
      module_name: '',
      module_objective: '',
      module_position: '',
      data: { modules: [] },

      lecture_title:'',
      lecture_description:'',
      lecture_article:'',
      lecture_resourceuri:'',

      module_name:'',
      module_objective:'',


      
      // data:{
      //   lecture_id:'',
      //   lecture_title:"",
      //   lecture_type:"",
      //   lecture_description:"",
      //   lecture_video:"",
      //   lecture_article:"",
      //   lecture_downloadable:0,
      //   lecture_resourceuri:'#',
      //   lecture_resources:[
      // 	{
      // 		id:1,
      // 		widget_type:'lecture',
      // 		widget_id:1,
      // 		resource_type:"file",
      // 		url:"",
      // 		url_title:"",
      // 		file:"file.txt",
      // 		file_size:"101",
      // 		file_name:"file.txt",
      // 		created_on:111,
      // 		updated_on:111
      // 	}
      // ],
      //   modules:[
      // 		{
      // 			module_name:"",
      // 			module_position:1,
      // 			module_content:[
      // 				{
      // 					conten_type:"lecture",
      // 					position:1,

      // 						id:1,
      // 						title:"",
      // 						description:"",
      // 						content_type:"V",
      // 						video:"",
      // 						article:"",
      // 						downloadable:0,
      // 						created_on:1554873664,
      // 						updated_on:1554873664,
      // 						resources:[
      // 							{
      // 								id:1,
      // 								widget_type:'lecture',
      // 								widget_id:1,
      // 								resource_type:"file",
      // 								url:"",
      // 								url_title:"",
      // 								file:"file.txt",
      // 								file_size:"101",
      // 								file_name:"file.txt",
      // 								created_on:111,
      // 								updated_on:111
      // 							}
      // 				momodule_iddule_id		]

      // 				}
      // 			]
      // 		}
      // 	],
      // 	enrolled:false,
      // }	



    };
    this.onChange = this.onChange.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    //this.createvideo = this.createvideo.bind(this)
    this.refresh = this.refresh.bind(this)
    this.publishseries = this.publishseries.bind(this)
    this.publishmodule = this.publishmodule.bind(this)
    //this.getDuration = this.getDuration.bind(this)

  } //end constructor


  getDuration = async (file) => {
    let videoNode = document.createElement("video");
    let promise = new Promise(function (resolve, reject) {
      videoNode.addEventListener("loadedmetadata", function () {
        resolve(videoNode.duration);
      });
      videoNode.addEventListener("error", function () {
        reject(videoNode.error.message + "(" + videoNode.error.code + ")");
      });
    });

    const URL = window.URL || window.webkitURL;
    videoNode.src = URL.createObjectURL(file);

    return promise;
  };



  componentDidMount() {
    var series_id = this.props.match.params.courseId;
    this.setState({ series_id: series_id });


    get('fetchcurriculum', { seriesid: this.props.match.params.courseId }).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error == false) {
        this.setState({ data: responseJson.data })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }



  addmodule(e, position) {
    var x = document.getElementsByClassName("addnewmodule");
    var y = document.getElementsByClassName("addnewmodulebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('addnewmodule_' + position).style.display = 'block';

  }


  closemodule(e, position) {
    var x = document.getElementsByClassName("addnewmodule");
    var y = document.getElementsByClassName("addnewmodulebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'block';
    }
    //document.getElementById('addnewmodule_'+position).style.display = 'block';

  }

  createmodule(e, position) {
    e.preventDefault();
    var name = this.state.module_name;
    var objective = this.state.module_objective;
    const formdata = { series_id: this.props.match.params.courseId, module_name: name, module_objective: objective, position: position };

    post('createmodule', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closemodule();
        this.componentDidMount();
        this.setState({module_name:'',module_objective:''});
        notify.show('Module Added Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }



  addlecture(e, position) {
    var x = document.getElementsByClassName("addnewlecture");
    var y = document.getElementsByClassName("addnewlecturebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('addnewlecture_' + position).style.display = 'block';

  }


  closelecture(e, position) {
    var x = document.getElementsByClassName("addnewlecture");
    var y = document.getElementsByClassName("addnewlecturebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'block';
    }
    //document.getElementById('addnewmodule_'+position).style.display = 'block';

  }

  createlecture(e, position, moduleid) {
    e.preventDefault();
    var title = this.state.lecture_title;
    var description = this.state.lecture_description;
    var sample = this.state.lecture_sample;
    const formdata = {
      series_id: this.props.match.params.courseId,
      title: title,
      description: description,
      module_id: moduleid,
      position: position,
      sample: sample,
    };

    post('createlecture', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closelecture();
        this.componentDidMount();
        this.setState({lecture_title:'',lecture_description:'',lecture_sample:0});
        notify.show('Lecture Added Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }


  addresource(e, position) {
    e.preventDefault();
    //var x = document.getElementsByClassName("addnewresource");
    var z = document.getElementsByClassName("addnewdownloadableresource");
    var y = document.getElementsByClassName("addnewresourcebtn");
    for (var i = 0; i < y.length; i++) {
      z[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('addnewresource_' + position).style.display = 'block';

  }


  closeresource(e, position) {
    var x = document.getElementsByClassName("addnewresource");
    var y = document.getElementsByClassName("addnewresourcebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'block';
    }
    //document.getElementById('addnewmodule_'+position).style.display = 'block';

  }

  createresource(e, position, moduleid, widget_id) {
    e.preventDefault();
    var title = this.state.resource_title;
    var description = this.state.resource_url;
    const formdata = {
      series_id: this.props.match.params.courseId,
      url_title: title,
      url: description,
      widget_id: widget_id,
      widget_type: 'lecture',
      resource_type: 'url',
      module_id: moduleid,
      position: position
    };

    post('addresource', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closeresource();
        this.componentDidMount();
        this.setState({resource_title:'',resource_url:''});
        notify.show('Resource Added Successfully!', 'success');
        this.setState({resource_url:'',resource_title:'' })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }


  adddownloadableresource(e, position) {
    e.preventDefault();
    // var x = document.getElementsByClassName("addnewresource");
    // //var y = document.getElementsByClassName("addnewdownloadableresourcebtn");
    // for (var i = 0; i < x.length; i++) {
    // 	x[i].style.display = 'none';
    // 	//y[i].style.display = 'none';
    // }
    var x = document.getElementsByClassName("addnewresource");
    //var y = document.getElementsByClassName("addnewresourcebtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      //y[i].style.display = 'block';
    }
    //this.closeresource()
    document.getElementById('addnewdownloadableresource_' + position).style.display = 'block';

  }


  closedownloadableresource(e, position) {
    var x = document.getElementsByClassName("addnewdownloadableresource");
    var y = document.getElementsByClassName("addnewresourcebtn");
    for (var i = 0; i < y.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'block';
    }
    //document.getElementById('addnewmodule_'+position).style.display = 'block';

  }

  createdownloadableresource(e, position, moduleid, widget_id) {
    e.preventDefault();
    var file = this.state.dresource_file;
    const formdata = {
      series_id: this.props.match.params.courseId,
      widget_id: widget_id,
      widget_type: 'lecture',
      resource_type: 'file',
      image_file: file,
      module_id: moduleid,
      position: position
    };

    PostFiles('addresource', formdata, 'POST').then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closedownloadableresource();
        this.componentDidMount();
        notify.show('Resource Uploaded Successfully!', 'success');
this.setState({dresource_file:null })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }


  addvideo(e, position) {
    var x = document.getElementsByClassName("addnewvideo");
    var y = document.getElementsByClassName("addnewvideobtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('addnewvideo_' + position).style.display = 'block';

  }

  addarticle(e, position) {
    var x = document.getElementsByClassName("addnewarticle");
    var y = document.getElementsByClassName("addnewvideobtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('addnewarticle_' + position).style.display = 'block';

  }


  closevideo(e, position) {
    var x = document.getElementsByClassName("addnewvideo");
    var z = document.getElementsByClassName("addnewarticle");
    var y = document.getElementsByClassName("addnewvideobtn");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      z[i].style.display = 'none';
      y[i].style.display = 'block';
    }
    //document.getElementById('addnewmodule_'+position).style.display = 'block';

  }

  updatearticle(e, position, moduleid, lid) {
    e.preventDefault();
    var article = this.state.article;
    var series_id = this.props.match.params.courseId;

      put('updatelecturearticle', { series_id: series_id, article: article, lid: lid ,moduleid:moduleid}).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closevideo();
        this.componentDidMount();
        notify.show('Article Updated Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }
   createvideo = async (e, position, moduleid, lid)=> {
    e.preventDefault();


    if (this.state.vimeo_video_file) {
      let file = this.state.vimeo_video_file;
      //let duration = this.state.vimeo_video_file.duration;
      let series_id = this.props.match.params.courseId;


      //var file2 = file;//.files[0];
      let duration = 0;
      duration = await this.getDuration(file).then((duration) => {
        this.setState({ duration: duration });
        return duration;
      });
      console.log(duration);
      //return false;
      document.getElementById('loading').style.display = 'block';

      var uploader = new VimeoUpload({
        file: file,
        //	token: 'f774725f543ad4eee9c24e663360c225',
        //token: '0732ea348e75008f1f9e73cb48ba914d',
        token: 'ef0e5c1b2d5ceb62aed80130ed489a1e',

        name: '',//'this.state.seriesdata.series_title',//document.getElementById('videoName').value,
        description: '',//'this.state.seriesdata.series_description', // document.getElementById('videoDescription').value,
        //private: true,
        //private: document.getElementById('make_private').checked,
        //upgrade_to_1080: document.getElementById('upgrade_to_1080').checked,
        onError: function (data) {
          notify.show('Error while Uploading Video ', 'error')
          this.setState({progress:false ,progressDone: 0, progressTotal:0,vimeo_video_file:null });
          //+ JSON.parse(data).error
        },
        onProgress: (data)=> {
          //updateProgress(data.loaded / data.total)
          this.setState({progress:(data.loaded/data.total)*100 ,progressDone: data.loaded, progressTotal:data.total })
        },
        onComplete:  (videoId, index) =>{
          
          if (videoId) {

            put('updatelecturevideo', { series_id: series_id, video_id: videoId, lid: lid, duration: duration }).then((result) => {
              let responseJson = result;
              if (responseJson.error == false) {
                document.getElementById('loading').style.display = 'none';
                this.closevideo();
                this.componentDidMount();
                //this.setState({loading:false});
                notify.show(responseJson.msg, 'success');
              } else {
                //this.setState({loading:false});

                document.getElementById('loading').style.display = 'none';
                notify.show('Something Went Wrong!', 'error');
              }
              this.setState({progress:false ,progressDone: 0, progressTotal:0,vimeo_video_file:null });
            });

          }

        }


      });

      uploader.upload();

    }
  }

  /********************************* */


  editlecture(e, position) {
    var x = document.getElementsByClassName("editlecture_btn");
    var y = document.getElementsByClassName("editlecture");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('editlecture_' + position).style.display = 'block';
    document.getElementById('lecturepanel_' + position).style.display = 'none';

  }

  editmodule(e, position) {
    var x = document.getElementsByClassName("editmodule_btn");
    var y = document.getElementsByClassName("editmodule");
    for (var i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
      y[i].style.display = 'none';
    }
    document.getElementById('editmodule_' + position).style.display = 'block';
    document.getElementById('modulepanel_' + position).style.display = 'none';

  }

  removeResource(e, sid, mid, lid, rid, rtype) {
    e.preventDefault();
    var file = this.state.dresource_file;
    const formdata = {
      series_id: this.props.match.params.courseId,
      widget_id: '',
      resource_id: rid,
      widget_type: 'lecture',
      resource_type: rtype,
      lecture_id: lid,
      module_id: mid

    };

    deleteData('removeresource', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.closedownloadableresource();
        this.componentDidMount();
        notify.show('Resource Removed Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }

  publishseries(e) {
    e.preventDefault();
    const formdata = {
      series_id: this.state.series_id,

    };

    put('publishseries', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.componentDidMount();
        notify.show('Published Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }
  
  unpublishseries=(e)=> {
    e.preventDefault();
    const formdata = {
      series_id: this.state.series_id,

    };

    put('unpublishseries', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.componentDidMount();
        notify.show('Published Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }

  publishmodule(e, mid) {
    e.preventDefault();
    const formdata = {
      series_id: this.state.series_id,
      module_id: mid,

    };

    put('publishmodule', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.componentDidMount();
        notify.show('Published Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }

  unpublishmodule=(e, mid)=> {
    e.preventDefault();
    const formdata = {
      series_id: this.state.series_id,
      module_id: mid,

    };

    put('unpublishmodule', formdata).then((result) => {
      let responseJson = result;
      if (responseJson.error == false) {

        this.componentDidMount();
        notify.show('Published Successfully!', 'success');


      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });

  }

  /********************************* */

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSampleToggle = (e) => {
    var sample = (/*this.state.lecture_sample*/ e.target.checked) ? 1 : 0;
    this.setState({ lecture_sample: sample });
  }

  onFileChange(e) {
    this.setState({ [e.target.name]: e.target.files[0] });
  }

  refresh() {
    this.componentDidMount()
  }


  render() {
    return (
            <>
        <Header  trainerPage={true} upload={true} progress={this.state.progress} progressDone={this.state.progressDone} progressTotal={this.state.progressTotal} />
        <Breadcrumb path={[{ link: '/', label: 'Home' }, { link: '', label: 'Trainer' }]} />

        <div className="main-body">
          <div className="container-fluid">

            <div className="design_new_course">

              <div className="dgnwcrs_tag_line">
                <div className="topic_tag">
                  <div className="btac ttl_tg_all">&#8735;</div>
                  <p className="aabtx ttl_tg_all"><Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>
                  <p className="crs_name ttl_tg_all">{this.state.data.series_title}</p>
                  {/* <p className="crs_sts ttl_tg_all">inactive</p> */}
                </div>

                <div className="topic_exit">
                  <Link to="/instructorportal/tips" target='_blank'>Tips <i className="fa fa-info-circle"></i></Link>
                </div>
              </div>

              <TrainerSidebar courseId={this.props.match.params.courseId} approveStatus={this.state.data.approved} publishStatus={this.state.data.published}  moduleExist={this.state.data.modules.length} unpublishseries={this.unpublishseries} publishseries={this.publishseries} />

              <div className="dnc_right_bar">


                <div id="Course_Content" className="tabcontent">

                  <div className="design_new_course_form">

                    <div className="container">

                      <div className="dnc_crs_sturtuce">

                        <div className="dnc_cs_section">

                          {/* <!-- start of course stucture-->  The very first add module/section  */}

                          <div className="dnc_section_cs">

                            <div className="dnc_top_add addnewmodulebtn" id={'addnewmodulebtn_' + '1'} title='Add New Section Here' onClick={(e) => this.addmodule(e, 1)}>
                              <span className="">&#43;</span>
                            </div>

                            <div className="dnc_middle_section addnewmodule" id={'addnewmodule_' + '1'} style={{ 'display': 'none' }}>

                              <div className="dnc_title_section_left">
                                <span> New Section : </span>
                              </div>

                              <div className="dnc_section_right " >
                                <form action="#" onSubmit={(e) => this.createmodule(e, 1)}>
                                  <button type='button' className='btn btn-sm btn-default pull-right closenewmodule hidden' title='Cancel' onClick={(e) => this.closemodule(e, 1)} id={'closenewmodule_' + '1'} > x </button>
                                  <div className="new_section_title">
                                    <div className="col-md-12 inputGroupContainer">
                                      <div className="input-group">
                                        <input placeholder="Section Title" className="form-control" type="text" maxLength='80' minLength='5' name="module_name" value={this.state.module_name} onChange={this.onChange} required />
                                      </div>
                                      <div className="form-control-counter" data-purpose="form-control-counter">{80-this.state.module_name.length}</div>
                                    </div>

                                    <span>What will students be able to do at the end of this section?</span>

                                    <div className="col-md-12 inputGroupContainer">
                                      <div className="input-group">
                                        <input placeholder="Section Objective" className="form-control" type="text" maxLength='200' minLength='10' name="module_objective" value={this.state.module_objective} onChange={this.onChange} required />
                                      </div>
                                      <div className="form-control-counter" data-purpose="form-control-counter">{200-this.state.module_objective.length}</div>

                                      <div className="new_section_title_btn">

                                        <button type="button" className="btn btn-lg btn-primary closenewmodule " onClick={(e) => this.closemodule(e, 1)} id={'closenewmodule_' + '1'} >Cancel</button>
                                        <button type="submit" className="btn btn-lg btn-primary">Add Section</button>

                                      </div>

                                    </div>

                                  </div>
                                </form>
                              </div>


                              <div className="dnc_top_bottom">
                                <span className="">&#43;</span>
                              </div>

                            </div>

                          </div>


                          {/* <!-- end of start of course stucture--> */}




                          {/* <!-- start of course stucture -2 -->   Module /Section Block Loop */}


                          {
                            this.state.data.modules.map((module, key) => {
                              return <>
                                <EditModule reload={this.refresh} sid={this.props.match.params.courseId} key2={key} mid={this.state.data.modules[key].module_id} mname={this.state.data.modules[key].module_name} mdesc={this.state.data.modules[key].module_objective} />


                                <div className="dnc_section_cs modulepanel" id={'modulepanel_' + key}>
                                <div className="main__modle_section">
                                  <p>Module {key + 1}: {module.module_name} 
                                  {
                                    !module.published?
                                    <button className="btn btn-xs btn-primary formbtnpub pull-right" onClick={(e)=>this.publishmodule(e,module.module_id)}>Publish</button>
                                  :<button className="btn btn-xs btn-danger formbtndan   pull-right" onClick={(e)=>this.unpublishmodule(e,module.module_id)}>Un Publish</button>}
                                  <i className='fa fa-edit pull-right c-pointer editmodule_btn' onClick={(e) => { this.editmodule(e, key) }} title='Edit Module Name & Description'>
                                    </i>
                                    </p>
                                    {/* VEry first add lecture of every lecture start here */}
                                  <div className="dnc_top_add addnewlecturebtn"  id={'addnewlecturebtn_' + '1' + key} title='Add New Lecture Here' onClick={(e) => this.addlecture(e, '1' + '' + key)}>
                                    <span className="">&#43;</span>
                                  </div>
                                    </div>




                            <div className="dnc_middle_section addnewlecture" id={'addnewlecture_' + '1' + key}  style={{ 'display': 'none' }}>

<div className="dnc_title_section_left">
  <span> New Lecture : </span>
</div>

<div className="dnc_section_right " >
  <form action="#"  onSubmit={(e) => this.createlecture(e, '1', module.module_id)} >
    <button type='button' className='btn btn-sm btn-default pull-right closenewlecture' title='Cancel' onClick={(e) => this.closelecture(e, '1' + key)} id={'closenewlecture_' + '1'}  > x </button>
    <div className="new_section_title">
      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <span>Mark as Sample </span> <input type="checkbox"  name="lecture_sample"  checked={ (this.state.lecture_sample)? true: false } onChange={this.onSampleToggle}  />
        </div>
      </div>

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <input type="text" maxLength='50' minLength='4' name="lecture_title" value={this.state.lecture_title} placeholder="Lecture Title" onChange={this.onChange} required className="form-control" />
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">{50-this.state.lecture_title.length}</div>
      </div>

      

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <textarea maxLength='255' minLength='4' name="lecture_description" value={this.state.lecture_description} placeholder="Lecture Description" onChange={this.onChange} required className="form-control" ></textarea>
         
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">{255-this.state.lecture_description.length}</div>

        <div className="new_section_title_btn">

          <button type="button" className="btn btn-lg btn-primary closenewlecture" onClick={(e) => this.closelecture(e, '1' + key)} id={'closenewlecture_' + '1'}  >Cancel</button>
          <button type="submit" className="btn btn-lg btn-primary">Add Lecture</button>

        </div>

      </div>

    </div>
  </form>
</div>

</div>



                                  {/* Very first add lecture of every lecture end here */}
                                  

{/* Lecture Loop start */}

                        {
                          module.module_content.map((content, key2) => {
                            return <>
                            <EditLecture reload={this.refresh} key2={key2} key1={key} sid={this.props.match.params.courseId} lid={this.state.data.modules[key].module_content[key2].id} mid={this.state.data.modules[key].module_id} lname={this.state.data.modules[key].module_content[key2].title} lsample={this.state.data.modules[key].module_content[key2].sample}  ldesc={this.state.data.modules[key].module_content[key2].description} />
                                  <div className="dnc_middle_section add_decs_res lecturepanel" id={'lecturepanel_' + (key2) + key}>
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <a data-toggle="collapse" data-parent={"#accordion" + key} href={"#collapse" + key + key2}> <span className="dnc_chapter">Chapter-{key2 + 1}</span></a>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>{content.title}</span>
                                      </div>
                                      <div className="dnc_chapter_edit  c-pointer  editlecture_btn" title="Edit this Lecture" onClick={(e) => { this.editlecture(e, key2 + '' + key) }}>
                                        <span>&#9998;</span>
                                      </div>
                                      {/* <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div> */}
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      {/* <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div> */}
                                      {/* <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">&#43;</span>

                                      </div> */}
                                    </div>


                                    <div id={"collapse" + key + key2} className={(key === 0 && key2 === 0) ? "dnc_add_new_des_and_res collapse in" : "dnc_add_new_des_and_res collapse"}>

<div className="final_video_section">

{
  (content.video) ?
  <div className="addnewvideobtn">

  <div className="left_sidefinal_view " id={'addnewvideobtn_' + (key + 2 + '' + key)} >
  <iframe src={'https://player.vimeo.com/video/' + content.video} width="300" height="100" className="" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </div>

  <div className="middle_sidefinal_view" style={{marginLeft: 250}}>

    <div className="dnc_final_time_crs">
      <span>&#128337;</span>
      <span>{Helper.sec2HrMin(content.duration)}</span>
    </div>

    <div className="dnc_final_edit_content_crs c-pointer" onClick={(e) => this.addvideo(e, (key2 + 2) + '' + key)}  title='Edit this Lectures Video Content'>
      <span>&#9998;</span>
      <span>Edit Content</span>
    </div>

    {/* <div className="dnc_final_upload_video_crs c-pointer"  title='Edit this Lectures Video Content'>
      <span className="fa">&#xf014;</span>
      <span>Replace with Video</span>
    </div> */}

  </div>
  </div>

  :
  (content.article) ?
  <div className="addnewvideobtn">
  <div className="left_sidefinal_view articleprev " id={'addnewvideobtn_' + (key + 2 + '' + key)} >
    {content.article}
    </div>
    <div className="middle_sidefinal_view" style={{marginLeft: 250}}>

    <div className="dnc_final_edit_content_crs c-pointer" onClick={(e) => this.addarticle(e, (key2 + 2) + '' + key)}  title='Edit this Article '>
      <span>&#9998;</span>
      <span>Edit Content</span>
    </div>

     <div className="dnc_final_upload_video_crs c-pointer"  onClick={(e) => this.addvideo(e, (key2 + 2) + '' + key)}   title='Replace Article with Video'>
      <span className="fa">&#xf014;</span>
      <span>Replace with Video</span>
    </div> 

  </div>
  </div>
    :
<>
  <div className="right_sidefinal_view">
    <div className=' dnc_final_priview addnewvideobtn' id={'addnewvideobtn_' + (key + 2 + '' + key)} onClick={(e) => this.addvideo(e, (key2 + 2) + '' + key)}> <span>+ Video</span></div>
  </div>
  <div className="right_sidefinal_view">
    <div className=' dnc_final_priview addnewvideobtn' id={'addnewvideobtn_' + (key + 2 + '' + key)} onClick={(e) => this.addarticle(e, (key2 + 2) + '' + key)}> <span>+ Article</span></div>
  </div>







  </>
}


<div className="dnc_two_box_add_des_and_res  addnewvideo" id={'addnewvideo_' + (key2 + 2) + key}  style={{'display': 'none' }} >


<div className="tab-content">
  <div className="tab-pane dnc_add_video_content_here active" >

  <form action="#" onSubmit={(e) => this.createvideo(e, key2 + 2, module.module_id, content.id)}>
    <div className="dnc_lec_des">
    <h4>Update Video File</h4>

      <div className="video_add_upload">
        <div className="file-uploader--file-selector--SGCns" data-purpose="">
        <input id="uploadFile" class="f-input" value={this.state.vimeo_video_file?this.state.vimeo_video_file.name:'Not Selected'} />
        <div class="fileUpload btn btn--browse">
            <span>Browse</span>
            <input id="uploadBtn" type='file' name="vimeo_video_file" accept="video/mp4,video/x-m4v,video/*" onChange={this.onFileChange} required  id="FileUploaderS3-0--8" />
        </div>
        </div>

        <span className="video_title video_note_line">
          <strong>Note:</strong> All files should be at least 720p and less than 4.0 GB.</span>
          
      </div>
      <div className="dnc_intro_btn">
            <button type="submit">Upload</button>
            <button type="button" className="closevideo"  onClick={(e) => this.closevideo(e, (key2 + 2))} id={'closevideo_' + (key2 + 2)} >cancel</button>
          </div>

    </div>
    </form>

  </div>
</div>

</div>


<div className="dnc_two_box_add_des_and_res  addnewarticle" id={'addnewarticle_' + (key2 + 2) + key} style={{'display': 'none' }} >


<div className="tab-content">

  <div className="tab-pane dnc_add_article_content_here active" >
  <form action="#" onSubmit={(e) => this.updatearticle(e, key2 + 2, module.module_id, content.id)}>
    <div className="dnc_sec">

      <div className="dnc_lec_des">

        <span className="lecture_desciption">Write your Article</span>

        <div className="lecture_desc_area">
          <textarea name="article"  onChange={this.onChange} required ></textarea>
          <div className="dnc_intro_btn">
            <button type="submit">save</button>
            <button type="button"  className="closevideo"  onClick={(e) => this.closevideo(e, (key2 + 2))} id={'closevideo_' + (key2 + 2)} >cancel</button>
          </div>
        </div>

      </div>

    </div>
    </form>

  </div>
</div>

</div>





</div>

<div className="dnc_final_desc">
{(content.description) ?
  <p className="dnc_final_desc_content">{content.description}</p> : <div className='dnc_chapter_content '> + Description</div>
}
</div>
{(content.resources.length) ?
<div className="dnc_final_desc">
  <h4>Resources</h4>
  {
		content.resources.map((resource, key3) => {
	 	  return   <p className="dnc_final_downloadable_link">

{
		(resource.resource_type == 'file') ?
				<a href={this.state.data.lecture_resourceuri + resource.file} target='_blank'><span className='fa fa-download'></span> <span> {resource.file_name}{(resource.file_size) ? '(' + resource.file_size + 'Kb)' : ''}  </span></a>
					:
      	<a href={(!/^(?:f|ht)tps?\:\/\//.test(resource.url)) ? 'http://' + resource.url : resource.url} target='_blank'> <span className='fa fa-external-link'></span><span>  {resource.url_title}</span> </a>
}
<i className='fa fa-trash pull-right c-pointer' onClick={(e) => this.removeResource(e, 'sid', module.module_id, content.id, resource.id, resource.resource_type)} title='Delete this Resource'></i>


  </p>
  })
}
</div>: ''}



<div className="dnc_final_download">
  <p className="dnc_final_add_resouce">

    <div className="dnc_chapter_content addnewresourcebtn" id={'addnewresourcebtn_' + (key2 + 2) + key} onClick={(e) => this.addresource(e, (key2 + 2) + '' + key)} >
      <span className="">&#43;</span>
      <span>Resource</span>

    </div>


    <div className="dnc_add_new_des_and_res addnewresource" id={'addnewresource_' + (key2 + 2) + key} style={{  'display': 'none' }} >

<div className="dnc_two_box_add_des_and_res">

  <ul className="nav nav-tabs tabs-left">

    <li role="presentation" className="dnc_space_here active">
      <div className="dnc_add_resc_here tablinks">
        <a href="#resc" aria-controls="resc">
          <span className="dnc_add_symb">&#43;</span>
          <span className="dnc_resc_section_here">
          Add External Resource
</span>
        </a>
      </div>
    </li>

    <li role="presentation" className="dnc_space_here">
      <div className="dnc_add_desc_here" >
        <a href="#" aria-controls="desc" className="addnewdownloadableresourcebtn" id={'addnewdownloadableresourcebtn_' + (key2 + 2) + key} onClick={(e) => this.adddownloadableresource(e, (key2 + 2) + '' + key)}>
          <span className="dnc_add_symb">&#43;</span>
          <span className="dnc_desc_section_here">
          Add Downloadable File 
</span></a>
      </div>
    </li>

  </ul>

  <div className="tab-content">
  
    <div  className="tab-pane dnc_add_resc_here active" id="desc">

      <div className="dnc_sec">

        <div className="">

          <span className="lecture_desciption">External Resourse</span>

          <div className="lecture_desc_area">
          <form action="#" onSubmit={(e) => this.createresource(e, key2 + 2, module.module_id, content.id)}>
            <div className="col-md-12 inputGroupContainer">
              <div className="input-group">
                <input type="text" maxLength='30' minLength='4' name="resource_title" value={this.resource_title} placeholder="URL Title" onChange={this.onChange} required  className="form-control" type="text" />
              </div>
            </div>

            <div className="col-md-12 inputGroupContainer">
              <div className="input-group">
                <input  maxLength='255' minLength='4' name="resource_url" value={this.resource_url} placeholder="External URL" onChange={this.onChange} required  className="form-control" type="text" />
              </div>
            </div>

            <button type="button" className="btn btn-lg btn-primary closenewresource" onClick={(e) => this.closeresource(e, (key2 + 2))} id={'closenewresource_' + (key2 + 2)} >Cancel</button>
            <button type="submit" className="btn btn-lg btn-primary">Add URL</button>
          </form>
          </div>

        </div>

      </div>

    </div>
  </div>

</div>

</div>


    


    <div className="dnc_add_new_des_and_res addnewdownloadableresource" id={'addnewdownloadableresource_' + (key2 + 2) + key} style={{  'display': 'none' }} >

<div className="dnc_two_box_add_des_and_res">

  <ul className="nav nav-tabs tabs-left">

    <li role="presentation" className="dnc_space_here ">
      <div className="dnc_add_resc_here tablinks">
        <a href="#" aria-controls="resc" className='addnewdownloadableresourcebtn' id={'addnewdownloadableresourcebtn_' + (key2 + 2) + key} onClick={(e) => this.addresource(e, (key2 + 2) + '' + key)} >
          <span className="dnc_add_symb">&#43;</span>
          <span className="dnc_resc_section_here">
          Add External Resource
</span>
        </a>
      </div>
    </li>

    <li role="presentation" className="dnc_space_here active">
      <div className="dnc_add_desc_here" >
        <a href="#desc" aria-controls="desc" >
          <span className="dnc_add_symb">&#43;</span>
          <span className="dnc_desc_section_here">
          Add Downloadable File 
</span></a>
      </div>
    </li>

  </ul>

  <div className="tab-content">
  
    <div  className="tab-pane dnc_add_resc_here active" id="desc">

      <div className="dnc_sec">

        <div className="">

          <span className="lecture_desciption">Downloadable File</span>

          <div className="lecture_desc_area">
          <form action="#"  onSubmit={(e) => this.createdownloadableresource(e, key2 + 2, module.module_id, content.id)}>
            <div className="col-md-12 inputGroupContainer">
              <div className="input-group">
                <input name="dresource_file" onChange={this.onFileChange} required className="form-control" type="file" />
              </div>
            </div>
            <button type="button" className="btn btn-lg btn-primary closedownloadablenewresource"  onClick={(e) => this.closedownloadableresource(e, (key2 + 2))} id={'closenewresource_' + (key2 + 2)} >Cancel</button>
            <button type="submit" className="btn btn-lg btn-primary">Upload</button>
            </form>

          </div>

        </div>

      </div>

    </div>
  </div>

</div>

</div>


    

  </p>
</div>

</div>

   {/*  add lecture  below alreadyh exist lecture  start*/}
   <div className="dnc_top_bottom addnewlecturebtn" id={'addnewlecturebtn_' + (key + 2) + key} title='Add New Lecture' onClick={(e) => this.addlecture(e, (key2 + 2) + '' + key)}>
                                    <span className="">&#43;</span>
                                  </div>
                                   
                                  </div>



                       


                            <div className="dnc_middle_section addnewlecture" id={'addnewlecture_' + (key2 + 2) + key}  style={{ 'display': 'none' }}>

<div className="dnc_title_section_left">
  <span> New Lecture : </span>
</div>

<div className="dnc_section_right " >
  <form action="#"  onSubmit={(e) => this.createlecture(e, (key2 + 2), module.module_id)} >
    <button type='button' className='btn btn-sm btn-default pull-right closenewlecture' title='Cancel' onClick={(e) => this.closelecture(e, (key2 + 2) + key)} id={'closenewlecture_' + (key2 + 2)}  > x </button>
    <div className="new_section_title">
      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <span>Mark as Sample </span> <input type="checkbox"  name="lecture_sample"  checked={ (this.state.lecture_sample)? true: false } onChange={this.onSampleToggle}  />
        </div>
      </div>

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <input type="text" maxLength='50' minLength='4' name="lecture_title" value={this.state.lecture_title} placeholder="Lecture Title" onChange={this.onChange} required className="form-control" />
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">{50-this.state.lecture_title.length}</div>
      </div>

      

      <div className="col-md-12 inputGroupContainer">
        <div className="input-group">
        <textarea maxLength='255' minLength='4' name="lecture_description" value={this.state.lecture_description} placeholder="Lecture Description" onChange={this.onChange} required className="form-control" ></textarea>
         
        </div>
        <div className="form-control-counter" data-purpose="form-control-counter">{255-this.state.lecture_description.length}</div>

        <div className="new_section_title_btn">

          <button type="button" className="btn btn-lg btn-primary closenewlecture" onClick={(e) => this.closelecture(e,(key2 + 2) + key)} id={'closenewlecture_' + (key2 + 2)}  >Cancel</button>
          <button type="submit" className="btn btn-lg btn-primary">Add Lecture</button>

        </div>

      </div>

    </div>
  </form>
</div>

</div>


                          {/*  add lecture  below alreadyh exist lecture  end*/}
                                </>
                          })
                        }
{/* Lecture Loop end */}


                                </div>


                          {/*  add module/section  below alreadyh exist module */}

                          <div className="dnc_section_cs">

<div className="dnc_top_add addnewmodulebtn" id={'addnewmodulebtn_' + (key + 2)} title='Add New Section Here' onClick={(e) => this.addmodule(e, (key + 2))}>
  <span className="">&#43;</span>
</div>

<div className="dnc_middle_section addnewmodule" id={'addnewmodule_' + (key + 2)} style={{ 'display': 'none' }}>

  <div className="dnc_title_section_left">
    <span> New Section : </span>
  </div>

  <div className="dnc_section_right " >
    <form action="#" onSubmit={(e) => this.createmodule(e, (key + 2))}>
      <button type='button' className='btn btn-sm btn-default pull-right closenewmodule hidden' title='Cancel' onClick={(e) => this.closemodule(e, (key + 2))} id={'closenewmodule_' + (key + 2)} > x </button>
      <div className="new_section_title">
        <div className="col-md-12 inputGroupContainer">
          <div className="input-group">
            <input placeholder="Section Title" className="form-control" type="text" maxLength='80' minLength='5' name="module_name" value={this.module_name} onChange={this.onChange} required />
          </div>
          <div className="form-control-counter" data-purpose="form-control-counter">{80-this.state.module_name.length}</div>
        </div>

        <span>What will students be able to do at the end of this section?</span>

        <div className="col-md-12 inputGroupContainer">
          <div className="input-group">
            <input placeholder="Section Objective" className="form-control" type="text" maxLength='200' minLength='10' name="module_objective" value={this.module_objective} onChange={this.onChange} required />
          </div>
          <div className="form-control-counter" data-purpose="form-control-counter">{200-this.state.module_objective.length}</div>

          <div className="new_section_title_btn">

            <button type="button" className="btn btn-lg btn-primary closenewmodule " onClick={(e) => this.closemodule(e, (key + 2))} id={'closenewmodule_' + (key + 2)} >Cancel</button>
            <button type="submit" className="btn btn-lg btn-primary">Add Section</button>

          </div>

        </div>

      </div>
    </form>
  </div>


  <div className="dnc_top_bottom">
    <span className="">&#43;</span>
  </div>

</div>

</div>


{/* <!-- end of start of course stucture--> */}


                                </>
                              })
                        }

                                {/* <!-- end of start of course stucture -2-->  Module /Section Block Loop */}



<div style={{display:'none'}} >




                                {/* <!-- final of course stucture 5--> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_middle_section add_decs_res">
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <span className="dnc_chapter">Chapter-1</span>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>Introduction</span>
                                      </div>
                                      <div className="dnc_chapter_edit">
                                        <span>&#9998;</span>
                                      </div>
                                      <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div>
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div>
                                      <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">&#43;</span>

                                      </div>
                                    </div>
                                    <div className="dnc_add_new_des_and_res">

                                      <div className="final_video_section">

                                        <div className="left_sidefinal_view">
                                          <img src="https://cdn2.iconfinder.com/data/icons/vlogger-and-video-platform-filled-outline/64/report-video-website-512.png" alt="" />
                                        </div>

                                        <div className="middle_sidefinal_view">

                                          <div className="dnc_final_time_crs">
                                            <span>&#128337;</span>
                                            <span>05:20</span>
                                          </div>

                                          <div className="dnc_final_edit_content_crs">
                                            <span>&#9998;</span>
                                            <span>Edit Content</span>
                                          </div>

                                          <div className="dnc_final_upload_video_crs">
                                            <span className="fa">&#xf014;</span>
                                            <span>Replace with Video</span>
                                          </div>

                                        </div>

                                        <div className="right_sidefinal_view">
                                          <div className="dnc_final_priview">
                                            <span>Preview</span>
                                            <span>&#10224;</span>
                                          </div>
                                        </div>

                                      </div>

                                      <div className="dnc_final_desc">
                                        <p className="dnc_final_desc_content">

                                          You’ve just stumbled upon the most complete, in-depth Java programming course online. With over 260,000 students enrolled and tens of thousands of 5 star reviews to date, these comprehensive java tutorials cover everything you’ll ever need.
                                          
</p>
                                      </div>

                                      <div className="dnc_final_desc">
                                        <h4>Downloadable Material</h4>
                                        <p className="dnc_final_downloadable_link">
                                          <span className="fa">&#xf019;</span>
                                          <span> screencapture-colorlib-preview-theme-joblisting-single-html-2019-12-20-09_23_05.png (391.9 kB)</span>

                                        </p>
                                      </div>

                                      <div className="dnc_final_desc">
                                        <h4>External Resource</h4>
                                        <p className="dnc_final_extrnal_source">
                                          <span className="fa">&#xf045;</span>
                                          <span> Veriipro – A Job Portal with Verified Professionals</span>

                                        </p>
                                      </div>

                                      <div className="dnc_final_download">
                                        <p className="dnc_final_add_resouce">

                                          <div className="dnc_chapter_content">
                                            <span className="">&#43;</span>
                                            <span>Resource</span>

                                          </div>

                                        </p>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- end of final of course stucture 5--> */}



                                {/* <!-- start of course stucture -2 --> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_middle_section add_decs_res">
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <span className="dnc_chapter">Chapter-1</span>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>Introduction</span>
                                      </div>
                                      <div className="dnc_chapter_edit">
                                        <span>&#9998;</span>
                                      </div>
                                      <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div>
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div>
                                      <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">&#43;</span>

                                      </div>
                                    </div>
                                    <div className="dnc_add_new_des_and_res">

                                      <div className="dnc_two_box_add_des_and_res">

                                        <div className="dnc_add_resc_here">

                                          <span className="dnc_add_symb">&#43;</span>
                                          <span className="dnc_resc_section_here">Resources</span>

                                        </div>

                                        <div className="dnc_add_desc_here">

                                          <span className="dnc_add_symb">&#43;</span>
                                          <span className="dnc_desc_section_here">Description</span>

                                        </div>

                                      </div>

                                    </div>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- end of start of course stucture -2--> */}



                                {/* <!-- start of course stucture -3 --> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_middle_section add_decs_res">
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <span className="dnc_chapter">Chapter-1</span>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>Introduction</span>
                                      </div>
                                      <div className="dnc_chapter_edit">
                                        <span>&#9998;</span>
                                      </div>
                                      <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div>
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div>
                                      <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">&#43;</span>

                                      </div>
                                    </div>
                                    <div className="dnc_add_new_des_and_res">

                                      <div className="dnc_two_box_add_des_and_res">

                                        <ul className="nav nav-tabs tabs-left" role="tablist">

                                          <li role="presentation" className="dnc_space_here active">
                                            <div className="dnc_add_resc_here tablinks">
                                              <a href="#resc" aria-controls="resc" role="tab" data-toggle="tab">
                                                <span className="dnc_add_symb">&#43;</span>
                                                <span className="dnc_resc_section_here">
                                                  Description
  </span>
                                              </a>
                                            </div>
                                          </li>

                                          <li role="presentation" className="dnc_space_here">
                                            <div className="dnc_add_desc_here" >
                                              <a href="#desc" aria-controls="desc" role="tab" data-toggle="tab">
                                                <span className="dnc_add_symb">&#43;</span>
                                                <span className="dnc_desc_section_here">
                                                  Resources
    </span></a>
                                            </div>
                                          </li>

                                        </ul>

                                        <div className="tab-content">
                                          <div role="tabpanel" className="tab-pane dnc_add_desc_here active" id="resc">

                                            <div className="dnc_sec">

                                              <div className="dnc_lec_des">

                                                <span className="lecture_desciption">Lecture Description</span>

                                                <div className="lecture_desc_area">
                                                  <textarea name=""></textarea>
                                                  <div className="dnc_intro_btn">
                                                    <button type="submit">save</button>
                                                    <button type="submit">cancel</button>
                                                  </div>
                                                </div>

                                              </div>

                                            </div>

                                          </div>
                                          <div role="tabpanel" className="tab-pane dnc_add_resc_here" id="desc">

                                            <div className="dnc_sec">

                                              <div className="dnc_lec_des">

                                                <span className="lecture_desciption">Downloadable File</span>

                                                <div className="lecture_desc_area">
                                                  <div className="file-uploader--file-selector--SGCns" data-purpose="">
                                                    <label for="FileUploaderS3-0--8">
                                                      <span className="input-group">
                                                        <input readonly="" type="text" className="form-control" value="No file selected" />
                                                        <span className="input-group-btn">
                                                          <button type="button" className="btn btn-default">Select file</button>
                                                        </span>
                                                      </span>
                                                    </label>
                                                    <input accept=".gif,.jpg,.jpeg,.png" type="file" id="FileUploaderS3-0--8" className="" />
                                                  </div>
                                                </div>

                                              </div>


                                              <div className="dnc_lec_des">

                                                <span className="lecture_desciption">External Resourse</span>

                                                <div className="lecture_desc_area">
                                                  <div className="col-md-12 inputGroupContainer">
                                                    <div className="input-group">
                                                      <input name="Course Tittle" placeholder="Title" className="form-control" type="text" />
                                                    </div>
                                                  </div>

                                                  <div className="col-md-12 inputGroupContainer">
                                                    <div className="input-group">
                                                      <input name="Course Tittle" placeholder="URL" className="form-control" type="text" />
                                                    </div>
                                                  </div>

                                                </div>

                                              </div>

                                            </div>

                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- end of start of course stucture -3 --> */}


                                {/* <!--start of course stucture -4 --> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_middle_section add_decs_res">
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <span className="dnc_chapter">Chapter-1</span>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>Introduction</span>
                                      </div>
                                      <div className="dnc_chapter_edit">
                                        <span>&#9998;</span>
                                      </div>
                                      <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div>
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div>
                                      <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">-</span>

                                      </div>
                                    </div>
                                    <div className="dnc_add_new_content">

                                      <div className="dnc_add_new_content_desc">

                                        <p></p>

                                      </div>


                                      <div className="dnc_two_box_add_des_and_res">

                                        <ul className="nav nav-tabs tabs-left" role="tablist">

                                          <li role="presentation" className="dnc_space_here active">
                                            <div className="dnc_add_video_content_here tablinks">
                                              <a href="#addvideo" aria-controls="addvideo" role="tab" data-toggle="tab">
                                                <span className="dnc_add_symb">&#43;</span>
                                                <span className="dnc_resc_section_here">
                                                  Video
  </span>
                                              </a>
                                            </div>
                                          </li>

                                          <li role="presentation" className="dnc_space_here">
                                            <div className="dnc_add_article_content_here" >
                                              <a href="#addarticle" aria-controls="addarticle" role="tab" data-toggle="tab">
                                                <span className="dnc_add_symb">&#43;</span>
                                                <span className="dnc_desc_section_here">
                                                  Article
    </span></a>
                                            </div>
                                          </li>

                                        </ul>

                                        <div className="tab-content">
                                          <div role="tabpanel" className="tab-pane dnc_add_video_content_here active" id="addvideo">

                                            <div className="dnc_lec_des">


                                              <div className="video_add_upload">
                                                <div className="file-uploader--file-selector--SGCns" data-purpose="">
                                                  <label for="FileUploaderS3-0--8">
                                                    <span className="input-group">
                                                      <input readonly="" type="text" className="form-control" value="No file selected" />
                                                      <span className="input-group-btn">
                                                        <button type="button" className="btn btn-default">Select file</button>
                                                      </span>
                                                    </span>
                                                  </label>
                                                  <input accept=".gif,.jpg,.jpeg,.png" type="file" id="FileUploaderS3-0--8" className="" />
                                                </div>

                                                <span className="video_title">
                                                  <strong>Note:</strong> All files should be at least 720p and less than 4.0 GB.</span>
                                              </div>

                                            </div>

                                          </div>
                                          <div role="tabpanel" className="tab-pane dnc_add_article_content_here" id="addarticle">

                                            <div className="dnc_sec">

                                              <div className="dnc_lec_des">

                                                <span className="lecture_desciption">Write your Article</span>

                                                <div className="lecture_desc_area">
                                                  <textarea name=""></textarea>
                                                  <div className="dnc_intro_btn">
                                                    <button type="submit">save</button>
                                                    <button type="submit">cancel</button>
                                                  </div>
                                                </div>

                                              </div>

                                            </div>

                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- end of start of course stucture -4 --> */}


                                {/* <!-- final of course stucture 5--> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_middle_section add_decs_res">
                                    <div className="dn_cs_top_section">
                                      <div className="dnc_chapter_number">
                                        <span className="dnc_tick">&#9745;</span>
                                        <span className="dnc_chapter">Chapter-1</span>
                                      </div>
                                      <div className="dnc_chapter_name">
                                        <span className="fa">&#xf15c;</span>
                                        <span>Introduction</span>
                                      </div>
                                      <div className="dnc_chapter_edit">
                                        <span>&#9998;</span>
                                      </div>
                                      <div className="dnc_chapter_delete">
                                        <span className="fa">&#xf014;</span>
                                      </div>
                                      <div className="dnc_chapter_swap">
                                        <span>&#9776;</span>
                                      </div>
                                      <div className="dnc_chapter_drop">
                                        <span className="drrooopppp">&#10506;</span>
                                      </div>
                                      <div className="dnc_chapter_content">
                                        <span>Content</span>
                                        <span className="">&#43;</span>

                                      </div>
                                    </div>
                                    <div className="dnc_add_new_des_and_res">

                                      <div className="final_video_section">

                                        <div className="left_sidefinal_view">
                                          <img src="https://cdn2.iconfinder.com/data/icons/vlogger-and-video-platform-filled-outline/64/report-video-website-512.png" alt="" />
                                        </div>

                                        <div className="middle_sidefinal_view">

                                          <div className="dnc_final_time_crs">
                                            <span>&#128337;</span>
                                            <span>05:20</span>
                                          </div>

                                          <div className="dnc_final_edit_content_crs">
                                            <span>&#9998;</span>
                                            <span>Edit Content</span>
                                          </div>

                                          <div className="dnc_final_upload_video_crs">
                                            <span className="fa">&#xf014;</span>
                                            <span>Replace with Video</span>
                                          </div>

                                        </div>

                                        <div className="right_sidefinal_view">
                                          <div className="dnc_final_priview">
                                            <span>Preview</span>
                                            <span>&#10224;</span>
                                          </div>
                                        </div>

                                      </div>

                                      <div className="dnc_final_desc">
                                        <p className="dnc_final_desc_content">

                                          You’ve just stumbled upon the most complete, in-depth Java programming course online. With over 260,000 students enrolled and tens of thousands of 5 star reviews to date, these comprehensive java tutorials cover everything you’ll ever need.
                                      
  </p>
                                      </div>

                                      <div className="dnc_final_desc">
                                        <h4>Downloadable Material</h4>
                                        <p className="dnc_final_downloadable_link">
                                          <span className="fa">&#xf019;</span>
                                          <span> screencapture-colorlib-preview-theme-joblisting-single-html-2019-12-20-09_23_05.png (391.9 kB)</span>

                                        </p>
                                      </div>

                                      <div className="dnc_final_desc">
                                        <h4>External Resource</h4>
                                        <p className="dnc_final_extrnal_source">
                                          <span className="fa">&#xf045;</span>
                                          <span> Veriipro – A Job Portal with Verified Professionals</span>

                                        </p>
                                      </div>

                                      <div className="dnc_final_download">
                                        <p className="dnc_final_add_resouce">

                                          <div className="dnc_chapter_content">
                                            <span className="">&#43;</span>
                                            <span>Resource</span>

                                          </div>

                                        </p>
                                      </div>

                                    </div>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- end of final of course stucture 5--> */}


                                {/* <!-- unpublish section--> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>


                                {/* <!-- unpublish section  --> */}

                                {/* <!-- unpublish section--> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>
                                  <div className="dnc_middle_section">

                                    <div className="dnc_title_section_left">
                                      <span> New Section : </span>
                                    </div>

                                    <div className="dnc_section_right">
                                      <div className="new_section_title">
                                        <div className="col-md-12 inputGroupContainer">
                                          <div className="input-group">
                                            <input name="Course Sub-Tittle" placeholder="Course Sub-Tittle" className="form-control" type="text" />
                                          </div>
                                          <div className="form-control-counter" data-purpose="form-control-counter">60</div>
                                        </div>

                                        <span>What will students be able to do at the end of this section?</span>

                                        <div className="col-md-12 inputGroupContainer">
                                          <div className="input-group">
                                            <input name="Course Sub-Tittle" placeholder="Course Sub-Tittle" className="form-control" type="text" />
                                          </div>
                                          <div className="form-control-counter" data-purpose="form-control-counter">60</div>

                                          <div className="new_section_title_btn">

                                            <button type="button" className="btn btn-lg btn-primary">Cancel</button>
                                            <button type="button" className="btn btn-lg btn-primary">Add Section</button>

                                          </div>

                                        </div>

                                      </div>
                                    </div>


                                    <div className="dnc_top_bottom">
                                      <span className="">&#43;</span>
                                    </div>

                                  </div>

                                </div>
                                {/* <!-- unpublish section  --> */}


                                {/* <!-- unpublish section--> */}

                                <div className="dnc_section_cs">

                                  <div className="dnc_top_add">
                                    <span className="">&#43;</span>
                                  </div>

                                  <div className="final_unpublish_section">
                                    <p> <span className="unpu_sec">Unplublished Section :</span>
                                      <span className="fa unpub_note">&#xf15c;</span>
                                      <span className="unpub_title">Title :</span>
                                      <span className="unpub_desc">Course Describtion Here</span>
                                      <span className="unpub_edit">&#9998;</span>
                                      <span className="fa unpub_del">&#xf014;</span>
                                      <span className="fa unpub_swap">&#9776;</span>
                                    </p>
                                  </div>

                                  <div className="dnc_top_bottom">
                                    <span className="">&#43;</span>
                                  </div>

                                </div>

                                {/* <!-- unpublish section  --> */}

</div>

                              </div>

</div>
{/* <div className="continue_btn">
                            <button type="button" className="btn btn-lg btn-primary">Save and continue</button>
                          </div> */}
                        </div>
                        {/* <!-- /.container --> */}

                      </div>

                    </div>


                  </div>


                </div>

              </div>
              {/* <!-- END MAIN --> */}
            </div>



            <Footer NoNavigation={true} />
    </>
          );
      }
  }
  
export default ManageCurriculum;