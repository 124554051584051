import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb';
import CourseCardHorizontal from './../../common/CourseCardHorizontal';
import FilterWidget from './../../common/FilterWidget';

import { get } from './../../services/Apicall';
import { notify } from 'react-notify-toast';

//const $ = window.$;

class SearchResult extends React.Component {


  constructor() {
    super();
    this.state = {
      courselist: [],
      nextItem: 1,
      refineparam: {
        learningfor: '',
        limit: 10,
        offset: 0,
        sortby: 'rate',
        level: [],
        language: [],
        pricingtype: [],
        rating: '',
        duration: []

      },
      resultcount: 0,
      appliedFilter: []
    };
  }



  refineAction = (e, type) => {

    var refineparam = { ...this.state.refineparam }
    if (type == 'text')
      refineparam[e.target.name] = e.target.value;
    else if (type == 'array')
      refineparam[e.target.name] = e.target.value;


    this.setState({ refineparam })
    this.getCourse(refineparam);
  }



  processRadioButton = (e, type, id) => {
    var refineparam = { ...this.state.refineparam }

    var cbarray = document.getElementsByName(type);
    if (e.target.checked == true) {
      refineparam[type] = id;
      for (var i = 0; i < cbarray.length; i++) {
        if (cbarray[i] != e.target)
          cbarray[i].checked = false;

      }
    } else {
      refineparam[type] = '';
    }

    this.setState({ refineparam })
    this.getCourse(refineparam);
  }

  processCheckbox = (type, id) => {
    var refineparam = { ...this.state.refineparam }
    var target = refineparam[type];

    if (target.includes(id)) {
      var index = target.indexOf(id);
      if (index !== -1)
        target.splice(index, 1);
      //this.setState({ ['cb_' + type + id]: true })
    } else {
      target.push(id)
      //this.setState({ ['cb_' + type + id]: false })
    }
    refineparam[type] = target;
    this.setState({ refineparam })
    this.getCourse(refineparam);
  }


  componentDidMount() {
    var refineparam = { ...this.state.refineparam }
    refineparam['query'] = this.props.match.params.query;
    this.setState({ refineparam })
    this.getCourse(refineparam);


    $(window).scroll(function () {
      // console.log('Scrolling...')
      // End of the document reached?
      this.console.log($(document).height() + ' - ' + $(this).height() + ' == ' + $(this).scrollTop())
      if ($(document).height() - $(this).height() - 200 <= ($(this).scrollTop())) {
        // console.log('Loading...') //alert('Scrolled to Bottom');
      }
    });
    var listElm = document.querySelector('body');

    // Add 20 items.


    // Detect when scrolled to bottom.
    listElm.addEventListener('scroll', function () {
      if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
        this.loadMore();
      }
    });
  }


  componentDidUpdate(prevProps) {

    if (this.props.location !== prevProps.location) {

      var refineparam = { ...this.state.refineparam }
      refineparam['query'] = this.props.match.params.query;
      this.setState({ refineparam })
      this.getCourse(refineparam);
    }
  }

  // loadMore = function() {
  //   get('searchseries',{query:this.props.match.params.query}).then((result) => {
  //     let responseJson = result;
  //     if(responseJson.data && responseJson.error==false){
  //       this.setState({courselist: [...this.state.courselist,...responseJson.data] })
  //     }else {
  //      notify.show('Something Went Wrong!','error');
  //     }
  //    });
  // }


  getCourse = (refineparam) => {
    if (refineparam === undefined)
      refineparam = this.state.refineparam;

    get('getseries', refineparam).then((result) => {
      let responseJson = result;
      if (responseJson.data && responseJson.error === false) {
        this.setState({ courselist: responseJson.data })
        this.setState({ resultcount: responseJson.resultcount, appliedFilter: responseJson.appliedFilter })

      } else {
        notify.show('Something Went Wrong!', 'error');
      }
    });
  }

  vpopClose = () => {
    $("#video-popup-close, #video-popup-overlay").on('click', function (e) {
      $("#video-popup-iframe-container,#video-popup-container,#video-popup-close,#video-popup-overlay").hide();
      $("#video-popup-iframe").attr('src', '');
    });
  }
  vpopOpen = () => {
    $(".vpop").on('click', function (e) {
      $("#video-popup-overlay,#video-popup-iframe-container,#video-popup-container,#video-popup-close").show();

      var srchref = '', autoplay = '', id = $(this).data('id');
      if ($(this).data('type') == 'vimeo') var srchref = "https://player.vimeo.com/video/";
      else if ($('#vpop').data('type') == 'youtube') var srchref = "https://www.youtube.com/embed/";

      if ($('#vpop').data('autoplay') == true) autoplay = '?autoplay=1';

      $("#video-popup-iframe").attr('src', srchref + id + autoplay);

      $("#video-popup-iframe").on('load', function () {
        $("#video-popup-container").show();
      });
    });

  }

  saveCourse = (series_id, action) => {
    const { courselist } = this.state;

    let index = courselist.findIndex(x => x.series_id === series_id);
    if (index !== -1 && index !== null)
      if (action == 'remove') {
        courselist[index]['saved'] = 0;
      } else if (action == 'add') {
        courselist[index]['saved'] = 1;
      }
    this.setState({ courselist });

  }

  offsetChagne = (event,offset)=>{
   event.preventDefault();
    var refineparam = { ...this.state.refineparam }
    refineparam['offset'] = offset;
    this.setState({ refineparam })
    this.getCourse(refineparam);
    window.scrollTo(0, 0)
  }


  render() {
    const { refineparam, resultcount } = this.state;
    let pagingItem = [];

    if (refineparam.offset > 0)
      pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset - 10)}>{'Prev'}</a></li>);

    for (let o = 0, p = 1; o <resultcount; o += 10, p++) {
      pagingItem.push(<li class={o=== refineparam.offset ?"page-item active":"page-item"}><a class="page-link" href={"#"+o} onClick={(e) => this.offsetChagne(e,o)}>{p}</a></li>);
    }

    if (refineparam.offset+10 < resultcount)
      pagingItem.push(<li class="page-item"><a class="page-link" href="#" onClick={(e) => this.offsetChagne(e,refineparam.offset + 10)}>{'Next'}</a></li>);



    return (
      <div key={this.props.match.params.query}>

        <Header />


        <div className="candiadte_course_section">

          <div id="rightside" className="rside">
            <Breadcrumb path={[{ link: '/', label: 'Home' }, { link: '/search', label: 'Search' }, { link: '', label: 'You searched for “' + this.props.match.params.query + '"' },]} />
            <div className="body_main">


              <div className="main">

                <div className="div_title_banner">

                  <h3>Search Result</h3>

                </div>


                {/* <!-- Portfolio Gallery Grid --> */}
                <div className="row item">
                  {/* <!-- GRID --> */}

                  <div className="container">

                    <div className="filter_right_side full_width">

                      <FilterWidget appliedFilter={this.state.appliedFilter} refineparam={this.state.refineparam} refineaction={this.refineAction} resultcount={this.state.resultcount} processCheckbox={this.processCheckbox} processRadioButton={this.processRadioButton} />


                      {
                        this.state.courselist.map((item, key) => {
                          return <CourseCardHorizontal saveCourse={this.saveCourse} vpopOpen={this.vpopOpen} key={key} item={item} />
                        })



                      }

                      {(this.state.courselist.length === 0) ? <h2 style={{ textAlign: "center" }}>No Match Found</h2> : ''}


                      {
                        pagingItem.length ? <nav ariaLabel="Page navigation example">
                          <ul class="pagination">
                            {pagingItem.map((item) => {
                              return item;
                            })}
                          </ul>
                        </nav> : null
                      }




                    </div>

                    {/* <!-- all development course --> */}

                  </div>
                </div>

              </div>



              {/* <!-- END GRID --> */}
            </div>

            <Footer />

            <div id="video-popup-overlay" onClick={this.vpopClose}></div>
            <div id="video-popup-container">
              <div id="video-popup-close" onClick={this.vpopClose} className="fade">&#10006;</div>
              <div id="video-popup-iframe-container">
                <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
              </div>
            </div>

          </div>

        </div>


      </div>


    );
  }

}
export default SearchResult;
