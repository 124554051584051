import React from "react";

class TargetInput extends React.Component {

  render() {

    return (
    <div className="mt-10">
        <input type="text" name="first_name" placeholder="eg: xxxx" onFocus="this.placeholder = ''" onBlur="this.placeholder = 'eg: xxxx'" required className="single-input-accent" />
    </div>


);
}
}

export default TargetInput;