import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { notify } from 'react-notify-toast';

import { get, put } from './../services/Apicall';

class FilterWidget extends React.Component {

  constructor() {
    super();
    this.state = {
      filteropen:false,
      languages:[],
    };
  }

  componentDidMount() {
    get('getmaster', { type: 'language' }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ languages: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

  }




  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {



    return (

<>
<div className={this.props.border?"dev_course_filter":""}>
<div className={this.props.border?"all_filters filt_btns":"all_filters"}>
  <div className="main_filter">
  <div className="course_count_rate_sort">
    {/* <span>Course{(this.props.resultcount >1)? 's':'' } : </span><span><strong>{this.props.resultcount}</strong></span> */}
  <span>Displaying </span><span><strong>{this.props.refineparam.offset+10>this.props.resultcount?this.props.resultcount%10:10} out of {this.props.resultcount}</strong></span>
  <span> Course{(this.props.resultcount >1)? 's':'' } from {(this.props.resultcount >1)?this.props.refineparam.offset+1:0} to {this.props.refineparam.offset+10>this.props.resultcount?this.props.resultcount:this.props.refineparam.offset+10}</span>
  </div>
  </div>
  <div className="side_filter">

    <div className="real_f">
    <button type="button"  onClick={()=>{this.setState({filteropen:!this.state.filteropen })}} className="filter-button--filter-button--y-iVA btn btn-quaternary fil_er_btn_to_show_all_filter"><span className="mr5 udi udi-production"></span><span>Filter</span></button>

</div>


<div className="sort_f">
<select name="sortby" onChange={(e)=>this.props.refineaction(e,'text')} value={this.props.refineparam.sortby} form="carform">
<option value="rate">Sort by : Highest Rated</option>
<option value="new">Sort by : Newest</option>
<option value="lth">Sort by : Lowest Price</option>
<option value="htl">Sort by : Highest Price</option>
</select>
</div>
  </div>
</div>
</div>


{/* <!--filter--> */}


<div className={`fi_ter_wra_per_here ${(this.state.filteropen===true)? '' :  'filter_block_none'} `}>

<div className="fi_ter_op_en_here ">
<span className="filter_close_btn"  onClick={()=>{this.setState({filteropen:!this.state.filteropen })}} >
<i className="fa fa-times"></i>
</span>
<div className="filter_t_t_c">
  <table>
    <tbody>
      <tr><td><strong>Course Duration</strong></td></tr>
      <tr><td><input type="checkbox" name="duration" value="" onChange={(e)=>this.props.processRadioButton(e,'duration','')}/><p>All</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="0~30" onChange={(e)=>this.props.processRadioButton(e,'duration','0~30')}/><p> upto 30 Min</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="30~60" onChange={(e)=>this.props.processRadioButton(e,'duration','30~60')}/> <p>30-60 Mins</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="60-120" onChange={(e)=>this.props.processRadioButton(e,'duration','60~120')}/><p>1-2 Hours</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="120~240" onChange={(e)=>this.props.processRadioButton(e,'duration','120~240')}/> <p>2-4 Hours</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="240~660" onChange={(e)=>this.props.processRadioButton(e,'duration','240~360')}/><p> 4-6 Hours</p></td></tr>
      <tr><td><input type="checkbox" name="duration" value="660~" onChange={(e)=>this.props.processRadioButton(e,'duration','360~')}/><p>6+ Hours</p></td></tr>
    </tbody>
  </table>
</div>

<div className="filter_le_el_filter_ty_e">
  <table>
    <tbody>
      <tr><td><strong>Course Level</strong></td></tr>
      <tr><td><input type="checkbox" name="level" value="1" onChange={()=>this.props.processCheckbox('level','1')}/><p>Beginner</p></td></tr>
      <tr><td><input type="checkbox" name="level" value="2" onChange={()=>this.props.processCheckbox('level','2')}/><p>Imtermediate</p></td></tr>
      <tr><td><input type="checkbox" name="level" value="3" onChange={()=>this.props.processCheckbox('level','3')}/><p>Advanced</p></td></tr>
      <tr><td><strong>Course Price</strong></td></tr>
      <tr><td><input type="checkbox" name="Price" value="Paid"  onChange={()=>this.props.processCheckbox('pricingtype','PAID')}/><p>Paid</p></td></tr>
      <tr><td><input type="checkbox" name="Price" value="Free"  onChange={()=>this.props.processCheckbox('pricingtype','FREE')}/><p>Free</p></td></tr>
    </tbody>
  </table>
</div>

<div className="filter_le_el_filter_ty_e">
  <table>
    <tbody>
      <tr><td><strong>Language</strong></td></tr>
{this.state.languages.map((item,index)=>{
   return    <tr><td><input type="checkbox" name="Type" value=""  onChange={()=>this.props.processCheckbox('language',item.value)}/><p>{item.label}</p></td></tr>
    })
  }
      {/* <tr><td><input type="checkbox" name="Type" value=""  onChange={()=>this.props.processCheckbox('language','2')}/><p>Hindi</p></td></tr>
      <tr><td><input type="checkbox" name="Type" value=""  onChange={()=>this.props.processCheckbox('language','3')}/><p>Português</p></td></tr> */}
    </tbody>
  </table>
</div>
<div className="filter_le_el_filter_ty_e">
<table>
    <tbody>
      <tr><td><strong>Rating</strong></td></tr>
      <tr><td><input className="ottff_star" type="checkbox" name="rating" value="5"  onChange={(e)=>this.props.processRadioButton(e,'rating','5')}/><div className="rat_block">
        <span>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i> 5
        </span>
      </div>
    </td></tr>
      <tr><td><input className="ottff_star" type="checkbox" name="rating" value="4"  onChange={(e)=>this.props.processRadioButton(e,'rating','4')}/><div className="rat_block">
        <span>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i> 4+
        </span>
      </div></td></tr>
      <tr><td><input className="ottff_star" type="checkbox" name="rating" value="3"  onChange={(e)=>this.props.processRadioButton(e,'rating','3')}/><div className="rat_block">
        <span>
        <i  className="fa"></i>
        <i  className="fa"></i>
        <i  className="fa"></i>3+
        </span>
      </div></td></tr>
      <tr><td><input className="ottff_star" type="checkbox" name="rating" value="2"  onChange={(e)=>this.props.processRadioButton(e,'rating','2')}/><div className="rat_block">
        <span>
        <i  className="fa"></i>
        <i  className="fa"></i>2+
        </span>
      </div></td></tr>
      <tr><td><input className="ottff_star" type="checkbox" name="rating" value="1"  onChange={(e)=>this.props.processRadioButton(e,'rating','1')}/><div className="rat_block">
        <span>
        <i  className="fa"></i> 1+
        </span>
      </div></td></tr>
    </tbody>
  </table>
  </div>

</div>
</div>
{/* <!--end of filter--> */}

{this.props.appliedFilter.length?
<div className="ap_plid_fil_ter">

  <div className="all_app_lied_fil_ter">
<div className="apledfiltertitle">
  <span>Applied filter</span>
</div>
    {
      this.props.appliedFilter.map((item,key)=>{
      return<div className="apliedfiledblock">
      <span>{item}</span>
      {/* <span><i className="fa fa-times"></i></span> */}
    </div>
    })
    }


  </div>

</div>
:null}
</>
);
}
}

export default FilterWidget;