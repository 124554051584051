import React, { Component } from 'react';
import {Link,Redirect} from 'react-router-dom'
//import $ from 'jquery';
import Header from './../../common/Header';
import Footer from './../../common/Footer';
import Breadcrumb from './../../common/Breadcrumb'
import TrainerSidebar from './../../common/TrainerSidebar'


import { get, put } from './../../services/Apicall';
import { notify } from 'react-notify-toast';
import Rating from 'react-rating';
import CourseCardTrainer from '../../common/CourseCardTrainer';
 

class ManageCourse extends React.Component {

  constructor() {
		super();

		this.state = {
			redirect: false,
			series_id: '',//this.props.match.params.courseId,
			//series_id2:this.props,
			languages: [],
			technologies: [],
			categories: [],
			subcategories: [],
			topics: [],
			formdata: {
				series_title: '',
				series_category: '',
				series_sub_category: '',
				series_primary_teaching: '',//topic
				series_sub_title: '',
				series_description: '',
				series_language: '',
				series_level: '',
				image_file: '',
				video_file: '',

			}
		};

		this.onChange = this.onChange.bind(this);
		this.onChangeDropdown = this.onChangeDropdown.bind(this);
		this.getSubcategory = this.getSubcategory.bind(this);
		this.getTopic = this.getTopic.bind(this);
		this.updateSeries = this.updateSeries.bind(this);


	} //end constructor


	componentDidMount() {

		this.setState({ 'series_id': this.props.match.params.courseId })


		get('fetchseries', { series_id: this.props.match.params.courseId }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ formdata: responseJson.data })
				get('getcategory', { pid: responseJson.data.series_category }).then((result) => {
					let responseJson = result;
					if (responseJson.data && responseJson.error == false) {
						this.setState({ subcategories: responseJson.data })
					} else {
						notify.show('Something Went Wrong!', 'error');
					}
				});
				get('getcategory', { pid: responseJson.data.series_sub_category, topic: 1 }).then((result) => {
					let responseJson = result;
					if (responseJson.data && responseJson.error == false) {
						this.setState({ topics: responseJson.data })
					} else {
						notify.show('Something Went Wrong!', 'error');
					}
				});

			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

		get('getmaster', { type: 'language' }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ languages: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

		get('getmaster', { type: 'technology' }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ technologies: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

		get('getcategory', {}).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ categories: responseJson.data })

			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});


	}


	getSubcategory(e) {
		this.onChange(e);
		get('getcategory', { pid: e.target.value }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ subcategories: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

	}

	getTopic(e) {
		this.onChange(e);
		get('getcategory', { pid: e.target.value, topic: 1 }).then((result) => {
			let responseJson = result;
			if (responseJson.data && responseJson.error == false) {
				this.setState({ topics: responseJson.data })
			} else {
				notify.show('Something Went Wrong!', 'error');
			}
		});

	}


	updateSeries(e) {
		e.preventDefault();
		if (this.state.formdata.series_title && this.state.formdata.series_category) {
			put('updateseries', this.state.formdata).then((result) => {
				let responseJson = result;
				if ( responseJson.error == false) {
					notify.show(responseJson.msg, 'success');
					this.setState({redirect: true});
				} else if (responseJson.error == true) {
					notify.show(responseJson.msg, 'error');

				}

			});
		} else { notify.show('Please Fill up all the fields', 'error'); }

	}

	onChange(e) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name] = e.target.value;                        //updating value
		this.setState({ formdata });
	}


	onChangeDropdown(e) {
		let formdata = Object.assign({}, this.state.formdata);    //creating copy of object
		formdata[e.target.name] =  this.getSelectedOptions(e)//.target.value;                        //updating value
		this.setState({ formdata });
	}

	getSelectedOptions(e) {
	  var sel = e.target;
	  var opts = [],
	    opt;
	  var len = sel.options.length;
	  for (var i = 0; i < len; i++) {
	    opt = sel.options[i];

	    if (opt.selected) {
	      opts.push(opt.value);
	     
	    }
	  }
	  return opts;
	}





    render() {

      if (this.state.redirect) {
        let seriesid=this.props.match.params.courseId;
        return <Redirect to={'/instructorportal/manageobjective/'+seriesid} />
        }

        return (
            <>
            <Header  trainerPage={true} /> 
    <Breadcrumb path={[{link:'/',label:'Home'},{link:'',label:'Trainer'}]} />

    <div className="main-body">
<div className="container-fluid">

<div className="design_new_course">
  
<div className="dgnwcrs_tag_line">
  <div className="topic_tag">
    <div className="btac ttl_tg_all">&#8735;</div>
  <p className="aabtx ttl_tg_all"><Link to={'/instructorportal/mycourses'} >Back to all courses</Link></p>

  <p className="crs_name ttl_tg_all">{this.state.formdata.series_title}</p>

  {/* <p className="crs_sts ttl_tg_all">Inactive</p> */}
  </div>

  <div className="topic_exit">
  <Link to="/instructorportal/tips">Tips <i className="fa fa-info-circle"></i></Link>
  </div>
</div>
<TrainerSidebar courseId={this.props.match.params.courseId } />
<div className="dnc_right_bar">
<div id="About_Course" className="tabcontent">

<div className="design_new_course_form">
  

  <div className="container">


    <form className="form-horizontal" action=" " method="post"  id="contact_form"  onSubmit={this.updateSeries} >
<fieldset>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course tittle</label>  
  <div className="col-md-12 inputGroupContainer">
  <div className="input-group">
  <input   type="text" maxLength='60' name="series_title" value={this.state.formdata.series_title}  onChange={this.onChange}   placeholder="Course Tittle" className="form-control" required />
    </div>
    <div className="form-control-counter" data-purpose="form-control-counter">{60-this.state.formdata.series_title.length}</div>
  </div>
</div>

{/* <!-- Text input--> */}

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course sub-tittle</label>  
    <div className="col-md-12 inputGroupContainer">
    <div className="input-group">
  <input maxLength="120" name="series_sub_title" value={this.state.formdata.series_sub_title} onChange={this.onChange}  placeholder="Course Sub-Tittle" className="form-control" required />
    </div>
    <div className="form-control-counter" data-purpose="form-control-counter">{120-this.state.formdata.series_sub_title.length}</div>
  </div>
</div>

{/* <!-- Text input--> */}
<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Course description</label>  
    <div className="col-md-12 inputGroupContainer">
    <div className="input-group">
          <textarea className="form-control" placeholder="Course Description" value={this.state.formdata.series_description} name='series_description'  onChange={this.onChange} required  ></textarea>
  </div>
  </div>
</div>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Learning area</label>  
    <div className="col-md-12 selectContainer">
    <div className="input-group">
    <select  name='series_category' value={this.state.formdata.series_category} onChange={this.getSubcategory} required className="form-control selectpicker" >
      <option value="" >Learning area</option>
      {
														this.state.categories.map((item, key) => {
															return <option key={key} value={item.value}>{item.label}</option>;
														})
													}
    </select>
  </div>
</div>
</div>

{/* <!-- Text input--> */}

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Subject</label>  
    <div className="col-md-12 selectContainer">
    <div className="input-group">
    <select name='series_sub_category' value={this.state.formdata.series_sub_category} onChange={this.getTopic} required className="form-control selectpicker" >
      <option value="" >Subject</option>
      {
														this.state.subcategories.map((item, key) => {
															return <option key={key} value={item.value}>{item.label}</option>;
														})
													}
    </select>
  </div>
</div>
</div>

{/* <!-- Text input--> */}
<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Topic</label>  
    <div className="col-md-12 selectContainer">
    <div className="input-group">
    <select name='series_primary_teaching' value={this.state.formdata.series_primary_teaching} onChange={this.onChange} required className="form-control selectpicker" >
      <option value="" >Topic</option>
      {
														this.state.topics.map((item, key) => {
															return <option key={key} value={item.value}>{item.label}</option>;
														})
													}
    </select>
  </div>
</div>
</div>

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Technology / Skill / Software </label>  
    <div className="col-md-12 inputGroupContainer">

    <div className="input-group" >

    <select name='series_technologies' value={this.state.formdata.series_technologies} onChange={this.onChangeDropdown} multiple required className="form-control selectpicker" >
      {
														this.state.technologies.map((item, key) => {
															return <option key={key} value={item.value}>{item.label}</option>;
														})
													}
    </select>
  </div>
  </div>
</div>
<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Language</label>  
    <div className="col-md-12 selectContainer">
    <div className="input-group">
    <select  name='series_language' value={this.state.formdata.series_language} onChange={this.onChange} required className="form-control selectpicker" >
      <option value="" >Language</option>
      {
														this.state.languages.map((item, key) => {
															return <option key={key} value={item.value}>{item.label}</option>;
														})
													}
    </select>
  </div>
</div>
</div>

{/* <!-- Text input--> */}

<div className="form-group dnc_hlwd">
    <label className="col-md-12 control-label dnc_lnhg">Level</label>  
    <div className="col-md-12 selectContainer">
    <div className="input-group">
    <select  name='series_level' value={this.state.formdata.series_level} onChange={this.onChange} required className="form-control selectpicker multipleoptionseclector" >
      <option value="">Course Level</option>
													<option value="1">Beginner Level</option>
													<option value="2">Intermediate Level</option>
													<option value="3">Expert Level</option>
													<option value="4">All Level</option>
    </select>
  </div>
</div>
</div>

</fieldset>
<div className="continue_btn">
<button type="submit" className="btn btn-lg btn-primary">Save and continue</button>
</div>
</form>
</div>

</div>
{/* <!-- /.container --> */}
</div>

</div>


</div>

</div>
{/* <!-- END MAIN --> */}
</div>


  
    <Footer  NoNavigation={true} />
    </>
        );
    }
}

export default ManageCourse;